
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import axios, { AxiosError } from "axios";

import './VesselList.css'; // スタイルのために追加
import { SelectTheme } from '../../components/theme/SelectTheme';
import { Main, BootstrapInput } from '../../components/styled/styled';
import { callAuth } from '../../components/components/auth';
import { SearchContext } from '../../App';
import { MyAppBar } from '../../components/components/AppBar';
import NoReceived_svg from '../../assets/images/no-receiived.svg';
import Problem_icon_svg from '../../assets/images/problem_icon.svg';

// @mui/material/
import { ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';

// @mui/icons-material/
import StarIcon from '@mui/icons-material/Star';
import SearchIcon from '@mui/icons-material/Search';

// Dialog
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

// アラームアイコン
import AlarmBlue from '../../assets/images/alarm_blue.svg';
import AlarmRed from '../../assets/images/alarm_red.svg';

interface payload {
  role: string | null
  user_id: string | null
  group_id: string | null
  company_id: string | null
  gid: string | null
  gidList: Array<string>
  imoList: Array<string>
  jwt: string | null
}

// Axios Error
interface ErrorData {
  message: string;
  status: number;
  error: string;
}

function formatNumber(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const ciiSize = "16px"
const spconSize = "15px"
const eua_cb_Size = "15px"
export default function BasicTable({ setSeachInfo }) {

  // 初期表示時に、WADATSUMI認証で、ユーザIDを取得。ユーザIDを元に、SPM側で保持しているグループマスタを元にどこのグループ所属か抽出し、GroupStateにセットする。
  const [message, setMessage] = React.useState<string>();
  let init = true
  const [userCheck, setUserCheck] = React.useState(false);
  const [user_id, setUser_id] = React.useState<string>();
  const [group_id, setGroup_id] = React.useState<string>();
  const [company_id, setCompany_id] = React.useState<string>();
  const [jwt, setJwt] = React.useState<string>();
  const [role, setRole] = React.useState<string>();

  // Loading--------------------------------------------------------------------
  const [loading, setLoading] = React.useState(true);
  const [success, setSuccess] = React.useState(false);

  React.useEffect(() => {
    console.log("vessellist")
    if (init === true) {
      let jwt_ls
      let role_ls
      if (localStorage.getItem("payload")) {
        const pay_json = localStorage.getItem("payload")
        if (pay_json !== null) {
          const pay = JSON.parse(pay_json)
          role_ls = pay["role"]
          jwt_ls = pay["jwt"]
          setJwt(jwt_ls)
          setRole(role_ls)
        }
      }

      // 例としてJWTを渡して呼び出す
      callAuth(jwt_ls).then(result => {
        if (result) {
          console.log("認証成功[VesselList]");

          let url = ""
          let sub = result["sub"]

          // ブラウザ更新による初期表示か、他画面から遷移の初期表示か判定。他画面から遷移の初期表示の場合、SeachInfo.gidは既にセットされている。
          if (SeachInfo.gid === "") {
            url = `${base_url}%23?user=${sub}&init=${init}`
          }
          else {
            url = `${base_url}%23?user=${sub}&GID=${SeachInfo.gid}`
          }
          console.log("init_url", url)
          setMessage("Authentication success.")
          getVesselList(url, jwt_ls, role_ls)
        } else {
          console.log("[VesselList]認証失敗");
          setMessage("Authentication error.")
        }
      });
    }
    init = false

  }, [init])


  // コンテクスト取得--------------------------------------------------------------------
  const SeachInfo: any = React.useContext(SearchContext);

  const [base_url, setBase_url] = React.useState(`${process.env.REACT_APP_API_URL}/VesselList/`);

  // Navigation--------------------------------------------------------------------
  const navigate = useNavigate();
  const handleClickSpeedConsumption = (index, imoNo, from, to) => {
    console.log("imoNo", imoNo)
    setSeachInfo({ imoNo: imoNo, from: from, to: to, gid: SeachInfo.gid, gidList: SeachInfo.gidList, imoList: SeachInfo.imoList })
    navigate('/SpeedConsumption/', { state: { beaufort: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], BallastLaden: "All", fromLogSpeed: "5", toLogSpeed: "23" } });
  };
  const handleClickCiiAnalysis = (index, imoNo, from, to, tabName) => {
    console.log("imoNo", imoNo)
    setSeachInfo({ imoNo: imoNo, from: from, to: to, gid: SeachInfo.gid, gidList: SeachInfo.gidList, imoList: SeachInfo.imoList })
    navigate('/CiiAnalysis/', { state: {activeTab: tabName} });
  };
  const handleClickEuEtsAndFuelEu =(index, imoNo, from, to, tabName) => {
    console.log("imoNo", imoNo)
    setSeachInfo({ imoNo: imoNo, from: from, to: to, gid: SeachInfo.gid, gidList: SeachInfo.gidList, imoList: SeachInfo.imoList })
    navigate('/EuEtsAndFuelEu/', { state: {activeTab: tabName} });
  };
  const handleClickFacorite = (imo, favorite) => {
    setLoading(true)
    setSuccess(false)
    const url = `${base_url}%23?&user=${user_id}&favorite=${favorite}&GID=${SeachInfo.gid}&imo=${imo}`
    getVesselList(url, jwt, role)
  };

  // WADATSUMIログイン画面遷移----------------------------------------------------------------------------------------------------------------------------
  const handleClickWADATSUMI = () => {
    window.location.replace(`${process.env.REACT_APP_WADATSUMIURL}`)
  };

  // Group--------------------------------------------------------------------
  const selectGroup = (event: SelectChangeEvent) => {

    let val = event.target.value as string;
    let gid = "";

    if (val === "ALL") {
      gid = "admin";
    }
    else {
      gid = val;
    }

    setSeachInfo({ imoNo: `${SeachInfo.imoNo}`, from: SeachInfo.from, to: SeachInfo.to, gid: `${gid}`, gidList: SeachInfo.gidList, imoList: SeachInfo.imoList })
    onClickGroup()
  };
  const selectGroupAction = (gid) => {
    setLoading(true)
    setSuccess(false)
    const url = `${base_url}%23?&user=${user_id}&GID=${gid}`
    getVesselList(url, jwt, role)
  }

  const [changeClickGroup, setChangeClickGroup] = React.useState<number>(0);

  const onClickGroup = () => {
    setChangeClickGroup((prev) => (prev + 1));
  }
  React.useEffect(() => {
    if (changeClickGroup > 0) {
      selectGroupAction(SeachInfo.gid)
    }
  }, [changeClickGroup])

  // VesselList取得データ
  const [rows, setRows] = React.useState<any>([])
  // VesselList取得データ（元データ保持用）
  const [rowsDefoult, setRowsDefoult] = React.useState<any>([])

  // VesselList取得--------------------------------------------------------------------
  const getVesselList = (url, jwt, role) => {
    console.log("url:", url);

    const options = {
      headers: {
        'Authorization': jwt,
      }
    }
    axios.get(url, options).then((res) => {
      console.log("axios[VesselList]", res)

      setSeachInfo({ imoNo: `${SeachInfo.imoNo}`, from: SeachInfo.from, to: SeachInfo.to, gid: res.data.datas.gid, gidList: res.data.datas.gidList, imoList: res.data.datas.imoList })

      setUser_id(res.data.datas.user_id)
      setGroup_id(res.data.datas.group_id)
      setCompany_id(res.data.datas.company_id)

      if (role !== undefined) {
        localStorage.clear()
        let Payload: payload
        Payload = {
          role: role,
          user_id: res.data.datas.user_id,
          group_id: res.data.datas.group_id,
          company_id: res.data.datas.company_id,
          gid: res.data.datas.gid,
          gidList: res.data.datas.gidList,
          imoList: res.data.datas.imoList,
          jwt: jwt
        }
        localStorage.setItem("payload", JSON.stringify(Payload))
      }
      setRows(res.data.datas.rows)
      setUserCheck(false)

      // 元データ保持用のオブジェクトにもセット
      setRowsDefoult(res.data.datas.rows)

      // Loading解除
      setLoading(false)
      setSuccess(true)
    })
      .catch((e: AxiosError<ErrorData>) => {
        setLoading(false)
        setSuccess(true)
        if (e.response === undefined) {
          console.log("undefinedError: " + e);
          return;
        }
        console.log(e + ", message: " + e.response.data.message);
      });
  }

  type ActiveSortNo = {
    item: string;
    seq: number;
    sort: string;
  }
  // ソート機能--------------------------------------------------------------------
  const [activeSortNo, setActiveSortNo] = React.useState<ActiveSortNo>({ item: '', seq: 0, sort: '' }); // seq: 文字列→1, 数値→2
  const sortAsc = (item: string, seq: number) => { //昇順に並び替え
    const newListData = [...rows];
    if (seq === 1) { //文字列
      newListData.sort((a, b) => {
        if (a[item] > b[item]) {
          return 1;
        } else {
          return -1;
        }
      })
    } else { //数値
      newListData.sort((a, b) => {
        return a[item] - b[item];
      })
    }


    if (activeSortNo.item === item && activeSortNo.sort === 'asc') { //ソート解除
      setActiveSortNo({ item: '', seq: 0, sort: 'asc' });
    } else {
      setActiveSortNo({ item, seq, sort: 'asc' });
    }
    setRows(newListData);
  }

  const sortDesc = (item: string, seq: number) => { //降順に並び替え
    const newListData = [...rows];
    if (activeSortNo.item === item && activeSortNo.sort === 'desc') { //ソート解除
      if (seq === 1) { //文字列
        newListData.sort((a, b) => {
          if (a[item] > b[item]) {
            return 1;
          } else {
            return -1;
          }
        })
      } else { //数値
        newListData.sort((a, b) => {
          return a[item] - b[item];
        })
      }
      setActiveSortNo({ item: '', seq: 0, sort: 'desc' });
    } else {
      if (seq === 1) { //文字列
        newListData.sort((a, b) => {
          if (a[item] < b[item]) {
            return 1;
          } else {
            return -1;
          }
        })
      } else { //数値
        newListData.sort((a, b) => {
          return b[item] - a[item];
        })
      }
      setActiveSortNo({ item, seq, sort: 'desc' });
    }
    setRows(newListData);
  }

  // 現在の日付を取得
  const currentDate = new Date();

  // 7日前の日付を計算
  const beforeday = new Date(currentDate);
  beforeday.setDate(currentDate.getDate() - 4);

  // VesselListのフィルタ処理
  const handleFilterVesselList = (value) => {
 
    // 予備品名フィルタ値
    let filterValVesselName = String(value).toUpperCase();

    // 一覧（元データ）を取得
    let tableData = rowsDefoult.concat();

    // フィルタに入力ありの場合は処理
    let filterTableData = tableData;
    if (filterValVesselName !== "") {
      // フィルタ処理
      filterTableData = [];
      for (const rowData of tableData) {
        // フィルタ対象のテーブル項目値を取得
        const colDataPartsName = String(rowData.VesselName);

        // フィルタ入力値がVesselNameに一致するかチェック
        let pushFlgVesselName = false;

        if (filterValVesselName === "") {
          pushFlgVesselName = true;
        } else if (colDataPartsName.indexOf(filterValVesselName) > -1) {
          pushFlgVesselName = true;
        }

        // 一致があった場合は追加
        if (pushFlgVesselName) {
          filterTableData.push(rowData);
        }
      }
    }
    // フィルタした一覧を画面表示
    setRows(filterTableData);
  }

  return (
    <Box height="100vh" sx={{ display: 'flex' }}>
      <CssBaseline />

      {/* useId有効期限切れダイアログ */}
      <Dialog
        open={userCheck}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
              height: "100%",
              maxHeight: "240px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            bgcolor: "#9ea2ad",
            height: "50px",
            color: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}>
          UserId Update
        </DialogTitle>
        <DialogContent
          sx={{
            background: "linear-gradient(#40475d, #0d1532)",
          }}>
          <Typography align="left" sx={{ marginTop: "32px", color: 'white', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', wordWrap: 'break-word' }}>
            {message === "" ? "" : message}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ height: "50px", bgcolor: "#9ea2ad" }}>
          <Button
            sx={{
              marginLeft: "0px",
              marginRight: "-8px",
              backgroundColor: "#e66300",
              width: "127px",
              height: "50px",
              padding: "0",
              fontSize: "18px",
              fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
              fontWeight: "bold",
              color: "white",
              borderRadius: "0px",
              ":hover": {
                bgcolor: "#FFFFFF",
                color: "#40475D",
              },
              textTransform: 'none'
            }}
            onClick={handleClickWADATSUMI}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* menu bar */}
      <MyAppBar company_id={company_id} group_id={group_id} user_id={user_id} role={role} />

      {/* メイン部分 */}
      <Main>

        {/* 検索フォーム */}
        <Box sx={{ height: "60px"}}>
          <Grid container spacing={0} sx={{ height: "100%" }}>
            <Stack direction="row" sx={{ height: "100%", marginTop: "30px", marginLeft: "32px" }} alignItems={"center"} justifyContent={"flex-start"}>
              <Typography align='left' sx={{ marginRight: "16px", color: 'white', fontSize: 14, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                Group
              </Typography>
              <ThemeProvider theme={SelectTheme}>
                <FormControl>
                  <Select
                    IconComponent={() =>
                      <div style={{ position: "absolute", top: "20px", left: "115px" }}>
                        <InputAdornment position="start" disablePointerEvents>
                          <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(135deg)" }}></Box>
                        </InputAdornment>
                      </div>
                    }
                    MenuProps={{
                      MenuListProps: {
                        disablePadding: true,

                      },
                      style: { zIndex: 100000, }
                    }}
                    sx={{
                      width: 140,
                      height: 40,
                      color: "white",
                      borderRadius: 30,
                      ":hover": { backgroundColor: "#7a86a0" }
                    }}
                    input={<BootstrapInput />}
                    onChange={selectGroup}
                    value={SeachInfo.gid === "admin" ? "ALL": SeachInfo.gid}
                  >
                    {SeachInfo.gidList.map((gid) => {
                      return (
                        <MenuItem key={gid === "admin" ? "ALL": gid} value={gid === "admin" ? "ALL": gid}>{gid === "admin" ? "ALL": gid}</MenuItem>
                      )
                    }
                    )}
                  </Select>
                </FormControl>
              </ThemeProvider>
            </Stack>
            <Stack sx={{width: "30px"}}></Stack>
            <Stack direction="row" sx={{ height: "100%", marginTop: "30px" , position: "relative"}} alignItems={"center"} justifyContent={"flex-start"}>
              <SearchIcon sx={{ marginRight: "6px", width: "20px", height: "20px", color: "white", 
                position:"absolute", left:"5px", zIndex:10000
              }} />
              <FormControl>
                <Input id="vesselNameFilter" type="email"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleFilterVesselList(event.target.value) }}
                  placeholder="Vessel Name" autoComplete="off"
                  sx={{
                    fontFamily: 'Roboto', fontWeight: '500', border: '0px grey solid', color: "white", fontSize: "15px", 
                    paddingLeft:"30px",background: "rgba(255, 255, 255, 0.15)",
                    "& .MuiInput-input": { height: "23px", width: "110px", borderRadius: '0px', border: '0px white solid', textAlign: "left", },
                    ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                    ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                    ":after": { borderBottom: "2px solid white", },
                    ":before": { borderBottom: "2px solid #A4A7B1", }, fontStyle:"italic"
                  }}/>
              </FormControl>
            </Stack>
            <Typography align='left' sx={{color: 'rgba(255, 255, 255, 0.50)', fontSize: 15, fontWeight: "bold", fontFamily: 'Roboto',  wordWrap: 'break-word'
              , marginTop: "50px", marginLeft: "22px", 
              }}>
                Total Vessels
            </Typography>
            <Typography align='left' sx={{ color: 'rgba(255, 255, 255, 0.50)', fontSize: 15, fontWeight: "bold", fontFamily: 'Roboto',  wordWrap: 'break-word'
              , marginTop: "50px", marginLeft: "7px", 
              }}>
                :
            </Typography>
            <Typography align='left' sx={{ color: 'white', fontSize: 15, fontFamily: 'Roboto',  wordWrap: 'break-word'
              , marginTop: "50px", marginLeft: "7px", 
              }}>
                {rows.length !== 0 ? rows.length: 0}
            </Typography>
          </Grid>
        </Box>
        <Box sx={{ height: "84%", marginLeft: "32px", marginRight: "32px" }}>
          <Box style={{ width: '100%', height: '5%', position: 'relative', marginTop: "5px" }}>
            <Box style={{ width: "11.25%", height: "26px", left: "30%", top: "2px", position: 'absolute', background: 'rgba(83, 84, 89, 0.50)', }}>
              <Typography align="center" sx={{ marginTop: "2px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 14, fontWeight: '700', wordWrap: 'break-word' }}>
                Speed Consumption Alarm
              </Typography>
            </Box>
            <Box style={{ width: "19.5%", height: "26px", left: "43.75%", top: "2px", position: 'absolute', background: 'rgba(83, 84, 89, 0.50)', }}>
              <Typography align="center" sx={{ marginTop: "2px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 14, fontWeight: '700', wordWrap: 'break-word' }}>
                CII Score
              </Typography>
            </Box>
            <Box style={{ width: "14.25%", height: "26px", left: "65%", top: "2px", position: 'absolute', background: 'rgba(83, 84, 89, 0.50)', }}>
              <Typography align="center" sx={{ marginTop: "2px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 14, fontWeight: '700', wordWrap: 'break-word' }}>
                EUA
              </Typography>
            </Box>
            <Box style={{ width: "14.25%", height: "26px", left: "81%", top: "2px", position: 'absolute', background: 'rgba(83, 84, 89, 0.50)', }}>
              <Typography align="center" sx={{ marginTop: "2px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 14, fontWeight: '700', wordWrap: 'break-word' }}>
                Compliance Balance (ton)
              </Typography>
            </Box>
          </Box>

          <div className="vessel-list-wrapper">
            <div className="vessel-list-area-wrapper">
              <table className="vessel-list-table">
                <thead>
                  <tr>
                    <th className="favorite">
                      <span></span>
                    </th>
                    <th className="alarm">
                      <span>
                      </span>
                    </th>
                    <th className="vesselName">
                      <div className='vessel-list-header-left'>
                        <div>
                          <span>Vessel Name</span>
                        </div>
                        <div className="common-sort-container">
                          <div className="common-sort-icon">
                            <div className={(activeSortNo.item === "vesselName" && activeSortNo.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAsc('VesselName', 1)}></div>
                            <div className={(activeSortNo.item === "vesselName" && activeSortNo.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDesc('VesselName', 1)}></div>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th className="latestUpdate">
                      <div className='vessel-list-header-left'>
                        <div>
                          <span>Latest Update</span>
                        </div>
                        <div className="common-sort-container">
                          <div className="common-sort-icon">
                            <div className={(activeSortNo.item === "latestUpdate" && activeSortNo.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAsc('LatestUpdate', 1)}></div>
                            <div className={(activeSortNo.item === "latestUpdate" && activeSortNo.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDesc('LatestUpdate', 1)}></div>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th className="padding1">
                      <span></span>
                    </th>
                    <th className="CPCurve">
                      <div className='vessel-list-header-center'>
                        <div>
                          <span>CP Curve </span><br />
                          <span>for 30days [time(s)]</span>
                        </div>
                        <div className="common-sort-container" style={{ position: "absolute", top: "10px", left: "156px" }}>
                          <div className="common-sort-icon">
                            <div className={(activeSortNo.item === "cp" && activeSortNo.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAsc('cp', 0)}></div>
                            <div className={(activeSortNo.item === "cp" && activeSortNo.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDesc('cp', 0)}></div>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th className="padding2">
                      <span></span>
                    </th>
                    <th className="Last4Weeks">
                      <div className='vessel-list-header-center'>
                        <div>
                          <span>Year to Date</span>
                        </div>
                        <div className="common-sort-container">
                          <div className="common-sort-icon">
                            <div className={(activeSortNo.item === "oneMonth" && activeSortNo.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAsc('oneMonth', 1)}></div>
                            <div className={(activeSortNo.item === "oneMonth" && activeSortNo.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDesc('oneMonth', 1)}></div>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th className="ThisYear">
                      <div className='vessel-list-header-center'>
                        <div>
                          <span>End of Year</span>
                        </div>
                        <div className="common-sort-container">
                          <div className="common-sort-icon">
                            <div className={(activeSortNo.item === "Januarytonow" && activeSortNo.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAsc('Januarytonow', 1)}></div>
                            <div className={(activeSortNo.item === "Januarytonow" && activeSortNo.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDesc('Januarytonow', 1)}></div>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th className="LastYear">
                      <div className='vessel-list-header-center'>
                        <div>
                          <span>Last Year</span>
                        </div>
                        <div className="common-sort-container">
                          <div className="common-sort-icon">
                            <div className={(activeSortNo.item === "LastYear" && activeSortNo.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAsc('LastYear', 1)}></div>
                            <div className={(activeSortNo.item === "LastYear" && activeSortNo.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDesc('LastYear', 1)}></div>
                          </div>
                        </div>
                      </div>
                    </th>
                    {/* <th className="padding3">
                      <span></span>
                    </th> */}
                    <th className="eua-year-to-date">
                      <div className='vessel-list-header-right'>
                        <div>
                          <span>Year to Date</span>
                        </div>
                        <div className="common-sort-container">
                          <div className="common-sort-icon">
                            <div className={(activeSortNo.item === "LastYear" && activeSortNo.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAsc('LastYear', 1)}></div>
                            <div className={(activeSortNo.item === "LastYear" && activeSortNo.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDesc('LastYear', 1)}></div>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th className="eua-end-of-year">
                      <div className='vessel-list-header-right'>
                        <div>
                          <span>End of Year</span>
                        </div>
                        <div className="common-sort-container">
                          <div className="common-sort-icon">
                            <div className={(activeSortNo.item === "LastYear" && activeSortNo.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAsc('LastYear', 1)}></div>
                            <div className={(activeSortNo.item === "LastYear" && activeSortNo.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDesc('LastYear', 1)}></div>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th className="padding4">
                      <span></span>
                    </th>
                    <th className="cb-year-to-date">
                      <div className='vessel-list-header-right'>
                        <div>
                          <span>Year to Date</span>
                        </div>
                        <div className="common-sort-container">
                          <div className="common-sort-icon">
                            <div className={(activeSortNo.item === "LastYear" && activeSortNo.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAsc('LastYear', 1)}></div>
                            <div className={(activeSortNo.item === "LastYear" && activeSortNo.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDesc('LastYear', 1)}></div>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th className="cb-end-of-year">
                      <div className='vessel-list-header-right'>
                        <div>
                          <span>End of Year</span>
                        </div>
                        <div className="common-sort-container">
                          <div className="common-sort-icon">
                            <div className={(activeSortNo.item === "LastYear" && activeSortNo.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAsc('LastYear', 1)}></div>
                            <div className={(activeSortNo.item === "LastYear" && activeSortNo.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDesc('LastYear', 1)}></div>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th className="padding5">
                      <span></span>
                    </th>
                  </tr>
                  <tr></tr>
                </thead>
                {success ?
                  <tbody>
                    {rows.length !== 0 ?
                      <>
                        {
                          rows.map((element, index) => {
                            return (
                              <tr key="">
                                <td className="favorite">
                                  <IconButton
                                    onClick={() => handleClickFacorite(element.imo, element.favorite)}
                                    sx={{
                                      fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
                                      fontWeight: "bold",
                                      color: `${element.favorite === "checked" ? "yellow" : "grey"}`,
                                      ":hover": {
                                        color: `${element.favorite === "checked" ? "orange" : "pink"}`,
                                      }
                                    }}
                                  >
                                    <StarIcon sx={{ width: "17px", height: "22px", opacity: 0.6 }} />
                                  </IconButton>
                                </td>
                                <td className="alarm">
                                  {new Date(element.LatestUpdate) < beforeday ? <img src={AlarmRed} className="alarm-blur" alt="alarm" />
                                    : parseInt(element.cp) >= 1 ? <img src={AlarmRed} className="alarm-blur" alt="alarm" />
                                      : element.Januarytonow === "D" ? <img src={AlarmRed} className="alarm-blur" alt="alarm" />
                                        : element.Januarytonow === "E" ? <img src={AlarmRed} className="alarm-blur" alt="alarm" />
                                          : element.CIIEndofYear === "D" ? <img src={AlarmRed} className="alarm-blur" alt="alarm" />
                                            : element.CIIEndofYear === "E" ? <img src={AlarmRed} className="alarm-blur" alt="alarm" />
                                              :<img src={AlarmBlue} alt="alarm" />
                                  }
                                </td>
                                <td className="vesselName">
                                  <Stack>
                                    <Typography align='left' sx={{ color: '#ffffff', width: "100%", fontSize: "15px", }}>{element.VesselName}</Typography>
                                  </Stack>
                                </td>
                                <td className="latestUpdate">
                                  <Grid container sx={{ height: "100%" }} alignItems={"center"}>
                                    <Grid item xs={6} >
                                      <Stack>
                                        <Typography align='left' sx={{ color: '#ffffff', width: "100%", fontSize: "15px", }}>{element.LatestUpdate}</Typography>
                                      </Stack>
                                    </Grid>
                                    <Grid item xs={6} >
                                      {new Date(element.LatestUpdate) < beforeday ?
                                        <Stack sx={{ width: "100%", marginLeft: "15px" }} alignItems={"left"}>
                                          <img src={NoReceived_svg} alt="iconLike" style={{ height: "20px", width: "20px" }} />
                                        </Stack>
                                        :
                                        ""
                                      }
                                    </Grid>
                                  </Grid>
                                </td>
                                <td className="padding1">
                                  <span></span>
                                </td>
                                <td className="CPCurve">
                                  <Button
                                    onClick={() => handleClickSpeedConsumption(index, element.imo, element.cp_from, element.cp_to)}
                                    sx={{
                                      width: "100%",
                                      height: "100%",
                                      ":hover": {
                                        bgcolor: "rgba(255, 255, 255, 0.5)",
                                        color: "orange",
                                      }
                                    }}
                                    disabled={element.oneMonth_count === "0" ? true : false}
                                  >
                                    <Grid item xs={6} sx={{ width: "100%" }}>
                                      <Stack>
                                        {element.oneMonth_count !== "0" ?
                                          <Typography align='right' sx={{ color: '#ffffff', width: "100%", fontSize: spconSize, }}>{element.cp}</Typography>
                                          :
                                          <>
                                            <Typography align='right' sx={{ color: 'rgba(255,255,255,0.6)', width: "100%", fontSize: "12px", textTransform: 'none' }}>No Data</Typography>
                                          </>
                                        }
                                      </Stack>
                                    </Grid>
                                    <Grid item xs={6} sx={{ width: "100%" }}>
                                      {parseInt(element.cp) >= 1 ?
                                        <Stack sx={{ marginLeft: "8px", width: "100%" }} alignItems={"left"}>
                                          <ReportProblemIcon sx={{ height: "17px", width: "21px", color: "yellow" }} />
                                        </Stack>
                                        :
                                        ""
                                      }
                                    </Grid>
                                  </Button>
                                </td>
                                <td className="padding2">
                                  <span></span>
                                </td>
                                <td className="ThisYear">
                                  <Stack direction={"row"} sx={{ width: "100%", height: "100%", }} >
                                    <Button
                                      onClick={() => handleClickCiiAnalysis(index, element.imo, element.Januarytonow_from, element.Januarytonow_to, "Emission")}
                                      sx={{ width: "100%", height: "100%", ":hover": { bgcolor: "rgba(255, 255, 255, 0.5)", } }}
                                      disabled={element.Januarytonow === "" ? true : false}>
                                      {
                                        element.Januarytonow === "A" ?
                                          <>
                                            <Stack justifyContent={"center"} sx={{ width: "26px", height: "26px", bgcolor: "rgba(0, 144, 206, 0.4)" }}>
                                              <Typography align='center' sx={{ marginTop: "2px", color: '#ffffff', fontSize: ciiSize, fontWeight: "bold", }}>
                                                {element.Januarytonow}
                                              </Typography>
                                            </Stack>
                                          </>
                                          :
                                          <>
                                            {
                                              element.Januarytonow === "B" ?
                                                <>
                                                  <Stack justifyContent={"center"} sx={{ width: "26px", height: "26px", bgcolor: "rgba(6, 165, 80, 0.4)" }}>
                                                    <Typography align='center' sx={{ marginTop: "2px", color: '#ffffff', fontSize: ciiSize, fontWeight: "bold", }}>
                                                      {element.Januarytonow}
                                                    </Typography>
                                                  </Stack>
                                                </>
                                                :
                                                <>
                                                  {
                                                    element.Januarytonow === "C" ?
                                                      <>
                                                        <Stack justifyContent={"center"} sx={{ width: "26px", height: "26px", bgcolor: "rgba(212, 195, 38, 0.4)" }}>
                                                          <Typography align='center' sx={{ marginTop: "2px", color: '#ffffff', fontSize: ciiSize, fontWeight: "bold", }}>
                                                            {element.Januarytonow}
                                                          </Typography>
                                                        </Stack>
                                                      </>
                                                      :
                                                      <>
                                                        {
                                                          element.Januarytonow === "D" ?
                                                            <>
                                                              <Stack justifyContent={"center"} sx={{ position: "relative", width: "26px", height: "26px", bgcolor: "rgba(255, 103, 103, 0.45)" }}>
                                                                <Typography align='center' sx={{ marginTop: "2px", color: '#ffffff', fontSize: ciiSize, fontWeight: "bold", }}>
                                                                  {element.Januarytonow}
                                                                </Typography>
                                                                <Stack sx={{ position: "absolute", top: "11px", left: "15px", bgcolor: "transparent" }} alignItems={"center"}>
                                                                  <ReportProblemIcon sx={{ height: "17px", width: "21px", color: "yellow" }} />
                                                                </Stack>
                                                              </Stack>
                                                            </>
                                                            :
                                                            <>
                                                              {
                                                                element.Januarytonow === "E" ?
                                                                  <>
                                                                    <Stack justifyContent={"center"} sx={{ position: "relative", width: "26px", height: "26px", bgcolor: "rgba(255, 47, 47, 0.45)" }}>
                                                                      <Typography align='center' sx={{ marginTop: "2px", color: '#ffffff', fontSize: ciiSize, fontWeight: "bold", }}>
                                                                        {element.Januarytonow}
                                                                      </Typography>
                                                                      <Stack sx={{ position: "absolute", top: "11px", left: "15px", bgcolor: "transparent" }} alignItems={"center"}>
                                                                        <ReportProblemIcon sx={{ height: "17px", width: "21px", color: "yellow" }} />
                                                                      </Stack>
                                                                    </Stack>
                                                                    
                                                                  </>
                                                                  :
                                                                  <>
                                                                    {
                                                                      element.Januarytonow === "" ?
                                                                        <Typography align='center' sx={{ color: 'rgba(255, 255, 255, 0.6)', fontSize: "12px", fontFamily: 'Roboto Regular', textTransform: 'none' }}>
                                                                          No Data
                                                                        </Typography>
                                                                        :
                                                                        ""
                                                                    }
                                                                  </>
                                                              }
                                                            </>
                                                        }
                                                      </>
                                                  }
                                                </>
                                            }
                                          </>
                                      }
                                    </Button>
                                  </Stack>
                                </td>
                                <td className="EndofYear">
                                  <Stack direction={"row"} sx={{ width: "100%", height: "100%", }} >
                                    <Button
                                      onClick={() => handleClickCiiAnalysis(index, element.imo, element.CIIEndofYear_from, element.CIIEndofYear_to, "Simulation")}
                                      sx={{ width: "100%", height: "100%", ":hover": { bgcolor: "rgba(255, 255, 255, 0.5)", } }}
                                      disabled={element.CIIEndofYear === "" ? true : false}>
                                      {
                                        element.CIIEndofYear === "A" ?
                                          <>
                                            <Stack justifyContent={"center"} sx={{ width: "26px", height: "26px", bgcolor: "rgba(0, 144, 206, 0.4)" }}>
                                              <Typography align='center' sx={{ marginTop: "2px", color: '#ffffff', fontSize: ciiSize, fontWeight: "bold", }}>
                                                {element.CIIEndofYear}
                                              </Typography>
                                            </Stack>
                                          </>
                                          :
                                          <>
                                            {
                                              element.CIIEndofYear === "B" ?
                                                <>
                                                  <Stack justifyContent={"center"} sx={{ width: "26px", height: "26px", bgcolor: "rgba(6, 165, 80, 0.4)" }}>
                                                    <Typography align='center' sx={{ marginTop: "2px", color: '#ffffff', fontSize: ciiSize, fontWeight: "bold", }}>
                                                      {element.CIIEndofYear}
                                                    </Typography>
                                                  </Stack>
                                                </>
                                                :
                                                <>
                                                  {
                                                    element.CIIEndofYear === "C" ?
                                                      <>
                                                        <Stack justifyContent={"center"} sx={{ width: "26px", height: "26px", bgcolor: "rgba(212, 195, 38, 0.4)" }}>
                                                          <Typography align='center' sx={{ marginTop: "2px", color: '#ffffff', fontSize: ciiSize, fontWeight: "bold", }}>
                                                            {element.CIIEndofYear}
                                                          </Typography>
                                                        </Stack>
                                                      </>
                                                      :
                                                      <>
                                                        {
                                                          element.CIIEndofYear === "D" ?
                                                            <>
                                                              <Stack justifyContent={"center"} sx={{ position: "relative", width: "26px", height: "26px", bgcolor: "rgba(255, 103, 103, 0.45)" }}>
                                                                <Typography align='center' sx={{ marginTop: "2px", color: '#ffffff', fontSize: ciiSize, fontWeight: "bold", }}>
                                                                  {element.CIIEndofYear}
                                                                </Typography>
                                                                <Stack sx={{ position: "absolute", top: "11px", left: "15px", bgcolor: "transparent" }} alignItems={"center"}>
                                                                  <ReportProblemIcon sx={{ height: "17px", width: "21px", color: "yellow" }} />
                                                                </Stack>
                                                              </Stack>
                                                            </>
                                                            :
                                                            <>
                                                              {
                                                                element.CIIEndofYear === "E" ?
                                                                  <>
                                                                    <Stack justifyContent={"center"} sx={{ position: "relative", width: "26px", height: "26px", bgcolor: "rgba(255, 47, 47, 0.45)" }}>
                                                                      <Typography align='center' sx={{ marginTop: "2px", color: '#ffffff', fontSize: ciiSize, fontWeight: "bold", }}>
                                                                        {element.CIIEndofYear}
                                                                      </Typography>
                                                                      <Stack sx={{ position: "absolute", top: "11px", left: "15px", bgcolor: "transparent" }} alignItems={"center"}>
                                                                        <ReportProblemIcon sx={{ height: "17px", width: "21px", color: "yellow" }} />
                                                                        {/* <img src={Problem_icon_svg} alt="iconLike" style={{ height: "13px", width: "16px" }} /> */}
                                                                      </Stack>
                                                                    </Stack>
                                                                    
                                                                  </>
                                                                  :
                                                                  <>
                                                                    {
                                                                      element.CIIEndofYear === "" ?
                                                                        <Typography align='center' sx={{ color: 'rgba(255, 255, 255, 0.6)', fontSize: "12px", fontFamily: 'Roboto Regular', textTransform: 'none' }}>
                                                                          No Data
                                                                        </Typography>
                                                                        :
                                                                        ""
                                                                    }
                                                                  </>
                                                              }
                                                            </>
                                                        }
                                                      </>
                                                  }
                                                </>
                                            }
                                          </>
                                      }
                                    </Button>
                                  </Stack>
                                </td>
                                <td className="LastYear">
                                  <Stack direction={"row"} sx={{ width: "100%", height: "100%" }}>
                                    <Button
                                      onClick={() => handleClickCiiAnalysis(index, element.imo, element.LastYear_from, element.LastYear_to, "Emission")}
                                      sx={{ width: "100%", height: "100%", ":hover": { bgcolor: "rgba(255, 255, 255, 0.5)", } }}
                                      disabled={element.LastYear === "" ? true : false}>
                                      {
                                        element.LastYear === "A" ?
                                          <>
                                            <Stack justifyContent={"center"} sx={{ width: "26px", height: "26px", bgcolor: "rgba(0, 144, 206, 0.4)" }}>
                                              <Typography align='center' sx={{ marginTop: "2px", color: '#ffffff', fontSize: ciiSize, fontWeight: "bold", }}>
                                                {element.LastYear}
                                              </Typography>
                                            </Stack>
                                          </>
                                          :
                                          <>
                                            {
                                              element.LastYear === "B" ?
                                                <>
                                                  <Stack justifyContent={"center"} sx={{ width: "26px", height: "26px", bgcolor: "rgba(6, 165, 80, 0.4)" }}>
                                                    <Typography align='center' sx={{ marginTop: "2px", color: '#ffffff', fontSize: ciiSize, fontWeight: "bold", }}>
                                                      {element.LastYear}
                                                    </Typography>
                                                  </Stack>
                                                </>
                                                :
                                                <>
                                                  {
                                                    element.LastYear === "C" ?
                                                      <>
                                                        <Stack justifyContent={"center"} sx={{ width: "26px", height: "26px", bgcolor: "rgba(212, 195, 38, 0.4)" }}>
                                                          <Typography align='center' sx={{ marginTop: "2px", color: '#ffffff', fontSize: ciiSize, fontWeight: "bold", }}>
                                                            {element.LastYear}
                                                          </Typography>
                                                        </Stack>
                                                      </>
                                                      :
                                                      <>
                                                        {
                                                          element.LastYear === "D" ?
                                                            <>
                                                              <Stack justifyContent={"center"} sx={{ position: "relative", width: "26px", height: "26px", bgcolor: "rgba(255, 103, 103, 0.45)" }}>
                                                                <Typography align='center' sx={{ marginTop: "2px", color: '#ffffff', fontSize: ciiSize, fontWeight: "bold", }}>
                                                                  {element.LastYear}
                                                                </Typography>
                                                              </Stack>
                                                            </>
                                                            :
                                                            <>
                                                              {
                                                                element.LastYear === "E" ?
                                                                  <>
                                                                    <Stack justifyContent={"center"} sx={{ position: "relative", width: "26px", height: "26px", bgcolor: "rgba(255, 47, 47, 0.45)" }}>
                                                                      <Typography align='center' sx={{ marginTop: "2px", color: '#ffffff', fontSize: ciiSize, fontWeight: "bold", }}>
                                                                        {element.LastYear}
                                                                      </Typography>
                                                                    </Stack>
                                                                  </>
                                                                  :
                                                                  <>
                                                                    {
                                                                      element.LastYear === "" ?
                                                                        <Typography align='center' sx={{ color: 'rgba(255, 255, 255, 0.6)', fontSize: "12px", textTransform: 'none' }}>
                                                                          No Data
                                                                        </Typography>
                                                                        :
                                                                        ""
                                                                    }
                                                                  </>
                                                              }
                                                            </>
                                                        }
                                                      </>
                                                  }
                                                </>
                                            }
                                          </>
                                      }
                                    </Button>
                                  </Stack>
                                </td>
                                {/* <td className="padding3">
                                  <span></span>
                                </td> */}
                                <td className="eua-year-to-date">
                                  <Button
                                    onClick={() => handleClickEuEtsAndFuelEu(index, element.imo, element.Januarytonow_from, element.Januarytonow_to, "Emission")}
                                    sx={{
                                      width: "100%",
                                      height: "100%",
                                      ":hover": {
                                        bgcolor: "rgba(255, 255, 255, 0.5)",
                                        color: "orange",
                                      }
                                    }}
                                    disabled={element.EUAYeartoDate === "0" ? true : false}
                                  >
                                    <Grid item xs={12} sx={{ width: "100%" }}>
                                      <Stack>
                                        {
                                          element.EUAYeartoDate !== "0" ?
                                            <>
                                              <Typography align='right' sx={{ color: '#ffffff', width: "100%", fontSize: eua_cb_Size, }}>
                                                {formatNumber(element.EUAYeartoDate)}
                                              </Typography>
                                            </>
                                            :
                                            <>
                                              <Typography align='right' sx={{ color: '#ffffff', fontSize: "12px", textTransform: 'none' }}>
                                                0
                                              </Typography>
                                            </>
                                        }
                                      </Stack>
                                    </Grid>
                                  </Button>
                                </td>
                                <td className="eua-end-of-year">
                                  <Button
                                    onClick={() => handleClickEuEtsAndFuelEu(index, element.imo, element.CIIEndofYear_from, element.CIIEndofYear_to, "Simulation")}
                                    sx={{
                                      width: "100%",
                                      height: "100%",
                                      ":hover": {
                                        bgcolor: "rgba(255, 255, 255, 0.5)",
                                        color: "orange",
                                      }
                                    }}
                                    disabled={element.EUAEndofYear === "0" ? true : false}
                                  >
                                    <Grid item xs={12} sx={{ width: "100%" }}>
                                      <Stack>
                                        {
                                          element.EUAEndofYear !== "0" ?
                                            <>
                                              <Typography align='right' sx={{ color: '#ffffff', width: "100%", fontSize: eua_cb_Size, }}>
                                                {formatNumber(element.EUAEndofYear)}
                                              </Typography>
                                            </>
                                            :
                                            <>
                                              <Typography align='right' sx={{ color: '#ffffff', fontSize: "12px", textTransform: 'none' }}>
                                                0
                                              </Typography>
                                            </>
                                        }
                                      </Stack>
                                    </Grid>
                                  </Button>
                                </td>
                                <td className="padding4">
                                  <span></span>
                                </td>
                                <td className="cb-year-to-date">
                                  <Button
                                    onClick={() => handleClickEuEtsAndFuelEu(index, element.imo, element.Januarytonow_from, element.Januarytonow_to, "Emission")}
                                    sx={{
                                      width: "100%",
                                      height: "100%",
                                      ":hover": {
                                        bgcolor: "rgba(255, 255, 255, 0.5)",
                                        color: "orange",
                                      }
                                    }}
                                    disabled={element.CBYeartoDate === "0.0" ? true : false}
                                  >
                                    <Grid item xs={12} sx={{ width: "100%" }}>
                                      <Stack>
                                        {
                                          element.CBYeartoDate !== "0.0" ?
                                            <>
                                              <Typography align='right' sx={{ color: '#ffffff', width: "100%", fontSize: eua_cb_Size, }}>
                                                {formatNumber(element.CBYeartoDate)}
                                              </Typography>
                                            </>
                                            :
                                            <>
                                              <Typography align='right' sx={{ color: '#ffffff', fontSize: "12px", textTransform: 'none' }}>
                                                0
                                              </Typography>
                                            </>
                                        }
                                      </Stack>
                                    </Grid>
                                  </Button>
                                </td>
                                <td className="cb-end-of-year">
                                  <Button
                                    onClick={() => handleClickEuEtsAndFuelEu(index, element.imo, element.CIIEndofYear_from, element.CIIEndofYear_to, "Simulation")}
                                    sx={{
                                      width: "100%",
                                      height: "100%",
                                      ":hover": {
                                        bgcolor: "rgba(255, 255, 255, 0.5)",
                                        color: "orange",
                                      }
                                    }}
                                    disabled={element.CBEndofYear === "0.0" ? true : false}
                                  >
                                    <Grid item xs={12} sx={{ width: "100%" }}>
                                      <Stack>
                                        {
                                          element.CBEndofYear !== "0.0" ?
                                            <>
                                              <Typography align='right' sx={{ color: '#ffffff', width: "100%", fontSize: eua_cb_Size, }}>
                                                {formatNumber(element.CBEndofYear)}
                                              </Typography>
                                            </>
                                            :
                                            <>
                                              <Typography align='right' sx={{ color: '#ffffff', fontSize: "12px", textTransform: 'none' }}>
                                                0
                                              </Typography>
                                            </>
                                        }
                                      </Stack>
                                    </Grid>
                                  </Button>
                                </td>
                                <td className="padding5">
                                  <span></span>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </>
                      :
                      ""
                    }
                  </tbody>
                  :
                  <Box>
                    {loading && (
                      <CircularProgress
                        size={68}
                        sx={{
                          color: "grey",
                          position: 'absolute',
                          top: 500,
                          left: 950,
                          zIndex: 1,
                        }}
                      />
                    )}
                  </Box>
                }
              </table>
            </div>
          </div>
        </Box>
      </Main >
    </Box >
  );
}
