
import * as React from 'react';
import { useLocation } from 'react-router-dom';

import axios, { AxiosError } from "axios";

import './EuEtsAndFuelEu.css'; // スタイルのために追加
import { Main, Drawer, BootstrapInput } from '../../components/styled/styled';
import { callAuth } from '../../components/components/auth';
import { SearchContext } from '../../App';
import { MyAppBar } from '../../components/components/AppBar';
import { SimulationSideBar } from '../../components/components/SimulationSideBar';
import { EmissionBoardSideBar}  from '../../components/components/EmissionBoardSideBar';
import { EuSimulation }  from './Simulation/EuSimulation';
import { EuList }  from './List/EuList';
import { EuEmissionBoard }  from './EmissionBoard/EuEmissionBoard';
import { FuelEuSupportTool }  from './FuelEuSupportTool/FuelEuSupportTool';

// MUI @mui/material/
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';

// Dialog
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

// Axios Error
interface ErrorData {
  message: string;
  status: number;
  error: string;
}

function formatNumber(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export default function EuEtsAndFuelEu({ setSeachInfo }) {

  // userId有効期限チェック----------------------------------------------------------------------------------------------------------------------------
  const [message, setMessage] = React.useState<string>();
  const [userCheck, setUserCheck] = React.useState(false);
  let init = true

  const [user_id, setUser_id] = React.useState<string>();
  const [group_id, setGroup_id] = React.useState<string>();
  const [company_id, setCompany_id] = React.useState<string>();
  const [gid, setGid] = React.useState<string>();
  const [gidList, setGidList] = React.useState<string>();
  const [imoList, setImoList] = React.useState<string>();
  const [jwt, setJwt] = React.useState<string>();
  const [role, setRole] = React.useState<string>();

  // SimulationSideVer関連
  const [chanveVesselFlg, setChanveVesselFlg] = React.useState<boolean>(false);
  const [focFormulaBallast, setFocFormulaBallast] = React.useState<any[]>([]);
  const [focFormulaLaden, setFocFormulaLaden] = React.useState<any[]>([]);
  const [focFormulaAux, setFocFormulaAux] = React.useState<any>();

  // SelectVesselNameコンポーネント用
  const [vesselOpen, setVesselOpen] = React.useState<boolean>(false);
  const [currentFocusVessel, setCurrentFocusVessel] = React.useState<string>(''); // 矢印キー(↑↓)でドロップダウン船名変更時のフォーカスしている船
  const [vesselName, setVesselName] = React.useState<string>(''); // 表示船名

  React.useEffect(() => {
    if (init === true) {

      // localStrage取得----------------------------------------------------------------------------------------------------------------------------
      let jwt_ls
      if (localStorage.getItem("payload")) {
        const pay_json = localStorage.getItem("payload")
        if (pay_json !== null) {
          const pay = JSON.parse(pay_json)
          jwt_ls = pay["jwt"]
          setUser_id(pay["user_id"])
          setGroup_id(pay["group_id"])
          setCompany_id(pay["company_id"])
          setGidList(pay["gidList"])
          setImoList(pay["imoList"])
          setJwt(jwt_ls)
          setRole(pay["role"])

          let tmp_gid = ""
          // gidがFavariteの場合の考慮（この画面のGroupにはFavoriteは表示しないため）
          if (pay["gid"] === "Favorite") {
            tmp_gid = "admin"
          }
          else {
            tmp_gid = pay["gid"]
          }
          setGid(tmp_gid)

          setSeachInfo({ imoNo: `${SeachInfo.imoNo}`, from: SeachInfo.from, to: SeachInfo.to, gid: tmp_gid, gidList: pay["gidList"], imoList: pay["imoList"] })

          // 選択中の船のimo設定
          setCurrentFocusVessel(SeachInfo.imoNo)
          
          // 選択中の船名がある場合、船名取得し初期表示として設定
          for (const imoData of pay["imoList"]) {
            if (imoData.imoNo == SeachInfo.imoNo) {
              setVesselName(imoData.VesselName)
            }
          }

          // 画面遷移時、開くタブを確認する
          checkNavigationTab()
        }
      }

      if (jwt_ls) {
        callAuth(jwt_ls).then(result => {
          console.log("result:" + result)
          if (result) {
            console.log("認証成功[EU-ETS&FuelEU]");

            // Loading解除
            setLoading(false);
            setSuccess(true);
            setMessage("Authentication success.")
          } else {
            console.log("認証失敗[EU-ETS&FuelEU]");
            setUserCheck(true)
            setMessage("Authentication error.")
            localStorage.clear()
          }
        });
      }
    }

    init = false
  }, []);

  // WADATSUMIログイン画面遷移----------------------------------------------------------------------------------------------------------------------------
  const handleClickWADATSUMI = () => {
    window.location.replace(`${process.env.REACT_APP_WADATSUMIURL}`)
  };

  // コンテクスト取得----------------------------------------------------------------------------------------------------------------------------
  const SeachInfo: any = React.useContext(SearchContext);


  // 検索フォーム----------------------------------------------------------------------------------------------------------------------------
  const [base_url, setBase_url] = React.useState(`${process.env.REACT_APP_API_URL}/DataViewer/`);


  // Select Imo----------------------------------------------------------------------------------------------------------------------------
  const selectImo = (imoNo: string) => {
    setSeachInfo({ imoNo: imoNo, from: SeachInfo.from, to: SeachInfo.to, gid: gid, gidList: gidList, imoList: imoList })

    // 船変更かつSimulation画面の場合
    if (activeTab === 1 || activeTab === 2) {
      setChanveVesselFlg(true);
    }
  };

  // AggrigateUnit----------------------------------------------------------------------------------------------------------------------
  const [unit, setUnit] = React.useState("");

  // Drawer----------------------------------------------------------------------------------------------------------------------------
  const [openDrwer, setOpenDrwer] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpenDrwer(true);
  };
  const handleDrawerClose = () => {
    setOpenDrwer(false);
  };

  // VesselMaster保持用
  const [vesselMaster, setVesselMaster] = React.useState<any>();

  // Loading--------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(true);


  // 時系列データ取得--------------------------------------------------------------------
  const [authCheck, setAuthCheck] = React.useState(false);
  const [authMessage, setAuthMessage] = React.useState("");

  // タブ切り替え機能--------------------------------------------------------------------
  // 現在のアクティブなタブを管理するための状態
  const [activeTab, setActiveTab] = React.useState(0);
  // 画面遷移時のタブ設定
  const location = useLocation()
  const [navigationTab, setnavigationTab] = React.useState<{ activeTab: string }>(location.state as { activeTab: string })
  const checkNavigationTab = () => {
    let paraNom = 0
    if (navigationTab !== null) {
      if (navigationTab.activeTab == "Emission")
        paraNom = 1
      else if (navigationTab.activeTab == "Simulation")
        paraNom = 2

      handleTabClick(paraNom)
      setUnit("Leg")
    }
  }

  // タブのサイズ変更用の定数
  const [tabMarginSize, setTabMarginSize] = React.useState(0.175);
  const [tabSize, setTabSize] = React.useState(2.9125);

  // タブがクリックされた時に呼び出されるハンドラー関数
  const handleTabClick = (index) => {
    setActiveTab(index);
    // 1:EmissionBoard、2:Simulationの場合のみ、サイドバーをオープン
    if (index === 1) {
      setOpenDrwer(true);
    }
    else if (index === 2) {
      setOpenDrwer(true);
    }
    else {
      setOpenDrwer(false);
    }

    // タブによって、タブのサイズを調整
    if (index === 0 || index ===3) {
      // List、FuelEuSupportToolの場合　少し狭め
      setTabMarginSize(0.175);
      setTabSize(2.9125);
    }
    else {
      // EmissionBoard、Simulationの場合　少し広め
      setTabMarginSize(0.025);
      setTabSize(2.9875);
    }
  };


  return (
    <Box height="100vh" sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* useId有効期限切れダイアログ */}
      <Dialog
        open={userCheck}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",  // Set your width here
              height: "100%",
              maxHeight: "210px",  // Set your width here
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            bgcolor: "#9ea2ad",
            height: "50px",
            color: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}>
          UserId Update
        </DialogTitle>
        <DialogContent
          sx={{
            background: "linear-gradient(#40475d, #0d1532)",
            height: "100px",
          }}>
          <Typography align="left" sx={{ marginTop: "32px", color: 'white', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', wordWrap: 'break-word' }}>
            {message === "" ? "" : message}
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            height: "50px",
            bgcolor: "#474C53",
          }}>
          <Button
            sx={{
              marginLeft: "0px",
              marginRight: "-8px",
              backgroundColor: "#e66300",
              width: "150px",
              height: "50px",
              padding: "0",
              fontSize: "18px",
              fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
              fontWeight: "bold",
              color: "white",
              borderRadius: "0px",
              borderBottomRightRadius: "2px",
              ":hover": {
                bgcolor: "#FFFFFF",
                color: "#40475D",
              },
              textTransform: 'none'
            }}
            onClick={handleClickWADATSUMI}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* 認可チェックダイアログ */}
      <Dialog
        open={authCheck}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",  // Set your width here
              height: "100%",
              maxHeight: "210px",  // Set your width here
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            bgcolor: "#9ea2ad",
            height: "50px",
            color: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}>
          Auth check
        </DialogTitle>
        <DialogContent
          sx={{
            background: "linear-gradient(#40475d, #0d1532)",
            height: "100px",
          }}>
          <Typography align="left" sx={{ marginTop: "32px", color: 'white', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', wordWrap: 'break-word' }}>
            {authMessage === "" ? "" : authMessage}
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            height: "50px",
            bgcolor: "#474C53",
          }}>
          <Button
            sx={{
              marginLeft: "0px",
              marginRight: "-8px",
              backgroundColor: "#e66300",
              width: "150px",
              height: "50px",
              padding: "0",
              fontSize: "18px",
              fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
              fontWeight: "bold",
              color: "white",
              borderRadius: "0px",
              borderBottomRightRadius: "2px",
              ":hover": {
                bgcolor: "#FFFFFF",
                color: "#40475D",
              },
              textTransform: 'none'
            }}
            onClick={() => setAuthCheck(false)}>
            OK
          </Button>
        </DialogActions>
      </Dialog>


      {/* menu bar */}
      <MyAppBar company_id={company_id} group_id={group_id} user_id={user_id} role={role} />

      {/* side bar */}
      <Drawer
        variant="permanent"
        open={openDrwer}
        sx={{
          // background: 'linear-gradient(180deg, #3B3D43 0%, #383A40 100%)',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            background: '#282F35',
            boxSizing: 'border-box',
            borderRight: "1px solid rgb(201,201,201,0.2)"
          },
        }}>
        
        {/* side bar */}
        <React.Fragment>
          {/* 検索フォーム開閉 */}
            {activeTab === 1 ?
              // EmissionBoard選択時
              <EmissionBoardSideBar
                vesselName = {vesselName}
                vesselOpen = {vesselOpen}
                currentFocusVessel = {currentFocusVessel}
                unit = {unit}
                setVesselName = {(updateValue: string) => setVesselName(updateValue)}
                setVesselOpen = {(updateValue: boolean) => setVesselOpen(updateValue)}
                setCurrentFocusVessel = {(updateValue: string) => setCurrentFocusVessel(updateValue)}
                setUnit = {(updateValue: string) => setUnit(updateValue)}
                selectImo = {(updateValue: string) => selectImo(updateValue)}

                vesselMaster = {vesselMaster}
                setVesselMaster = {(updateValue: any) => setVesselMaster(updateValue)}
                
                SeachInfo = {SeachInfo}
                setSeachInfo = {(updateValue: any) => setSeachInfo(updateValue)}

                calledFunctionName = {"eu-ets-and-fuel-eu"}
              />
              :
              activeTab === 2 ?
                // Simulation選択時
                <SimulationSideBar
                  vesselName = {vesselName}
                  vesselOpen = {vesselOpen}
                  currentFocusVessel = {currentFocusVessel}
                  vesselMaster = {vesselMaster}
                  focFormulaBallast = {focFormulaBallast}
                  setFocFormulaBallast = {(updateValue: any[]) => setFocFormulaBallast(updateValue)}
                  focFormulaLaden = {focFormulaLaden}
                  setFocFormulaLaden = {(updateValue: any[]) => setFocFormulaLaden(updateValue)}
                  focFormulaAux = {focFormulaAux}
                  setFocFormulaAux = {(updateValue: any) => setFocFormulaAux(updateValue)}
                  setVesseName = {(updateValue: string) => setVesselName(updateValue)}
                  setVesselOpen = {(updateValue: boolean) => setVesselOpen(updateValue)}
                  setCurrentFocusVessel = {(updateValue: string) => setCurrentFocusVessel(updateValue)}
                  selectImo = {(updateValue: string) => selectImo(updateValue)}

                  SeachInfo = {SeachInfo}
                  setSeachInfo = {(updateValue: any) => setSeachInfo(updateValue)}
                />
                :
                <Box>
                </Box>    
            }
          </React.Fragment >
      </Drawer >

      {/* メイン部分 */}
      <Main sx={{overflow: "hidden"}}>
        {/* ↓↓ タブ ヘッダー ↓↓ */}
        <Box sx={{ width: "100%", height: "20px" }}>
        </Box>
        <Grid container spacing={0.5} direction={"row"} alignItems={"center"}>
          <Grid item xs={tabMarginSize}></Grid>
          <Grid item xs={tabSize}>
            <Button
              sx={{
                width: "100%",
                height: "25px",
                borderBottomLeftRadius: "0px",
                borderBottomRightRadius: "0px",
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
                fontSize: "14px",
                // fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
                fontWeight: "bold",
                color: `${activeTab === 0 ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.5)"}`,
                backgroundColor: `${activeTab === 0 ? "rgba(230, 99, 0, 0.70)" : "rgba(224, 224, 224, 0.15)"}`,
                textTransform: 'none',
                ":hover": { bgcolor: "rgba(255, 255, 255, 0.6)", color: "rgba(255, 255, 255, 0.8)", },
              }}
              onClick={() => handleTabClick(0)}
            >
              <Stack direction={"row"}>
                <Box sx={{ textAlign: "center" }}>List</Box>
              </Stack>
            </Button>
          </Grid>
          <Grid item xs={tabSize}>
            <Button
              sx={{
                width: "100%",
                height: "25px",
                borderBottomLeftRadius: "0px",
                borderBottomRightRadius: "0px",
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
                fontSize: "14px",
                // fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
                fontWeight: "bold",
                color: `${activeTab === 1 ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.5)"}`,
                backgroundColor: `${activeTab === 1 ? "rgba(230, 99, 0, 0.70)" : "rgba(224, 224, 224, 0.15)"}`,
                textTransform: 'none',
                ":hover": { bgcolor: "rgba(255, 255, 255, 0.6)", color: "rgba(255, 255, 255, 0.8)", },
              }}
              onClick={() => handleTabClick(1)}
            >
              <Stack direction={"row"}>
                <Box sx={{ textAlign: "center" }}>Emission Board</Box>
              </Stack>
            </Button>
          </Grid>
          <Grid item xs={tabSize}>
            <Button
              sx={{
                width: "100%",
                height: "25px",
                borderBottomLeftRadius: "0px",
                borderBottomRightRadius: "0px",
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
                fontSize: "14px",
                // fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
                fontWeight: "bold",
                color: `${activeTab === 2 ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.5)"}`,
                backgroundColor: `${activeTab === 2 ? "rgba(230, 99, 0, 0.70)" : "rgba(224, 224, 224, 0.15)"}`,
                textTransform: 'none',
                ":hover": { bgcolor: "rgba(255, 255, 255, 0.6)", color: "rgba(255, 255, 255, 0.8)", },
              }}
              onClick={() => handleTabClick(2)}
            >
              <Stack direction={"row"}>
                <Box sx={{ textAlign: "center" }}>Simulation</Box>
              </Stack>
            </Button>
          </Grid>
          <Grid item xs={tabSize}>
            <Button
              sx={{
                width: "100%",
                height: "25px",
                borderBottomLeftRadius: "0px",
                borderBottomRightRadius: "0px",
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
                fontSize: "14px",
                // fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
                fontWeight: "bold",
                color: `${activeTab === 3 ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.5)"}`,
                backgroundColor: `${activeTab === 3 ? "rgba(230, 99, 0, 0.70)" : "rgba(224, 224, 224, 0.15)"}`,
                textTransform: 'none',
                ":hover": { bgcolor: "rgba(255, 255, 255, 0.6)", color: "rgba(255, 255, 255, 0.8)", },
              }}
              onClick={() => handleTabClick(3)}
            >
              <Stack direction={"row"}>
                <Box sx={{ textAlign: "center" }}>FuelEU Support Tool</Box>
              </Stack>
            </Button>
          </Grid>
          <Grid item xs={tabMarginSize}></Grid>
        </Grid>
        {/* ↑↑ タブ ヘッダー ↑↑ */}   
        
        <Box className="euets-fueleu-main-aera">

          {/* タブにより表示切替 */}
          {activeTab === 0 ?
            // List
            <EuList
              setAuthCheck = {(updateValue: boolean) => setAuthCheck(updateValue)}
              setAuthMessage = {(updateValue: any) => setAuthMessage(updateValue)}
              activeTab = {activeTab}
              setActiveTab = {(updateValue: any) => setActiveTab(updateValue)}
              unit = {unit}
              setUnit = {(updateValue: any) => setUnit(updateValue)}
              openDrwer = {unit}
              setOpenDrwer = {(updateValue: any) => setOpenDrwer(updateValue)}
              tabMarginSize = {tabMarginSize}
              setTabMarginSize = {(updateValue: any) => setTabMarginSize(updateValue)}
              tabSize = {tabSize}
              setTabSize = {(updateValue: any) => setTabSize(updateValue)}
              vesselName = {tabSize}
              setVesselName = {(updateValue: any) => setVesselName(updateValue)}
              SeachInfo = {SeachInfo}
              setSeachInfo = {(updateValue: any) => setSeachInfo(updateValue)}
              />
            : 
            activeTab === 1 ?
              // Emission Board
              <EuEmissionBoard
                unit = {unit}
                vesselMaster = {vesselMaster}
                setVesselMaster = {(updateValue: any) => setVesselMaster(updateValue)}
                changeVesselFlg = {chanveVesselFlg}
                setChangeVesselFlg = {(updateValue: boolean) => setChanveVesselFlg(updateValue)}
                setAuthCheck = {(updateValue: boolean) => setAuthCheck(updateValue)}
                setAuthMessage = {(updateValue: any) => setAuthMessage(updateValue)}
                SeachInfo = {SeachInfo}
                setSeachInfo = {(updateValue: any) => setSeachInfo(updateValue)}
                />
              : 
              activeTab === 2 ?
                // Simulation
                <EuSimulation
                    changeVesselFlg = {chanveVesselFlg}
                    setChangeVesselFlg = {(updateValue: boolean) => setChanveVesselFlg(updateValue)}
                    vesselMaster = {vesselMaster}
                    setVesselMaster = {(updateValue: any) => setVesselMaster(updateValue)}
                    focFormulaBallast = {focFormulaBallast}
                    setFocFormulaBallast = {(updateValue: any[]) => setFocFormulaBallast(updateValue)}
                    focFormulaLaden = {focFormulaLaden}
                    setFocFormulaLaden = {(updateValue: any[]) => setFocFormulaLaden(updateValue)}
                    focFormuAux = {focFormulaAux}
                    setFocFormuAux = {(updateValue: any) => setFocFormulaAux(updateValue)}
                    setAuthCheck = {(updateValue: boolean) => setAuthCheck(updateValue)}
                    setAuthMessage = {(updateValue: any) => setAuthMessage(updateValue)}
                    SeachInfo = {SeachInfo}
                    setSeachInfo = {(updateValue: any) => setSeachInfo(updateValue)}
                  />
                : 
                activeTab === 3 ?
                  // FuelEU Support Tool
                  <FuelEuSupportTool
                    setAuthCheck = {(updateValue: boolean) => setAuthCheck(updateValue)}
                    setAuthMessage = {(updateValue: any) => setAuthMessage(updateValue)}
                    SeachInfo = {SeachInfo}
                    setSeachInfo = {(updateValue: any) => setSeachInfo(updateValue)}
                    />
                  : 
                  <Box></Box>
          }
        </Box>
      </Main>
    </Box>
  );
} 