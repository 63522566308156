import React from "react";

type ProgressTimelineProps = {
  departureTime: string;
  arrivalTime: string;
};

const ProgressTimeline: React.FC<ProgressTimelineProps> = ({ departureTime, arrivalTime }) => {
  const now = new Date().getTime();
  const departure = new Date(departureTime).getTime();
  const arrival = new Date(arrivalTime).getTime();

  // 進行度の計算（0～1の範囲）
  const progress = Math.min(Math.max((now - departure) / (arrival - departure), 0), 1);

  // SVG の設定
  const width = 380;
  const height = 11;
  const lineY = height / 2;
  const radius = 3;
  const strokeWidth = 1.5;
  const margin = 10;

  // 線の描画範囲
  const startX = margin + radius;
  const endX = width - margin - radius;
  const progressX = startX + progress * (endX - startX);

  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      {/* ベースライン */}
      <line x1={startX} y1={lineY} x2={endX} y2={lineY} stroke="rgba(255, 255, 255, 0.5)" strokeWidth={strokeWidth} />
      
      {/* 進行ライン */}
      <line x1={startX} y1={lineY} x2={progressX} y2={lineY} stroke="white" strokeWidth={strokeWidth} />

      {/* 左端（出発地）の円 */}
      <circle cx={margin} cy={lineY} r={radius} fill="url(#gradient)" />

      {/* 現在位置の円 */}
      <circle cx={progressX} cy={lineY} r={radius} fill="url(#gradient)" />

      {/* 右端（到着地）の円 */}
      <circle cx={width - margin} cy={lineY} r={radius} stroke="white" strokeWidth={strokeWidth} fill="none" />

      {/* グラデーション定義 */}
      <defs>
        <radialGradient id="gradient" cx="50%" cy="50%" r="50%">
          <stop offset="0%" stopColor="#BFFFFF" />
          <stop offset="100%" stopColor="#05FFFF" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default ProgressTimeline;
