
import React, { useState, useRef } from "react";

import axios, { AxiosError } from "axios";

import './EuList.css'; // スタイルのために追加

// MUI @mui/material/
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import { BootstrapInput2 } from '../../../components/styled/styled';
import MenuItem from '@mui/material/MenuItem';
import { SelectTheme } from '../../../components/theme/SelectTheme';
import Input from '@mui/material/Input';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

// MUI @mui/icons-material/
// @mui/icons-material/
import SearchIcon from '@mui/icons-material/Search';

interface payload {
  role: string | null
  user_id: string | null
  group_id: string | null
  company_id: string | null
  gid: string | null
  gidList: Array<string>
  imoList: Array<string>
  jwt: string | null
}

// Axios Error
interface ErrorData {
  message: string;
  status: number;
  error: string;
}

// 親コンポーネントから持ち回る値
export type Props = {
  setAuthCheck: (value: boolean) => void;
  setAuthMessage: (value: any) => void;
  activeTab: any;
  setActiveTab: (value: any) => void;
  unit: any;
  setUnit: (value: any) => void;
  openDrwer: any;
  setOpenDrwer: (value: any) => void;
  tabMarginSize: any;
  setTabMarginSize: (value: any) => void;
  tabSize: any;
  setTabSize: (value: any) => void;
  vesselName: any;
  setVesselName: (value: any) => void;

  SeachInfo: any;
  setSeachInfo: (value: any) => void;
}

function formatNumber(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// EU List Component--------------------------------------------------------------------
export const EuList: React.FC<Props> = props => {

  const [jwt, setJwt] = React.useState<string>();
  const [userId, setUserId] = React.useState<string>();
  const [role, setRole] = React.useState<string>();

  const [yearMap, setYearMap] = React.useState<any>([]);
  const [todayYear, setTodayYear] = React.useState<String>("");
  const [todayYearFirstDay, setTodayYearFirstDay] = React.useState<String>("");
  const [todayYeaLastDay, setTodayYeaLastDay] = React.useState<String>("");
  const [selectedYearFirstDay, setSelectedYearFirstDay] = React.useState<String>("");
  const [selectedYeaLastDay, setSelectedYeaLastDay] = React.useState<String>("");

  // VesselList取得APIのURL
  const [base_url, setBase_url] = React.useState(`${process.env.REACT_APP_API_URL}/EuEtsFuelEu/List/`);

  let initFlg = true;

  // 初回のみ実行する処理
  React.useEffect(() => {

    // Yearプルダウンに表示するmap生成--------------
    let dateObj = new Date();
    let tmp_todayYear = dateObj.getFullYear();
    let yearList = ["2024"];

    setTodayYear(String(tmp_todayYear))

    // 今年の最初と最後の年月日時分秒を取得---------------------------------
    let tmpFirstDay = new Date(Date.UTC(tmp_todayYear, 0, 1, 0, 0, 0)).toISOString().replace(".000", ""); 
    let tmpLastDay = new Date(Date.UTC(tmp_todayYear, 11, 31, 23, 59, 59)).toISOString().replace(".000", "");
    setTodayYearFirstDay(tmpFirstDay);
    setTodayYeaLastDay(tmpLastDay);
    setSelectedYearFirstDay(tmpLastDay);
    setSelectedYeaLastDay(tmpLastDay);

    // 画面初期表示用にセット
    setSelectedYear(String(tmp_todayYear));

    // Yearが2024になるまで、Mapに年を追加（2024年が最古）
    while (2024 != tmp_todayYear) {
      yearList.unshift(String(tmp_todayYear))
      tmp_todayYear = tmp_todayYear - 1;
    }
    setYearMap(yearList);

    // API呼び出し-----------------------------------
    let payloadUserId = ""
    let payloadJwt = ""
    let payloadRole = ""
    const pay_json = localStorage.getItem("payload")
    if (pay_json !== null) {
      const pay = JSON.parse(pay_json)
      payloadUserId = pay["user_id"];
      payloadJwt = pay["jwt"];
      payloadRole = pay["role"];
      setUserId(payloadUserId);
      setJwt(payloadJwt);
      setRole(pay["role"]);
    }

    if (initFlg) {
    
      let url = "";
      
      // gidが空ではない場合（他画面からの遷移を想定）
      if (props.SeachInfo.gid !== "") {

        let tmp_gid = "";

        // gidがFavariteの場合の考慮（この画面のGroupにはFavoriteは表示しないため）
        if (props.SeachInfo.gid === "Favorite") {
          tmp_gid = "admin";
        }
        else {
          tmp_gid = props.SeachInfo.gid;
        }

        url = `${base_url}%23?&user_id=${payloadUserId}&group=${tmp_gid}&year=${String(yearList[0])}`;
      }
      // 上記以外の場合（ブラウザ更新による初期表示を想定）
      else {
        url = `${base_url}%23?&user_id=${payloadUserId}&init=true&year=${String(yearList[0])}`;
      }
    
      getEuVesselList(url, payloadJwt, payloadRole);
      initFlg = false;
    }

    // テスト用のデータ設定
    // setRows(test_datas.rows);
    // setRowsDefoult(test_datas.rows);
    // setEuaUnitPrice(test_datas.eua_price);
    // setTotalVessels(test_datas.total_vessels);
    // setTotalList(test_datas.total_list); 

  }, []);

  // Loading--------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(true);

  // EmissionBoardに遷移--------------------------------------------------------------------
  const handleClickEuEmissionBoard = (index, imoNo, from, to, unit, vesselName) => {
    props.setSeachInfo({ imoNo: imoNo, from: from, to: to, gid: props.SeachInfo.gid, gidList: props.SeachInfo.gidList, imoList: props.SeachInfo.imoList })
    console.log("props.SeachInfo:" + from);
    props.setUnit(unit);
    props.setActiveTab(1);
    props.setOpenDrwer(true);
    props.setTabMarginSize(0.025);
    props.setTabSize(2.9875);
    props.setVesselName(vesselName);
  };

  // Simulationに遷移--------------------------------------------------------------------
  const handleClickEuSimulation = (index, imoNo, from, to, vesselName) => {
    props.setSeachInfo({ imoNo: imoNo, from: from, to: to, gid: props.SeachInfo.gid, gidList: props.SeachInfo.gidList, imoList: props.SeachInfo.imoList })
    console.log("props.SeachInfo:" + from);
    props.setActiveTab(2);
    props.setOpenDrwer(true);
    props.setTabMarginSize(0.025);
    props.setTabSize(2.9875);
    props.setVesselName(vesselName);
  };

  // 取得データ
  const [rows, setRows] = React.useState<any>([])
  // 取得データ（元データ保持用）
  const [rowsDefoult, setRowsDefoult] = React.useState<any>([])
  // その他取得データ
  const [totalVessels, setTotalVessels] = React.useState<any>()
  const [totalList, setTotalList] = React.useState<any>()

  // Group--------------------------------------------------------------------
  const selectGroup = (event: SelectChangeEvent) => {

    // ALLの場合、adminに書き換え
    let groupId = event.target.value;
    if (groupId === "ALL") {
      groupId = "admin";
    }

    props.setSeachInfo({ 
      imoNo: `${props.SeachInfo.imoNo}`, from: props.SeachInfo.from, to: props.SeachInfo.to, 
      gid: `${groupId as string}`, gidList: props.SeachInfo.gidList, 
      imoList: props.SeachInfo.imoList })
    onClickGroup()
  };
  const selectGroupAction = (gid) => {
    setLoading(true);
    setSuccess(false);
    setTotalList(undefined);
    const url = `${base_url}%23?&user_id=${userId}&group=${gid}&year=${selectedYear}`
    getEuVesselList(url, jwt, role);
  }

  const [changeClickGroup, setChangeClickGroup] = React.useState<number>(0);

  const onClickGroup = () => {
    setChangeClickGroup((prev) => (prev + 1));
  }
  React.useEffect(() => {
    if (changeClickGroup > 0) {
      selectGroupAction(props.SeachInfo.gid)
    }
  }, [changeClickGroup])

  // Year--------------------------------------------------------------------
  const [selectedYear, setSelectedYear] = React.useState<string>("");
  const selectYear = (event: SelectChangeEvent) => {
    setSelectedYear(event.target.value)

    // SelectedYearをセット（過去分表示時のEmissionBoard表示用）
    let tmpFirstDay = new Date(Date.UTC(Number(event.target.value), 0, 1, 0, 0, 0)).toISOString().replace(".000", ""); 
    let tmpLastDay = new Date(Date.UTC(Number(event.target.value), 11, 31, 23, 59, 59)).toISOString().replace(".000", "");
    setSelectedYearFirstDay(tmpFirstDay);
    setSelectedYeaLastDay(tmpLastDay);

    onClickYear()
  };
  const selectYearAction = (year) => {
    setLoading(true);
    setSuccess(false);
    setTotalList(undefined);
    const url = `${base_url}%23?&user_id=${userId}&group=${props.SeachInfo.gid}&year=${year}`
    getEuVesselList(url, jwt, role)
  }

  const [changeClickYear, setChangeClickYear] = React.useState<number>(0);

  const onClickYear = () => {
    setChangeClickYear((prev) => (prev + 1));
  }
  React.useEffect(() => {
    if (changeClickYear > 0) {
      selectYearAction(selectedYear)
    }
  }, [changeClickYear])

  // EuVesselList取得--------------------------------------------------------------------
  const getEuVesselList = (url, jwt, role) => {
    console.log("url:", url);
    console.log("role:", role);
    
    // Loading表示
    setLoading(true);
    setSuccess(false);
    
    const options = {
      headers: {
        'Authorization': jwt,
      }
    }
    axios.get(url, options).then((res) => {
      console.log("axios[EuVesselList]", res)

      props.setSeachInfo({ imoNo: `${props.SeachInfo.imoNo}`, from: props.SeachInfo.from, to: props.SeachInfo.to, gid: res.data.gid, gidList: res.data.gidList, imoList: res.data.imoList })

      if (role !== undefined) {
        localStorage.clear()
        let Payload: payload
        Payload = {
          role: role,
          user_id: res.data.user_id,
          group_id: res.data.group_id,
          company_id: res.data.company_id,
          gid: res.data.gid,
          gidList: res.data.gidList,
          imoList: res.data.imoList,
          jwt: jwt
        }
        localStorage.setItem("payload", JSON.stringify(Payload))
      }

      console.log("res.data.total_list:" + res.data.total_list.eoy_total_cb)
      // リストのデータをセット 
      setRows(res.data.rows)
      // 元データ保持用のオブジェクトにもセット
      setRowsDefoult(res.data.rows)
      // EUA単価
      setEuaUnitPrice(res.data.eua_price);
      // 船数
      setTotalVessels(res.data.total_vessels);
      // 合計値
      setTotalList(res.data.total_list); 

      // Loading解除
      setLoading(false)
      setSuccess(true)
    })
      .catch((e: AxiosError<ErrorData>) => {
        setLoading(false)
        setSuccess(true)
        if (e.response === undefined) {
          console.log("undefinedError: " + e);
          return;
        }
        console.log(e + ", message: " + e.response.data.message);
      });
  }

  // ソート機能--------------------------------------------------------------------
  type ActiveSortNo = {
    item: string;
    seq: number;
    sort: string;
  }

  const [activeSortNo, setActiveSortNo] = React.useState<ActiveSortNo>({ item: '', seq: 0, sort: '' }); // seq: 文字列→1, 数値→2
  const sortAsc = (item: string, seq: number) => { //昇順に並び替え
    const newListData = [...rows];
    if (seq === 1) { //文字列
      newListData.sort((a, b) => {
        if (a[item] > b[item]) {
          return 1;
        } else {
          return -1;
        }
      })
    } else { //数値
      newListData.sort((a, b) => {
        return a[item] - b[item];
      })
    }


    if (activeSortNo.item === item && activeSortNo.sort === 'asc') { //ソート解除
      setActiveSortNo({ item: '', seq: 0, sort: 'asc' });
    } else {
      setActiveSortNo({ item, seq, sort: 'asc' });
    }
    setRows(newListData);
  }

  const sortDesc = (item: string, seq: number) => { //降順に並び替え
    const newListData = [...rows];
    if (activeSortNo.item === item && activeSortNo.sort === 'desc') { //ソート解除
      if (seq === 1) { //文字列
        newListData.sort((a, b) => {
          if (a[item] > b[item]) {
            return 1;
          } else {
            return -1;
          }
        })
      } else { //数値
        newListData.sort((a, b) => {
          return a[item] - b[item];
        })
      }
      setActiveSortNo({ item: '', seq: 0, sort: 'desc' });
    } else {
      if (seq === 1) { //文字列
        newListData.sort((a, b) => {
          if (a[item] < b[item]) {
            return 1;
          } else {
            return -1;
          }
        })
      } else { //数値
        newListData.sort((a, b) => {
          return b[item] - a[item];
        })
      }
      setActiveSortNo({ item, seq, sort: 'desc' });
    }
    setRows(newListData);
  }

  // VesselListのフィルタ処理
  const handleFilterVesselList = (value) => {
 
    // 予備品名フィルタ値
    let filterValVesselName = String(value).toUpperCase();

    // 一覧（元データ）を取得
    let tableData = rowsDefoult.concat();

    // フィルタに入力ありの場合は処理
    let filterTableData = tableData;
    if (filterValVesselName !== "") {
      // フィルタ処理
      filterTableData = [];
      for (const rowData of tableData) {
        // フィルタ対象のテーブル項目値を取得
        const colDataPartsName = String(rowData.vessel_name);

        // フィルタ入力値がVesselNameに一致するかチェック
        let pushFlgVesselName = false;

        if (filterValVesselName === "") {
          pushFlgVesselName = true;
        } else if (colDataPartsName.indexOf(filterValVesselName) > -1) {
          pushFlgVesselName = true;
        }

        // 一致があった場合は追加
        if (pushFlgVesselName) {
          filterTableData.push(rowData);
        }
      }
    }
    // フィルタした一覧を画面表示
    setRows(filterTableData);
  }

  // EUA単価変更
  const[euaUnitPrice, setEuaUnitPrice] = React.useState("")
  const[euaUnitPriceError, setEuaUnitPriceError] = React.useState("")
  const inputEuaUnitPriceRef = useRef<HTMLInputElement | null>(null);
  const handleChangeEuaUnitPrice = (value) => {
    
    // 整数チェック
    if (value === "" || /^[0-9]+$/.test(value)) {
      setEuaUnitPriceError("");
      setEuaUnitPrice(value);
    }
  }
  const handleBlurEuaUnitPrice = () => {
    if (euaUnitPrice === "") {
      setEuaUnitPriceError("Please enter EUA unit price.");
      // フォーカスを戻す
      if (inputEuaUnitPriceRef.current) {
        inputEuaUnitPriceRef.current.focus();
      }
    }
    else {

      // インプット作成
        const value = {  
          "userid": userId,
          "price": euaUnitPrice,
        }

      // EUA単価更新API呼び出し
      registEuaPrice(value)

      // 表示データ取得API呼び出し
      setLoading(true);
      setSuccess(false);
      const url = `${base_url}%23?&user_id=${userId}&group=${props.SeachInfo.gid}&year=${selectedYear}`
      getEuVesselList(url, jwt, role)
    }
  };

  // EUA単価登録用URL
  const [eua_price_url, set_eua_price_url] = React.useState(`${process.env.REACT_APP_API_URL}/EuEtsFuelEu/List/EuaPrice/%23/`);

  // EUA単価登録
  const registEuaPrice = (value) => {
    console.log("registEuaPrice")

    if (jwt !== undefined) {
      console.log("registEuaPriceスタート", value)
      const formData = new FormData();
      Object.keys(value).forEach(key => {
        formData.append(key, value[key]);
      });
      const options = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': jwt
        }
      }
      const url = `${eua_price_url}`
      console.log("url[registEuaPrice]:", url);
      axios.post(url, formData, options).then((res) => {
        console.log("API Response", res.data);

        // 認可チェック
        if (res.data === 401) {
          props.setAuthCheck(true)
          props.setAuthMessage("不正な操作が行われた可能性があります。")
        } else if (res.data === 500) {
          props.setAuthCheck(true)
          props.setAuthMessage("JWTが改ざんされている可能性があります。")
        } else {
          props.setAuthCheck(false)
        }
      })
        .catch((e: AxiosError<ErrorData>) => {
          if (e.response === undefined) {
            console.log("undefinedError: " + e);
            return;
          }
          console.log(e + ", message: " + e.response.data.message);
        });
    }
  }

  // テスト用データ
  let test_datas = {
    "user_id": "rhaga", 
    "group_id": "admin", 
    "company_id": "BEMAC", 
    "gid": "admin", 
    "gidList": ["admin", "favorite"],
    "eua_price": "75", 
    "imoList": [],
    "total_vessels": "1000",
    "total_list": {
        "ytd_total_eua": 1000,
        "ytd_total_eua_cost": 7500,
        "ytd_total_cb": -2000,
        "ytd_total_cb_cost": 3000,
        "eoy_total_eua": 1010,
        "eoy_total_eua_cost": 7501,
        "eoy_total_cb": -3010,
        "eoy_total_cb_cost": 3001 
    },
    "rows": [
      {
        "imo": "9478676",
        "vessel_name": "TAKAMATSU MARU",
        "operator":"NYK",
        "ytd_distance": "10000",
        "ytd_foc": "20000",
        "ytd_eua": "100",
        "ytd_eua_cost": "7000",
        "ytd_cb": "-100",
        "ytd_cb_cost": "50",
        "eoy_distance": "20001",
        "eoy_foc": "30001",
        "eoy_eua": "200",
        "eoy_eua_cost": "200",
        "eoy_cb": "-101",
        "eoy_cb_cost": "60"
      },
      {
        "imo": "9000002",
        "vessel_name": "TEST VESSEL 02",
        "operator":"NYK",
        "ytd_distance": "10000",
        "ytd_foc": "20000",
        "ytd_eua": "100",
        "ytd_eua_cost": "7000",
        "ytd_cb": "-100",
        "ytd_cb_cost": "50",
        "eoy_distance": "20001",
        "eoy_foc": "30001",
        "eoy_eua": "200",
        "eoy_eua_cost": "200",
        "eoy_cb": "-101",
        "eoy_cb_cost": "60"
      },
      {
        "imo": "9000003",
        "vessel_name": "TEST VESSEL 03",
        "operator":"NYK",
        "ytd_distance": "10000",
        "ytd_foc": "20000",
        "ytd_eua": "100",
        "ytd_eua_cost": "7000",
        "ytd_cb": "-100",
        "ytd_cb_cost": "50",
        "eoy_distance": "20001",
        "eoy_foc": "30001",
        "eoy_eua": "200",
        "eoy_eua_cost": "200",
        "eoy_cb": "-101",
        "eoy_cb_cost": "60"
      },
      {
        "imo": "9000004",
        "vessel_name": "TEST VESSEL 04",
        "operator":"NYK",
        "ytd_distance": "10000",
        "ytd_foc": "20000",
        "ytd_eua": "100",
        "ytd_eua_cost": "7000",
        "ytd_cb": "-100",
        "ytd_cb_cost": "50",
        "eoy_distance": "20001",
        "eoy_foc": "30001",
        "eoy_eua": "200",
        "eoy_eua_cost": "200",
        "eoy_cb": "-101",
        "eoy_cb_cost": "60"
      },
      {
        "imo": "9000005",
        "vessel_name": "TEST VESSEL 05",
        "operator":"NYK",
        "ytd_distance": "10000",
        "ytd_foc": "20000",
        "ytd_eua": "100",
        "ytd_eua_cost": "7000",
        "ytd_cb": "-100",
        "ytd_cb_cost": "50",
        "eoy_distance": "20001",
        "eoy_foc": "30001",
        "eoy_eua": "200",
        "eoy_eua_cost": "200",
        "eoy_cb": "-101",
        "eoy_cb_cost": "60"
      },
      {
        "imo": "9000006",
        "vessel_name": "TEST VESSEL 06",
        "operator":"NYK",
        "ytd_distance": "10000",
        "ytd_foc": "20000",
        "ytd_eua": "100",
        "ytd_eua_cost": "7000",
        "ytd_cb": "-100",
        "ytd_cb_cost": "50",
        "eoy_distance": "20001",
        "eoy_foc": "30001",
        "eoy_eua": "200",
        "eoy_eua_cost": "200",
        "eoy_cb": "-101",
        "eoy_cb_cost": "60"
      },
      {
        "imo": "9000007",
        "vessel_name": "TEST VESSEL 07",
        "operator":"NYK",
        "ytd_distance": "10000",
        "ytd_foc": "20000",
        "ytd_eua": "100",
        "ytd_eua_cost": "7000",
        "ytd_cb": "-100",
        "ytd_cb_cost": "50",
        "eoy_distance": "20001",
        "eoy_foc": "30001",
        "eoy_eua": "200",
        "eoy_eua_cost": "200",
        "eoy_cb": "-101",
        "eoy_cb_cost": "60"
      },
      {
        "imo": "9000008",
        "vessel_name": "TEST VESSEL 08",
        "operator":"NYK",
        "ytd_distance": "10000",
        "ytd_foc": "20000",
        "ytd_eua": "100",
        "ytd_eua_cost": "7000",
        "ytd_cb": "-100",
        "ytd_cb_cost": "50",
        "eoy_distance": "20001",
        "eoy_foc": "30001",
        "eoy_eua": "200",
        "eoy_eua_cost": "200",
        "eoy_cb": "-101",
        "eoy_cb_cost": "60"
      },
      {
        "imo": "9000009",
        "vessel_name": "TEST VESSEL 09",
        "operator":"NYK",
        "ytd_distance": "10000",
        "ytd_foc": "20000",
        "ytd_eua": "100",
        "ytd_eua_cost": "7000",
        "ytd_cb": "-100",
        "ytd_cb_cost": "50",
        "eoy_distance": "20001",
        "eoy_foc": "30001",
        "eoy_eua": "200",
        "eoy_eua_cost": "200",
        "eoy_cb": "-101",
        "eoy_cb_cost": "60"
      },
    ]
  }

  return (
    <>
      <Box sx={{width: "97.5%", height: "98.75%", marginLeft: "1.2%"}}>
        {/* 条件プルダウン */}
        <Box sx={{ height: "82px"}}>
          <Box sx={{height: "32px"}}></Box>
          <Stack direction="row" sx={{ height: "100%", }} >
            
            {/* Group */}
            <Typography align='left' sx={{ marginTop: "7px", marginRight: "10px", color: 'white', fontSize: 14, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
              Group
            </Typography>
            <ThemeProvider theme={SelectTheme}>
              <FormControl>
                <Select
                  IconComponent={() =>
                    <div style={{ position: "absolute", top: "15px", left: "105px" }}>
                      <InputAdornment position="start" disablePointerEvents>
                        <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(135deg)" }}></Box>
                      </InputAdornment>
                    </div>
                  }
                  MenuProps={{
                    MenuListProps: {
                      disablePadding: true,

                    },
                    style: { zIndex: 100000, }
                  }}
                  sx={{
                    width: 128,
                    height: 32,
                    color: "white",
                    borderRadius: 30,
                    ":hover": { backgroundColor: "#7a86a0" }
                  }}
                  input={<BootstrapInput2 />}
                  onChange={selectGroup}
                  value={props.SeachInfo.gid === "admin" ? "ALL": props.SeachInfo.gid}
                >
                  {props.SeachInfo.gidList.map((gid) => {
                    return (
                      <MenuItem key={gid === "admin" ? "ALL": gid} value={gid === "admin" ? "ALL": gid}>{gid === "admin" ? "ALL": gid}</MenuItem>
                    )
                  }
                  )}
                </Select>
              </FormControl>
            </ThemeProvider>
            <Stack sx={{ width: "56px" }}></Stack>

            {/* Year */}
            <Typography align='left' sx={{ marginTop: "7px", marginRight: "10px", color: 'white', fontSize: 14, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
              Year
            </Typography>
            <ThemeProvider theme={SelectTheme}>
              <FormControl>
                <Select
                  IconComponent={() =>
                    <div style={{ position: "absolute", top: "15px", left: "105px" }}>
                      <InputAdornment position="start" disablePointerEvents>
                        <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(135deg)" }}></Box>
                      </InputAdornment>
                    </div>
                  }
                  MenuProps={{
                    MenuListProps: {
                      disablePadding: true,

                    },
                    style: { zIndex: 100000, }
                  }}
                  sx={{
                    width: 128,
                    height: 32,
                    color: "white",
                    borderRadius: 30,
                    ":hover": { backgroundColor: "#7a86a0" }
                  }}
                  input={<BootstrapInput2 />}
                  onChange={selectYear}
                  value={selectedYear}
                >
                  {yearMap.map((year) => {
                    return (
                      <MenuItem key={year} value={year}>{year}</MenuItem>
                    )
                  }
                  )}
                </Select>
              </FormControl>
            </ThemeProvider>
            <Stack sx={{ width: "56px" }}></Stack>

            {/* EUA単価 */}
            <Typography align='left' sx={{ marginTop: "7px", marginRight: "10px", color: 'white', fontSize: 14, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
              1EUA ＝
            </Typography>
            <Box sx={{ display: "flex", position: "relative" }}>
              <FormControl>
                <Input type="tel"
                  value={euaUnitPrice} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeEuaUnitPrice(event.target.value) }}
                  onBlur={handleBlurEuaUnitPrice} autoComplete="off" inputRef={inputEuaUnitPriceRef}
                  sx={{
                    marginLeft: "-8px",
                    fontFamily: 'Roboto', fontWeight: '500', border: '0px grey solid', color: "white", fontSize: "15px", 
                    paddingRight:"10px",background: "rgba(255, 255, 255, 0.15)",
                    "& .MuiInput-input": { height: "23px", width: "55px", borderRadius: '0px', border: '0px white solid', textAlign: "center", },
                    ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                    ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                    ":after": { borderBottom: "2px solid white", },
                    ":before": { borderBottom: "2px solid #A4A7B1", }, 
                  }}/>
              </FormControl>
              <Typography sx={{ position: "absolute", left: "45px", marginTop: "6px", color: 'rgba(255, 255, 255, 0.5)', 
                fontSize: 14, fontFamily: 'Roboto Condensed', }}>
                €
              </Typography>
              <Typography sx={{ width: "200px", position: "absolute", left: "-7px", top: "30px", color: 'red', fontSize: 14, fontFamily: 'Roboto Condensed', }}>
                {euaUnitPriceError !== "" ? euaUnitPriceError : ""}
              </Typography>
            </Box>
          </Stack>
        </Box>

        {/* Total */}
        <Stack direction="row" sx={{ height: "215px"}}>

          {/* EU-ETS Total */}
          <Box className="total-area" sx={{ width: "47%"}}>
            <Stack direction="row" sx={{ height: "50px" }}>
              <Typography sx={{ width: "70px", marginTop: "20px", marginLeft: "24px", color: 'white', fontSize: 15, fontFamily: 'Roboto', fontWeight: 'bold' }}>
                EU-ETS
              </Typography>
              <Typography sx={{ width: "97px", marginTop: "20px", color: 'rgb(255, 255, 255, 0.5)', fontSize: 14, fontFamily: 'Roboto', fontWeight: 'bold' }}>
                Total Vessels :
              </Typography>
              <Typography sx={{ width: "120px", marginTop: "20px", color: 'white', fontSize: 14, fontFamily: 'Roboto', }}>
                {totalVessels !== undefined && totalVessels !== "" ? formatNumber(totalVessels) : "-"}
              </Typography>
            </Stack>
            <Stack direction="row" sx={{height: "140px", width: "100%"}}>
              <Box sx={{width: "48%"}}>
                <Stack className='total-area-section' >
                  Year to Date
                </Stack>
                <Stack direction="row" sx={{width: "100%"}}>
                  <Box className="total-data-titole1">
                    Total EUAs
                  </Box>
                  <Box className="total-data-titole2">
                    EUA Cost
                  </Box>
                </Stack>
                <Stack direction="row" sx={{width: "100%"}}>
                  <Box className="total-data-value">
                    {selectedYear === todayYear ? 
                      totalList !== undefined && totalList.eoy_total_eua !== "" ? 
                        formatNumber(totalList.ytd_total_eua) 
                        : "-" 
                      : "-"
                    }
                  </Box>
                  <Box className="total-data-cost">
                    <span>
                      {selectedYear === todayYear ? 
                        totalList !== undefined && totalList.ytd_total_eua_cost !== "" ? 
                          formatNumber(totalList.ytd_total_eua_cost) 
                          : "-" 
                        : "-"
                      }
                    </span>
                    <span className='unit-eur'>&nbsp;€</span>
                  </Box>
                </Stack>
              </Box>
              <Box sx={{width: "4%"}}>
                <Stack className='separate-bar' alignItems={"center"} justifyContent={"center"}>
                  <Box className="bar"></Box>
                </Stack>
              </Box>
              <Box sx={{width: "48%"}}>
                <Stack className='total-area-section-right'>
                  {selectedYear === todayYear ? "End of Year ( Estimated )" : "End of Year"}
                </Stack>
                <Stack direction="row" sx={{width: "100%"}}>
                  <Box className="total-data-titole1-right">
                    Total EUAs
                  </Box>
                  <Box className="total-data-titole2-right">
                    EUA Cost
                  </Box>
                </Stack>
                <Stack direction="row" sx={{width: "100%"}}>
                  <Box className="total-data-value-right">
                    {totalList !== undefined && totalList.eoy_total_eua !== "" ? formatNumber(totalList.eoy_total_eua) : "-"}
                  </Box>
                  <Box className="total-data-cost-right">
                    <span>{totalList !== undefined && totalList.eoy_total_eua_cost !== "" ? formatNumber(totalList.eoy_total_eua_cost) : "-"}</span>
                    <span className='unit-eur'>&nbsp;€</span>
                  </Box>
                </Stack>
              </Box>
            </Stack>
          </Box>
          <Box sx={{ height: "197px", width: "2%"}}></Box>

          {/* FuelEU Maritime Total */}
          <Box className="total-area" sx={{ width: "51%"}}>
            <Stack direction="row" sx={{ height: "50px" }}>
              <Stack sx={{ width: "130px", marginTop: "20px", marginLeft: "24px", color: 'white', fontSize: 15, fontFamily: 'Roboto', fontWeight: 'bold' }}>
                FuelEU Maritime
              </Stack>
              <Typography sx={{ width: "97px", marginTop: "20px", color: 'rgb(255, 255, 255, 0.5)', fontSize: 14, fontFamily: 'Roboto', fontWeight: 'bold' }}>
                Total Vessels :
              </Typography>
              <Typography sx={{ width: "120px", marginTop: "20px", color: 'white', fontSize: 14, fontFamily: 'Roboto', }}>
                {totalVessels !== undefined && totalVessels !== "" ? formatNumber(totalVessels) : "-"}
              </Typography>
            </Stack>
            <Stack direction="row" sx={{height: "140px", width: "100%"}}>
              <Box sx={{width: "48%"}}>
                <Stack className='total-area-section'>
                  Year to Date
                </Stack>
                <Stack direction="row" sx={{width: "100%"}}>
                  <Box className="total-data-titole-cb">
                    Total Compliance Balance (ton)
                  </Box>
                  <Box className="total-data-titole-cb-cost">
                    Compliance Balance Cost
                  </Box>
                </Stack>
                <Stack direction="row" sx={{width: "100%"}}>
                  <Box className="total-data-value-cb">
                    {selectedYear === todayYear ? 
                      totalList !== undefined && totalList.ytd_total_cb !== "" ? 
                        formatNumber(totalList.ytd_total_cb) 
                        : "-" 
                      : "-"
                    }
                  </Box>
                  <Box className="total-data-cost-cb">
                    <span>
                      {selectedYear === todayYear ? 
                        totalList !== undefined && totalList.ytd_total_cb_cost !== "" ? 
                          formatNumber(totalList.ytd_total_cb_cost) 
                          : "-" 
                        : "-"
                      }
                    </span>
                    <span className='unit-eur'>&nbsp;€</span>
                  </Box>
                </Stack>
              </Box>
              <Box sx={{width: "4%"}}>
                <Stack className='separate-bar' alignItems={"center"} justifyContent={"center"}>
                  <Box className="bar"></Box>
                </Stack>
              </Box>
              <Box sx={{width: "48%"}}>
                <Stack className='total-area-section-right-cb'>
                  {selectedYear === todayYear ? "End of Year ( Estimated )" : "End of Year"}
                </Stack>
                <Stack direction="row" sx={{width: "100%"}}>
                  <Box className="total-data-titole-cb-right">
                    Total Compliance Balance (ton)
                  </Box>
                  <Box className="total-data-titole-cb-cost-right">
                    Compliance Balance Cost
                  </Box>
                </Stack>
                <Stack direction="row" sx={{width: "100%"}}>
                  <Box className="total-data-value-cb-right">
                    {totalList !== undefined && totalList.eoy_total_cb !== "" ? formatNumber(totalList.eoy_total_cb) : "-"}
                  </Box>
                  <Box className="total-data-cost-cb-right">
                    <span>
                      {totalList !== undefined && totalList.eoy_total_cb_cost !== "" ? formatNumber(totalList.eoy_total_cb_cost) : "-"}
                    </span>
                    <span className='unit-eur'>&nbsp;€</span>
                  </Box>
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Stack>
        
        {/* List */}
        <Box className="list-area">
          {/* VesselName絞り込みテキストボックス */}
          <Box sx={{ position: "relative" }}>
            <Stack direction="row" sx={{ height: "60px", marginLeft: "2.6%", position: "relative"}} alignItems={"center"} justifyContent={"flex-start"}>
              <SearchIcon sx={{ marginRight: "6px", width: "20px", height: "20px", color: "white", 
                  position:"absolute", top: "32px", left:"5px", zIndex:10000
                }} />
              <FormControl>
                <Input id="vesselNameFilter" type="email"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleFilterVesselList(event.target.value) }}
                  placeholder="Vessel Name" autoComplete="off"
                  sx={{  marginTop: "25px",
                    fontFamily: 'Roboto', fontWeight: '500', border: '0px grey solid', color: "white", fontSize: "15px", 
                    paddingLeft:"30px",background: "rgba(255, 255, 255, 0.15)",
                    "& .MuiInput-input": { height: "23px", width: "122px", borderRadius: '0px', border: '0px white solid', textAlign: "left", },
                    ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                    ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                    ":after": { borderBottom: "2px solid white", },
                    ":before": { borderBottom: "2px solid #A4A7B1", }, fontStyle:"italic"
                  }}/>
              </FormControl>
            </Stack>
            {/* Year to Date */}
            <Box style={{ width: "38.25%", height: "25px", left: "19.25%", top: "30px", position: 'absolute', background: 'rgba(83, 84, 89, 0.50)', }}>
              <Typography align="center" sx={{ marginTop: "2px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                Year to Date
              </Typography>
            </Box>
            {/* End of Year */}
            <Box style={{ width: "38.25%", height: "25px", left: "59.75%", top: "30px", position: 'absolute', background: 'rgba(83, 84, 89, 0.50)', }}>
              <Typography align="center" sx={{ marginTop: "2px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                {selectedYear === todayYear ? "End of Year ( Estimated )" : "End of Year"}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ height: "20px", position: 'relative',}}>
            {/* YeatToDate EU-MRV */}
            <Stack direction="row" sx={{ width: "12%", position: "absolute", left: "19%"}}>
              <Stack className='horizontal-bar-MRV'>
                <Box className="bar"></Box>
              </Stack>
              <Box className='sub-header' sx={{width: "30%", marginTop: "5px", textAlign: "center"}}>
                EU-MRV
              </Box>
              <Stack className='horizontal-bar-MRV'>
                <Box className="bar"></Box>
              </Stack>
            </Stack>
            {/* YeatToDate EU-ETS */}
            <Stack direction="row" sx={{ width: "11%", position: "absolute", left: "33.5%"}}>
              <Stack className='horizontal-bar-EUA'>
                <Box className="bar"></Box>
              </Stack>
              <Box className='sub-header' sx={{width: "30%", marginTop: "5px", textAlign: "center"}}>
                EU-ETS
              </Box>
              <Stack className='horizontal-bar-EUA'>
                <Box className="bar"></Box>
              </Stack>
            </Stack>
            {/* YeatToDate FuelEU Maritime */}
            <Stack direction="row" sx={{ width: "10.25%", position: "absolute", left: "47%"}}>
              <Stack className='horizontal-bar-FuelEU'>
                <Box className="bar"></Box>
              </Stack>
              <Box className='sub-header' sx={{width: "70%", marginTop: "5px", textAlign: "center"}}>
                FuelEU Maritime
              </Box>
              <Stack className='horizontal-bar-FuelEU'>
                <Box className="bar"></Box>
              </Stack>
            </Stack>
            {/* End of Year EU-MRV */}
            <Stack direction="row" sx={{ width: "12%", position: "absolute", left: "59.75%"}}>
              <Stack className='horizontal-bar-MRV'>
                <Box className="bar"></Box>
              </Stack>
              <Box className='sub-header' sx={{width: "30%", marginTop: "5px", textAlign: "center"}}>
                EU-MRV
              </Box>
              <Stack className='horizontal-bar-MRV'>
                <Box className="bar"></Box>
              </Stack>
            </Stack>
            {/* EndOfYear EU-ETS */}
            <Stack direction="row" sx={{ width: "11%", position: "absolute", left: "74%"}}>
              <Stack className='horizontal-bar-EUA'>
                <Box className="bar"></Box>
              </Stack>
              <Box className='sub-header' sx={{width: "30%", marginTop: "5px", textAlign: "center"}}>
                EU-ETS
              </Box>
              <Stack className='horizontal-bar-EUA'>
                <Box className="bar"></Box>
              </Stack>
            </Stack>
            {/* EndOfYear FuelEU Maritime */}
            <Stack direction="row" sx={{ width: "10%", position: "absolute", left: "87.5%"}}>
              <Stack className='horizontal-bar-FuelEU'>
                <Box className="bar"></Box>
              </Stack>
              <Box className='sub-header' sx={{width: "70%", marginTop: "5px", textAlign: "center"}}>
                FuelEU Maritime
              </Box>
              <Stack className='horizontal-bar-FuelEU'>
                <Box className="bar"></Box>
              </Stack>
            </Stack>
          </Box>
          <div className='eua-cb-list-area-wrapper'>
            <table className="eua-cb-vessel-list-table">
              <thead>
                <tr>
                  <th className="padding1">
                    <span></span>
                  </th>
                  <th className="vesselName">
                    <div className='eu-list-header-left'>
                      <div>
                        <span>Vessel Name</span>
                      </div>
                      <div className="eu-list-common-sort-container">
                        <div className="eu-list-common-sort-icon">
                          <div className={(activeSortNo.item === "vessel_name" && activeSortNo.sort === "asc") ? "eu-list-sort-active-ascending" : "eu-list-common-sort-icon-ascending"} onClick={() => sortAsc('vessel_name', 1)}></div>
                          <div className={(activeSortNo.item === "vessel_name" && activeSortNo.sort === "desc") ? "eu-list-sort-active-descending" : "eu-list-common-sort-icon-descending"} onClick={() => sortDesc('vessel_name', 1)}></div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="operator">
                    <div className='eu-list-header-left'>
                      <div>
                        <span>Operator</span>
                      </div>
                      <div className="eu-list-common-sort-container">
                        <div className="eu-list-common-sort-icon">
                          <div className={(activeSortNo.item === "operator" && activeSortNo.sort === "asc") ? "eu-list-sort-active-ascending" : "eu-list-common-sort-icon-ascending"} onClick={() => sortAsc('operator', 1)}></div>
                          <div className={(activeSortNo.item === "operator" && activeSortNo.sort === "desc") ? "eu-list-sort-active-descending" : "eu-list-common-sort-icon-descending"} onClick={() => sortDesc('operator', 1)}></div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="year-to-date-distance">
                    <div className='eu-list-header-right'>
                      <div>
                        <span>Distance (NM)</span>
                      </div>
                      <div className="eu-list-common-sort-container">
                        <div className="eu-list-common-sort-icon">
                          <div className={(activeSortNo.item === "ytd_distance" && activeSortNo.sort === "asc") ? "eu-list-sort-active-ascending" : "eu-list-common-sort-icon-ascending"} onClick={() => sortAsc('ytd_distance', 2)}></div>
                          <div className={(activeSortNo.item === "ytd_distance" && activeSortNo.sort === "desc") ? "eu-list-sort-active-descending" : "eu-list-common-sort-icon-descending"} onClick={() => sortDesc('ytd_distance', 2)}></div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="year-to-date-foc">
                    <div className='eu-list-header-right'>
                      <div>
                        <span>FOC (MT)</span>
                      </div>
                      <div className="eu-list-common-sort-container">
                        <div className="eu-list-common-sort-icon">
                          <div className={(activeSortNo.item === "ytd_foc" && activeSortNo.sort === "asc") ? "eu-list-sort-active-ascending" : "eu-list-common-sort-icon-ascending"} onClick={() => sortAsc('ytd_foc', 2)}></div>
                          <div className={(activeSortNo.item === "ytd_foc" && activeSortNo.sort === "desc") ? "eu-list-sort-active-descending" : "eu-list-common-sort-icon-descending"} onClick={() => sortDesc('ytd_foc', 2)}></div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="padding3">
                    <span></span>
                  </th> 
                  <th className="year-to-date-eua">
                    <div className='eu-list-header-center'>
                      <div>
                        <span>EUA</span>
                      </div>
                      <div className="eu-list-common-sort-container">
                        <div className="eu-list-common-sort-icon">
                          <div className={(activeSortNo.item === "ytd_eua" && activeSortNo.sort === "asc") ? "eu-list-sort-active-ascending" : "eu-list-common-sort-icon-ascending"} onClick={() => sortAsc('ytd_eua', 2)}></div>
                          <div className={(activeSortNo.item === "ytd_eua" && activeSortNo.sort === "desc") ? "eu-list-sort-active-descending" : "eu-list-common-sort-icon-descending"} onClick={() => sortDesc('ytd_eua', 2)}></div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="year-to-date-eua-cost">
                    <div className='eu-list-header-right'>
                      <div>
                        <span>EUA Cost (€)</span>
                      </div>
                      <div className="eu-list-common-sort-container">
                        <div className="eu-list-common-sort-icon">
                          <div className={(activeSortNo.item === "ytd_eua_cost" && activeSortNo.sort === "asc") ? "eu-list-sort-active-ascending" : "eu-list-common-sort-icon-ascending"} onClick={() => sortAsc('ytd_eua_cost', 2)}></div>
                          <div className={(activeSortNo.item === "ytd_eua_cost" && activeSortNo.sort === "desc") ? "eu-list-sort-active-descending" : "eu-list-common-sort-icon-descending"} onClick={() => sortDesc('ytd_eua_cost', 2)}></div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="padding3">
                    <span></span>
                  </th> 
                  <th className="year-to-date-cb">
                    <div className='eu-list-header-center'>
                      <div>
                        <span>CB (ton)</span>
                      </div>
                      <div className="eu-list-common-sort-container">
                        <div className="eu-list-common-sort-icon">
                          <div className={(activeSortNo.item === "ytd_cb" && activeSortNo.sort === "asc") ? "eu-list-sort-active-ascending" : "eu-list-common-sort-icon-ascending"} onClick={() => sortAsc('ytd_cb', 2)}></div>
                          <div className={(activeSortNo.item === "ytd_cb" && activeSortNo.sort === "desc") ? "eu-list-sort-active-descending" : "eu-list-common-sort-icon-descending"} onClick={() => sortDesc('ytd_cb', 2)}></div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="year-to-date-cb-cost">
                    <div className='eu-list-header-right'>
                      <div>
                        <span>CB Cost (€)</span>
                      </div>
                      <div className="eu-list-common-sort-container">
                        <div className="eu-list-common-sort-icon">
                          <div className={(activeSortNo.item === "ytd_cb_cost" && activeSortNo.sort === "asc") ? "eu-list-sort-active-ascending" : "eu-list-common-sort-icon-ascending"} onClick={() => sortAsc('ytd_cb_cost', 2)}></div>
                          <div className={(activeSortNo.item === "ytd_cb_cost" && activeSortNo.sort === "desc") ? "eu-list-sort-active-descending" : "eu-list-common-sort-icon-descending"} onClick={() => sortDesc('ytd_cb_cost', 2)}></div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="padding2">
                    <span></span>
                  </th>
                  <th className="end-of-year-distance">
                    <div className='eu-list-header-right'>
                      <div>
                        <span>Distance (NM)</span>
                      </div>
                      <div className="eu-list-common-sort-container">
                        <div className="eu-list-common-sort-icon">
                          <div className={(activeSortNo.item === "eoy_distance" && activeSortNo.sort === "asc") ? "eu-list-sort-active-ascending" : "eu-list-common-sort-icon-ascending"} onClick={() => sortAsc('eoy_distance', 2)}></div>
                          <div className={(activeSortNo.item === "eoy_distance" && activeSortNo.sort === "desc") ? "eu-list-sort-active-descending" : "eu-list-common-sort-icon-descending"} onClick={() => sortDesc('eoy_distance', 2)}></div>
                        </div>
                      </div>
                    </div>
                  </th>              
                  <th className="end-of-year-foc">
                    <div className='eu-list-header-right'>
                      <div>
                        <span>FOC (MT)</span>
                      </div>
                      <div className="eu-list-common-sort-container">
                        <div className="eu-list-common-sort-icon">
                          <div className={(activeSortNo.item === "eoy_foc" && activeSortNo.sort === "asc") ? "eu-list-sort-active-ascending" : "eu-list-common-sort-icon-ascending"} onClick={() => sortAsc('eoy_foc', 2)}></div>
                          <div className={(activeSortNo.item === "eoy_foc" && activeSortNo.sort === "desc") ? "eu-list-sort-active-descending" : "eu-list-common-sort-icon-descending"} onClick={() => sortDesc('eoy_foc', 2)}></div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="padding3">
                    <span></span>
                  </th> 
                  <th className="end-of-year-eua">
                    <div className='eu-list-header-center'>
                      <div>
                        <span>EUA</span>
                      </div>
                      <div className="eu-list-common-sort-container">
                        <div className="eu-list-common-sort-icon">
                          <div className={(activeSortNo.item === "eoy_eua" && activeSortNo.sort === "asc") ? "eu-list-sort-active-ascending" : "eu-list-common-sort-icon-ascending"} onClick={() => sortAsc('eoy_eua', 2)}></div>
                          <div className={(activeSortNo.item === "eoy_eua" && activeSortNo.sort === "desc") ? "eu-list-sort-active-descending" : "eu-list-common-sort-icon-descending"} onClick={() => sortDesc('eoy_eua', 2)}></div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="end-of-year-eua-cost">
                    <div className='eu-list-header-right'>
                      <div>
                        <span>EUA Cost (€)</span>
                      </div>
                      <div className="eu-list-common-sort-container">
                        <div className="eu-list-common-sort-icon">
                          <div className={(activeSortNo.item === "eoy_eua_cost" && activeSortNo.sort === "asc") ? "eu-list-sort-active-ascending" : "eu-list-common-sort-icon-ascending"} onClick={() => sortAsc('eoy_eua_cost', 2)}></div>
                          <div className={(activeSortNo.item === "eoy_eua_cost" && activeSortNo.sort === "desc") ? "eu-list-sort-active-descending" : "eu-list-common-sort-icon-descending"} onClick={() => sortDesc('eoy_eua_cost', 2)}></div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="padding3">
                    <span></span>
                  </th> 
                  <th className="end-of-year-cb">
                    <div className='eu-list-header-center'>
                      <div>
                        <span>CB (ton)</span>
                      </div>
                      <div className="eu-list-common-sort-container">
                        <div className="eu-list-common-sort-icon">
                          <div className={(activeSortNo.item === "eoy_cb" && activeSortNo.sort === "asc") ? "eu-list-sort-active-ascending" : "eu-list-common-sort-icon-ascending"} onClick={() => sortAsc('eoy_cb', 2)}></div>
                          <div className={(activeSortNo.item === "eoy_cb" && activeSortNo.sort === "desc") ? "eu-list-sort-active-descending" : "eu-list-common-sort-icon-descending"} onClick={() => sortDesc('eoy_cb', 2)}></div>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="end-of-year-cb-cost">
                    <div className='eu-list-header-right'>
                      <div>
                        <span>CB Cost (€)</span>
                      </div>
                      <div className="eu-list-common-sort-container">
                        <div className="eu-list-common-sort-icon">
                          <div className={(activeSortNo.item === "eoy_cb_cost" && activeSortNo.sort === "asc") ? "eu-list-sort-active-ascending" : "eu-list-common-sort-icon-ascending"} onClick={() => sortAsc('eoy_cb_cost', 2)}></div>
                          <div className={(activeSortNo.item === "eoy_cb_cost" && activeSortNo.sort === "desc") ? "eu-list-sort-active-descending" : "eu-list-common-sort-icon-descending"} onClick={() => sortDesc('eoy_cb_cost', 2)}></div>
                        </div>
                      </div>
                    </div>
                  </th>              
                  <th className="padding3">
                    <span></span>
                  </th>              
                </tr>             
              </thead>
              {success ?
                <tbody>
                  {rows.length !== 0 ?
                    <>
                      {
                        rows.map((element, index) => {
                          return (
                            <tr>
                              <td className="padding1">
                                <span></span>
                              </td>
                              <td className="vesselName">
                                <span>{element.vessel_name}</span>
                              </td>
                              <td className="operator">
                                <span>{element.operator}</span>
                              </td>
                              <td className="year-to-date-distance">                               
                                <span>{selectedYear === todayYear ? formatNumber(element.ytd_distance) : "-"}</span>
                              </td>
                              <td className="year-to-date-foc">
                                <span>{selectedYear === todayYear ? formatNumber(element.ytd_foc) : "-"}</span>
                              </td>
                              <td className="padding3">
                                <span></span>
                              </td> 
                              <td className="year-to-date-eua">
                                <Button sx={{ width: "100%", height: "100%", marginLeft: "10px", ":hover": { bgcolor: "rgba(255, 255, 255, 0.5)" } }}
                                  disabled={element.ytd_distance === "" ? true : false}
                                  onClick={() => handleClickEuEmissionBoard(index, element.imo, todayYearFirstDay, todayYeaLastDay, "Leg", element.vessel_name)}>
                                  <span>{selectedYear === todayYear ? formatNumber(element.ytd_eua) : "-"}</span>
                                </Button>
                              </td>
                              <td className="year-to-date-eua-cost">
                                <Button sx={{ width: "100%", height: "100%", marginLeft: "10px", ":hover": { bgcolor: "rgba(255, 255, 255, 0.5)" } }}
                                  disabled={element.ytd_distance === "" ? true : false}
                                  onClick={() => handleClickEuEmissionBoard(index, element.imo, todayYearFirstDay, todayYeaLastDay, "Leg", element.vessel_name)}>
                                  <span>{selectedYear === todayYear ? formatNumber(element.ytd_eua_cost) : "-"}</span>
                                </Button>
                              </td>
                              <td className="padding3">
                                <span></span>
                              </td> 
                              <td className="year-to-date-cb">
                                <Button sx={{ width: "100%", height: "100%", marginLeft: "10px", ":hover": { bgcolor: "rgba(255, 255, 255, 0.5)" } }}
                                  disabled={element.ytd_distance === "" ? true : false}
                                  onClick={() => handleClickEuEmissionBoard(index, element.imo, todayYearFirstDay, todayYeaLastDay, "Leg", element.vessel_name)}>
                                  <span>{selectedYear === todayYear ? formatNumber(element.ytd_cb) : "-"}</span>
                                </Button>
                              </td>
                              <td className="year-to-date-cb-cost">
                                <Button sx={{ width: "100%", height: "100%", marginLeft: "10px", ":hover": { bgcolor: "rgba(255, 255, 255, 0.5)" } }}
                                  disabled={element.ytd_distance === "" ? true : false}
                                  onClick={() => handleClickEuEmissionBoard(index, element.imo, todayYearFirstDay, todayYeaLastDay, "Leg", element.vessel_name)}>
                                  <span>{selectedYear === todayYear ? formatNumber(element.ytd_cb_cost) : "-"}</span>
                                </Button>
                              </td>
                              <td className="padding2">
                                <span></span>
                              </td>
                              <td className="end-of-year-distance">
                                <span>{formatNumber(element.eoy_distance ?? 0)}</span>
                              </td>              
                              <td className="end-of-year-foc">
                                <span>{formatNumber(element.eoy_foc ?? 0)}</span>
                              </td>
                              <td className="padding3">
                                <span></span>
                              </td> 
                              <td className="end-of-year-eua">
                                <Button sx={{ width: "100%", height: "100%", marginLeft: "10px", ":hover": { bgcolor: "rgba(255, 255, 255, 0.5)" } }}
                                  disabled={element.eoy_eua === "" ? true : false}
                                  onClick={() =>
                                    {selectedYear === todayYear ? handleClickEuSimulation(index, element.imo, todayYearFirstDay, todayYeaLastDay, element.vessel_name)  
                                      : handleClickEuEmissionBoard(index, element.imo, selectedYearFirstDay, selectedYeaLastDay, "Leg", element.vessel_name)}}>
                                  <span>{formatNumber(element.eoy_eua ?? 0)}</span>
                                </Button>
                              </td>
                              <td className="end-of-year-eua-cost">
                                <Button sx={{ width: "100%", height: "100%", marginLeft: "10px", ":hover": { bgcolor: "rgba(255, 255, 255, 0.5)" } }}
                                  disabled={element.eoy_eua_cost === "" ? true : false}
                                  onClick={() =>
                                    {selectedYear === todayYear ? handleClickEuSimulation(index, element.imo, todayYearFirstDay, todayYeaLastDay, element.vessel_name)  
                                      : handleClickEuEmissionBoard(index, element.imo, selectedYearFirstDay, selectedYeaLastDay, "Leg", element.vessel_name)}}>
                                  <span>{formatNumber(element.eoy_eua_cost ?? 0)}</span>
                                </Button>
                              </td>
                              <td className="padding3">
                                <span></span>
                              </td> 
                              <td className="end-of-year-cb">
                                <Button sx={{ width: "100%", height: "100%", marginLeft: "10px", ":hover": { bgcolor: "rgba(255, 255, 255, 0.5)" } }}
                                  disabled={element.eoy_cb === "" ? true : false}
                                  onClick={() =>
                                    {selectedYear === todayYear ? handleClickEuSimulation(index, element.imo, todayYearFirstDay, todayYeaLastDay, element.vessel_name)  
                                      : handleClickEuEmissionBoard(index, element.imo, selectedYearFirstDay, selectedYeaLastDay, "Leg", element.vessel_name)}}>
                                  <span>{formatNumber(element.eoy_cb ?? 0)}</span>
                                </Button>
                              </td>
                              <td className="end-of-year-cb-cost">
                                <Button sx={{ width: "100%", height: "100%", marginLeft: "10px", ":hover": { bgcolor: "rgba(255, 255, 255, 0.5)" } }}
                                  disabled={element.eoy_cb_cost === "" ? true : false}
                                  onClick={() => 
                                    {selectedYear === todayYear ? handleClickEuSimulation(index, element.imo, todayYearFirstDay, todayYeaLastDay, element.vessel_name)  
                                      : handleClickEuEmissionBoard(index, element.imo, selectedYearFirstDay, selectedYeaLastDay, "Leg", element.vessel_name)}}>
                                  <span>{formatNumber(element.eoy_cb_cost ?? 0)}</span>
                                </Button>
                              </td>              
                              <td className="padding3">
                                <span></span>
                              </td>   
                            </tr>
                          )
                        })
                      }
                    </>
                    :
                    <></>
                    }
                </tbody>
                :
                <Box>
                  {loading && (
                    <CircularProgress
                      size={68}
                      sx={{
                        color: "grey",
                        position: 'absolute',
                        top: 500,
                        left: 910,
                        zIndex: 1,
                      }}
                    />
                  )}
                </Box>
              }
            </table>
          </div>
        </Box>
      </Box>
    </>
  );
} 

export default EuList;