import React from "react";

type ShipDirectionIndicatorProps = {
  course: string; // 船の向き（0-360度）
  beaufort: string; // 風の強さ
  windDirection: string; // 風向き（0-360度）
};

const ShipDirectionIndicator: React.FC<ShipDirectionIndicatorProps> = ({ course, windDirection, beaufort }) => {
  // 引数をパース
  const courseAngle = parseFloat(course);
  const windAngle = parseFloat(windDirection);
  const bfValue: number | "-" = beaufort === "-" ? "-" : parseInt(beaufort);

  // SVGのサイズ
  const svgWidth = 160;
  const svgHeight = 100;
  const centerX = svgWidth / 2; // 船の位置（X固定）
  const centerY = svgHeight / 2; // 中心位置（Y）

  // 円の半径
  const radius = 25;
  const correctedAngle = windAngle + 90;

  // 風向き矢印の先端の座標（円の外周）
  const arrowX = centerX + radius * Math.cos((correctedAngle * Math.PI) / 180);
  const arrowY = centerY + radius * Math.sin((correctedAngle * Math.PI) / 180);

  // Beaufort の表示位置
  let textDistance = 15;
  if (windAngle >= 0 && windAngle <= 45) {
    textDistance = 18 + (10 * (windAngle / 90));
  } else if (windAngle > 45 && windAngle <= 75) {
    textDistance = 25 + (10 * (windAngle / 90));
  } else if (windAngle > 75 && windAngle <= 105) {
    textDistance = 33;
  } else if (windAngle > 105 && windAngle <= 150) {
    textDistance = 33 - (10 * ((windAngle - 105) / 75)); // 105 → 180 のとき 33 → 25
  }

  const beaufortX = arrowX + textDistance * Math.cos((correctedAngle * Math.PI) / 180);
  let beaufortY = arrowY + textDistance * Math.sin((correctedAngle * Math.PI) / 180);
  if(windAngle > 75 && windAngle <= 105){    
    beaufortY = arrowY;
  } else if (windAngle > 105 && windAngle <= 120 ) {
    beaufortY = arrowY - (10 * ((180 - windAngle) / 75));
  } else if (windAngle > 120 && windAngle <= 140 ) {
    beaufortY = arrowY - (20 * ((180 - windAngle) / 75));
  } else if (windAngle > 140 && windAngle <= 150 ) {
    beaufortY = arrowY - (30 * ((180 - windAngle) / 75));
  }

  return (
    <svg width={svgWidth} height={svgHeight} viewBox={`0 0 ${svgWidth} ${svgHeight}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      {/* グラデーション背景の外周円 */}
      <circle cx={centerX} cy={centerY} r="20" stroke="#7A7A7A" strokeWidth="1" fill="url(#circleGradient)" />

      {/* **グラデーション定義（中心→外側に向かって白くなる）** */}
      <defs>
        <radialGradient id="circleGradient" cx="50%" cy="50%" r="50%">
          <stop offset="0%" stopColor="rgba(255, 255, 255, 0)" />
          <stop offset="100%" stopColor="rgba(255, 255, 255, 0.1)" />
        </radialGradient>
      </defs>


      {/* 船の向き矢印 */}
      <g transform={`translate(${centerX - 10}, ${centerY - 13}) rotate(${courseAngle}, 10.5, 13)`}>
        <path 
          d="M10.2485 0.214447L20.0428 25.3095L10.2485 20.3889L0.454102 25.3095L10.2485 0.214447Z" 
          fill="#D8D8D8"
        />
      </g>

      {/* 風向き矢印 */}
      <g transform={`translate(${arrowX}, ${arrowY}) rotate(${windAngle}, 0, 0)`}>
        <polygon 
          points="0,-5 -5,5 5,5" 
          fill="url(#windGradient)" 
        />
      </g>

      {/* Beaufort（値 + 単位）を風向き矢印の後ろに表示 */}
      <g transform={`translate(${beaufortX}, ${beaufortY})`}>
        <text 
          fill="#FFFFFF" 
          fontSize="13px" 
          fontFamily="Roboto" 
          textAnchor="middle" 
          alignmentBaseline="middle"
        >
          {bfValue}
        </text>
        <text 
          x="10" 
          fill="rgba(255, 255, 255, 0.7)" 
          fontSize="12px" 
          fontFamily="Roboto Condensed" 
          textAnchor="start" 
          alignmentBaseline="middle"
        >
          BF
        </text>
      </g>

      {/* 風向き矢印のグラデーション定義 */}
      <defs>
        <linearGradient id="windGradient" x1="0" y1="-5" x2="0" y2="5" gradientUnits="userSpaceOnUse">
          <stop offset="0%" stopColor="#B6FFFF" />
          <stop offset="100%" stopColor="#00FFFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ShipDirectionIndicator;
