import React, { useEffect, useRef } from 'react';
import './MainEngineGraph.css';

interface MainEngineGraphProps {
  title: string;
  maxValue: number;
  dataValue: number | "-";
  dataUnit: string;
}

export const MainEngineGraph: React.FC<MainEngineGraphProps> = ({ title, maxValue, dataValue, dataUnit }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const animationDuration = 1800; // アニメーションの時間をミリ秒で指定

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        const width = canvas.width;
        const height = canvas.height;
        const centerX = width / 2; // グラフの中心点X座標
        const centerY = height / 2; // グラフの中心点Y座標
        const radius = 30; // グラフ半径
        const startAngle = -0.5 * Math.PI; // グラフ始点
        const numericValue = dataValue === "-" ? 0 : dataValue;
        const endAngle = startAngle + (numericValue / maxValue) * 2 * Math.PI; // グラフ終点

        // イージング関数 (ease-in-out)
        const easeInOut = (t: number) => t < 0.5 
          ? 2 * t * t 
          : 1 - Math.pow(-2 * t + 2, 2) / 2;

        // アニメーションの設定
        const startTime = performance.now(); // アニメーションの開始時間
        const animate = (now: number) => {
          const elapsed = now - startTime; // 経過時間
          const progress = Math.min(elapsed / animationDuration, 1); // 進行度（0〜1）
          const easedProgress = easeInOut(progress); // イージングを適用した進行度
          const currentAngle = startAngle + easedProgress * (endAngle - startAngle); // 現在の角度

          // 背景の円弧
          ctx.clearRect(0, 0, width, height);
          ctx.beginPath();
          ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
          ctx.strokeStyle = '#4C515F';
          ctx.lineWidth = 6;
          ctx.stroke();

          // グラデーションの設定
          const gradient = ctx.createConicGradient(startAngle, centerX, centerY);
          gradient.addColorStop(0, '#1795B1');
          gradient.addColorStop((numericValue / maxValue) * 0.5, '#2FA1B6');
          gradient.addColorStop((numericValue / maxValue), '#3EA9B9');

          // シャドウの設定（グラデーションに合わせた明るい色）
          ctx.shadowColor = 'rgba(23,149,177,0.8)'; // シャドウの色を明るいブルーに設定
          ctx.shadowBlur = 8; // シャドウのぼかし具合
          ctx.shadowOffsetX = 0;
          ctx.shadowOffsetY = 0;

          // シャドウ付きの円弧を描画
          ctx.beginPath();
          ctx.arc(centerX, centerY, radius, startAngle, currentAngle);
          ctx.strokeStyle = gradient;
          ctx.lineWidth = 6;
          ctx.stroke();

          // シャドウをリセット
          ctx.shadowColor = 'transparent';
          ctx.shadowBlur = 0;

          // 上のグラフに重ねるグラデーションの円弧（シャドウと同じ範囲）
          ctx.beginPath();
          ctx.arc(centerX, centerY, radius, startAngle, currentAngle);
          ctx.strokeStyle = gradient;
          ctx.lineWidth = 6;
          ctx.stroke();

          // アニメーションが終わるまで再帰的に実行
          if (progress < 1) {
            requestAnimationFrame(animate);
          }
        };

        // アニメーション開始
        requestAnimationFrame(animate);
      }
    }
  }, [dataValue, maxValue]);

  return (
    <div className='me-graph-wrapper'>
      <div className='me-graph-title'>{title}</div>
      <div className='me-graph-graph'>      
        <canvas ref={canvasRef} width={80} height={80} />
        <div className='me-graph-value'>{dataValue}</div>
        <div className='me-graph-unit'>{dataUnit}</div>
      </div>
    </div>
  );
};
