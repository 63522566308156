
import * as React from 'react';

import axios, { AxiosError } from "axios";

import './VesselOverview.css'; // スタイルのために追加
import { SelectTheme } from '../../components/theme/SelectTheme';
import { Main, Drawer, BootstrapInput } from '../../components/styled/styled';
import { callAuth } from '../../components/components/auth';
import { SearchContext } from '../../App';
import { MyAppBar } from '../../components/components/AppBar';
import { SelectDate } from '../../components/components/select-date';
import { CustomMarker, CustomBeaufortMarker } from './CustomMarker';
import { SelectVesseName } from '../../components/components/SelectVesseNameComponent';
import CourseIcon from '../../assets/images/cog.png';
import DateIcon_svg from '../../assets/images/date.svg';
import OilIcon_svg from '../../assets/images/oil.svg';
import EngineIcon_svg from '../../assets/images/engine.svg';
import ShipIcon_svg from '../../assets/images/ship.png';

// MUI @mui/material/
import { ThemeProvider } from '@mui/material/styles'
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';

// MUI @mui/icons-material/
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SearchIcon from '@mui/icons-material/Search';
import AirIcon from '@mui/icons-material/Air';
import TsunamiIcon from '@mui/icons-material/Tsunami';
import WavesIcon from '@mui/icons-material/Waves';
import SettingsIcon from '@mui/icons-material/Settings';

// Dialog
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';


// Select Date
import dayjs from "dayjs";

// Highcharts
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

// Leaflet
import { MapContainer, TileLayer, useMap, CircleMarker, LayerGroup, Polyline } from 'react-leaflet'
import { LatLng } from "leaflet";
import L from "leaflet";
import 'leaflet/dist/leaflet.css';
L.Icon.Default.imagePath = 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/'



// Axios Error
interface ErrorData {
  message: string;
  status: number;
  error: string;
}

// Toggle Button
const me_load_color = '#FE349A'
const me_rpm_color = '#00A9FF'
const foc_color = '#19FED3'
const log_speed_color = '#E6B422'
const AntSwitch = (props) => {
  return (
    <>
      <Switch
        inputProps={{ 'aria-label': 'ant design' }}
        onChange={props.onChange}
        checked={props.checked}
        name={props.name}
        sx={{
          position: "absolute",
          top: "-8px",
          left: "0px",
          height: "24px",
          width: "32px",
          '& .MuiSwitch-thumb': {
            position: "absolute",
            top: "2px",
            left: "2px",
            width: "12px",
            height: "12px",
            borderRadius: 2,
          },
          '& .MuiSwitch-track': {
            position: "absolute",
            top: "0px",
            left: "0px",
            borderRadius: 16 / 2,
            opacity: 1,
            width: "28px",
            height: "16px",
            backgroundColor: '#333333',
          },
          '& .MuiSwitch-switchBase': {
            position: "absolute",
            top: "0px",
            left: "0px",
            transition: 'transform 0.2s',
            padding: 1.2,
            color: "rgb(105, 105, 105)",
            '&.Mui-checked': {
              transform: 'translateX(12px)',
              color: props.color,
              '& + .MuiSwitch-track': {
                border: '1px solid #f1f1f1',
                opacity: 1,
                backgroundColor: '#333333',
              },
            },
          },

        }} />
    </>
  )
}

function formatNumber(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function VesselOverview({ setSeachInfo }) {

  // userId有効期限チェック----------------------------------------------------------------------------------------------------------------------------
  const [message, setMessage] = React.useState<string>();
  const [userCheck, setUserCheck] = React.useState(false);
  let init = true

  const [user_id, setUser_id] = React.useState<string>();
  const [group_id, setGroup_id] = React.useState<string>();
  const [company_id, setCompany_id] = React.useState<string>();
  const [gid, setGid] = React.useState<string>();
  const [gidList, setGidList] = React.useState<string>();
  const [imoList, setImoList] = React.useState<string>();
  const [jwt, setJwt] = React.useState<string>();
  const [role, setRole] = React.useState<string>();

  // SelectVesselNameコンポーネント用
  const [vesselOpen, setVesselOpen] = React.useState<boolean>(false);
  const [currentFocusVessel, setCurrentFocusVessel] = React.useState<string>(''); // 矢印キー(↑↓)でドロップダウン船名変更時のフォーカスしている船
  const [vesselName, setVesselName] = React.useState<string>(''); // 表示船名
  
  const [SeachInfo_imo, setSeachInfo_imo] = React.useState<string>(''); // 検索imo
  const [SeachInfo_from, setSeachInfo_from] = React.useState<string>(''); // 検索期間From
  const [SeachInfo_to, setSeachInfo_to] = React.useState<string>(''); // 検索期間To

  const [initCompleted, setInitCompleted] = React.useState(false);

  React.useEffect(() => {
    if (init === true) {

      // localStrage取得----------------------------------------------------------------------------------------------------------------------------
      let jwt_ls
      if (localStorage.getItem("payload")) {
        const pay_json = localStorage.getItem("payload")
        if (pay_json != null) {
          const pay = JSON.parse(pay_json)
          jwt_ls = pay["jwt"]
          setUser_id(pay["user_id"])
          setGroup_id(pay["group_id"])
          setCompany_id(pay["company_id"])
          setGid(pay["gid"])
          setGidList(pay["gidList"])
          setImoList(pay["imoList"])
          setJwt(jwt_ls)
          setRole(pay["role"])
          setSeachInfo({ imoNo: `${SeachInfo.imoNo}`, from: SeachInfo.from, to: SeachInfo.to, gid: pay["gid"], gidList: pay["gidList"], imoList: pay["imoList"] })
        
          // 選択中の船のimo設定
          setCurrentFocusVessel(SeachInfo.imoNo)
  
          // 選択中の船名がある場合、船名取得し初期表示として設定
          for (const imoData of pay["imoList"]) {
            if (imoData.imoNo == SeachInfo.imoNo) {
              setVesselName(imoData.VesselName)
            }
          }}
      }

      if (jwt_ls) {
        callAuth(jwt_ls).then(result => {
          if (result) {
            console.log("認証成功[VesselOverview]");

            // Loading解除
            setSuccess(true);
            setLoading(false);
            setMessage("Authentication success.")
          } else {
            setUserCheck(true)
            console.log("認証失敗[VesselOverview]");
            setMessage("Authentication error.")
            localStorage.clear()
          }
        });
      }

      // 初期処理終了フラグを設定
      setInitCompleted(true);
    }

    init = false
  }, []);


  // WADATSUMIログイン画面遷移----------------------------------------------------------------------------------------------------------------------------
  const handleClickWADATSUMI = () => {
    window.location.replace(`${process.env.REACT_APP_WADATSUMIURL}`)
  };

  // コンテクスト取得----------------------------------------------------------------------------------------------------------------------------
  const SeachInfo: any = React.useContext(SearchContext);

  // 検索フォーム----------------------------------------------------------------------------------------------------------------------------
  const [base_url, setBase_url] = React.useState(`${process.env.REACT_APP_API_URL}/VesselOverview/`);

  // Select Imo----------------------------------------------------------------------------------------------------------------------------
  // const [Imo, setImo] = React.useState(SeachInfo.imoNo);
  const selectImo = (imoNo: string) => {
    // setImo(event.target.value as string);
    setSeachInfo({ imoNo: imoNo, from: SeachInfo.from, to: SeachInfo.to, gid: gid, gidList: gidList, imoList: imoList })
  };

  // Leaflet----------------------------------------------------------------------------------------------------------------------------
  function ChangeMapCenter({ position }) {
    const map = useMap()
    map.panTo(position)

    return null
  }

  // Drawer----------------------------------------------------------------------------------------------------------------------------
  const [openDrwer, setOpenDrwer] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpenDrwer(true);
  };
  const handleDrawerClose = () => {
    setOpenDrwer(false);
  };


  // DataRnage----------------------------------------------------------------------------------------------------------------------------
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  // Map----------------------------------------------------------------------------------------------------------------------------
  // LatLon
  const Initial_center = new LatLng(0, 0);
  const [CENTER, setCenter] = React.useState<LatLng>(Initial_center);
  const [polyline, setPolyline] = React.useState<any>([]);
  const [polyline_360Plus, setPolyline_360Plus] = React.useState<any>([]);
  const [polyline_360Minus, setPolyline_360Minus] = React.useState<any>([]);

  // plot_information----------------------------------------------------------------------------------------------------------------------------
  const plot = {
    utc_date: "",
    lat: "",
    lng: "",
    foc: "",
    co2_factor: "",
    co2: "",
    me_foc: "",
    me_load: "",
    log_speed: "",
    me_rpm: "",
    distance: "",
    displacement: "",
    wind_direction: "",
    wind_directionColor: "transparent",
    wind_directionCalcDeg: "",
    wind_directionCalcLeft: "",
    wind_directionCalcTop: "",
    wind_speed: "",
    wave_direction: "",
    wave_directionColor: "transparent",
    wave_directionCalcDeg: "",
    wave_directionCalcLeft: "",
    wave_directionCalcTop: "",
    wave_height: "",
    wave_period: "",
    swell_direction: "",
    swell_directionColor: "transparent",
    swell_directionCalcDeg: "",
    swell_directionCalcLeft: "",
    swell_directionCalcTop: "",
    swell_height: "",
    swell_period: "",
    course: "",
    beaufort: "",
    index: "",
  }
  const [plotInformation, setPlotInformation] = React.useState(plot);


  // ToggleButton----------------------------------------------------------------------------------------------------------------------------
  const [Toggle_me_load, setToggle_me_load] = React.useState<{ name: String, color: String, checked: boolean }>({ name: "checkedme_load", color: me_load_color, checked: true })
  const [Toggle_me_rpm, setToggle_me_rpm] = React.useState<{ name: String, color: String, checked: boolean }>({ name: "checkedme_rpm", color: me_rpm_color, checked: true })
  const [Toggle_foc, setToggle_foc] = React.useState<{ name: String, color: String, checked: boolean }>({ name: "checkedFOC", color: foc_color, checked: true })
  const [Toggle_log_speed, setToggle_log_speed] = React.useState<{ name: String, color: String, checked: boolean }>({ name: "checkedlog_speed", color: log_speed_color, checked: true })
  const [state, setState] = React.useState({
    checkedFOC: true,
    checkedlog_speed: true,
    checkedme_rpm: true,
    checkedme_load: true,
  });
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });

    const newHcOptions = { ...hcOptions };
    if (event.target.name === Toggle_me_load.name) {
      newHcOptions.series[0].visible = !newHcOptions.series[0].visible;
      Toggle_me_load.checked = !Toggle_me_load.checked
    }
    else if (event.target.name === Toggle_me_rpm.name) {
      newHcOptions.series[1].visible = !newHcOptions.series[1].visible;
      Toggle_me_rpm.checked = !Toggle_me_rpm.checked
    }
    else if (event.target.name === Toggle_foc.name) {
      newHcOptions.series[2].visible = !newHcOptions.series[2].visible;
      Toggle_foc.checked = !Toggle_foc.checked
    }
    else if (event.target.name === Toggle_log_speed.name) {
      newHcOptions.series[3].visible = !newHcOptions.series[3].visible;
      Toggle_log_speed.checked = !Toggle_log_speed.checked
    }

    setHcOptions(newHcOptions);
  };

  // Graph----------------------------------------------------------------------------------------------------------------------------
  // TimeSereis
  const [VesselMaster, setVesselMaster] = React.useState<any>();
  const [InPortAtSeaList, setInPortAtSeaList] = React.useState<any>([]);
  // const [InPortList, setInPortList] = React.useState<any>([]);
  // const [AtSeaList, setAtSeaList] = React.useState<any>([]);
  const [focTimeSereis, setFocTimeSereis] = React.useState<Array<Array<number>>>();
  const [log_speedTimeSereis, setLog_speedTimeSereis] = React.useState<Array<Array<number>>>();
  const [me_rpmTimeSereis, setMe_rpmTimeSereis] = React.useState<Array<Array<number>>>();
  const [me_loadTimeSereis, setMe_loadTimeSereis] = React.useState<Array<Array<number>>>();
  const [LATLNGTimeSereis, setLATLNGTimeSereis] = React.useState<any>([]);
  const [AllTimeSereis, setAllTimeSereis] = React.useState<any>([]);
  // yAxis
  const [yAxisMin, setyAxisMin] = React.useState<any>(0);
  const [yAxisMax, setyAxisMax] = React.useState<any>(100);
  const [yAxisMinShow, setyAxisMinShow] = React.useState<any>(0);
  const [yAxisMaxShow, setyAxisMaxShow] = React.useState<any>(100);
  // const [tickInterval, settickInterval] = React.useState<any>();
  const [yAxisMin_log_speed, setyAxisMin_log_speed] = React.useState<any>(0);
  const [yAxisMax_log_speed, setyAxisMax_log_speed] = React.useState<any>(30);
  const [yAxisMinShow_log_speed, setyAxisMinShow_log_speed] = React.useState<any>(0);
  const [yAxisMaxShow_log_speed, setyAxisMaxShow_log_speed] = React.useState<any>(30);
  // const [tickInterval_log_speed, settickInterval_log_speed] = React.useState<any>();
  const [openDialogyAxis, setOpenDialogAxis] = React.useState(false);
  const handleClickOpenDialogAxis = () => {
    setOpenDialogAxis(true);
  };
  const handleNoClickOpenDialogAxis = () => {
    setyAxisMinShow(yAxisMin)
    setyAxisMaxShow(yAxisMax)
    setyAxisMinShow_log_speed(yAxisMin_log_speed)
    setyAxisMaxShow_log_speed(yAxisMax_log_speed)
    setOpenDialogAxis(false);
  };
  const handleClickSetAxis = () => {
    console.log("yAxisMin", yAxisMin)
    if (yAxisMin >= yAxisMax && yAxisMin_log_speed >= yAxisMax_log_speed) {
      alert("Please enter a value greater than Min for Max.")
    }
    else if ((yAxisMin === "" || yAxisMax === "" || yAxisMin === undefined || yAxisMax === undefined)
      && (yAxisMin_log_speed === "" || yAxisMax_log_speed === "" || yAxisMin_log_speed === undefined || yAxisMax_log_speed === undefined)
    ) {
      alert("No spaces allowed. Please enter a value.")
    }
    else {
      setyAxisMin(yAxisMinShow)
      setyAxisMax(yAxisMaxShow)
      setyAxisMin_log_speed(yAxisMinShow_log_speed)
      setyAxisMax_log_speed(yAxisMaxShow_log_speed)
      setOpenDialogAxis(false);
      setKey(key + 1);
    }
  };
  const handleClickyAxisMinShow = (event) => {
    if (/^\d*$/.test(event)) { setyAxisMinShow(event); }
    else { alert("Please enter numbers in half-width characters.") }
  }
  const handleClickyAxisMaxShow = (event) => {
    if (/^\d*$/.test(event)) { setyAxisMaxShow(event); }
    else { alert("Please enter numbers in half-width characters.") }
  }
  const handleClickyAxisMinShow_log_speed = (event) => {
    if (/^\d*$/.test(event)) { setyAxisMinShow_log_speed(event); }
    else { alert("Please enter ship speed in half-width characters.") }
  }
  const handleClickyAxisMaxShow_log_speed = (event) => {
    if (/^\d*$/.test(event)) { setyAxisMaxShow_log_speed(event); }
    else { alert("Please enter ship speed in half-width characters.") }
  }
  React.useEffect(() => {
    const newHcOptions = { ...hcOptions };
    newHcOptions.yAxis[0].min = parseFloat(yAxisMin_log_speed)
    newHcOptions.yAxis[0].max = parseFloat(yAxisMax_log_speed)
    newHcOptions.yAxis[1].min = parseFloat(yAxisMin)
    newHcOptions.yAxis[1].max = parseFloat(yAxisMax)
    newHcOptions.yAxis[2].min = parseFloat(yAxisMin)
    newHcOptions.yAxis[2].max = parseFloat(yAxisMax)
    newHcOptions.yAxis[3].min = parseFloat(yAxisMin)
    newHcOptions.yAxis[3].max = parseFloat(yAxisMax)
    setHcOptions(newHcOptions)
  }, [yAxisMin, yAxisMax, yAxisMin_log_speed, yAxisMax_log_speed])

  // hcOptions
  const firstOptions = {
    navigation: {
      buttonOptions: {
        enabled: false,
      }
    },
    credits: {
      enabled: false
    },
    chart: {
      zooming: { type: "xy" },
      // width: 900,
      height: 250,
      alignTicks: false,
      backgroundColor: 'rgba(0, 0, 0, 0)',
      // plotBackgroundColor: {
      //   linearGradient: { x1: 0, x2: 0, y1: 1, y2: 0 },
      //   stops: [
      //     [0, 'rgba(255, 255, 255, 0.03)'],
      //     [0.8529, 'rgba(255, 255, 255, 0.13)'],
      //     [1, 'rgba(255, 255, 255, 0.15)']
      //   ]
      // },
    },
    rangeSelector: {
      selected: 1
    },
    title: {
      text: '',
      style: {
        fontSize: '12px',
        color: 'black'
      }
    },
    xAxis: {
      title: {
        enabled: false,
        text: "",
        style: {
          color: 'black'
        }
      },
      type: 'datetime',
      // tickInterval: 30 * 24 * 3600 * 1000,
      labels: {
        enabled: true,
        style: {
          color: 'white'
        }
      },
    },
    legend: {
      enabled: false,
    },
    yAxis: [
      {
        min: 0,
        max: 30,
        gridLineDashStyle: 'Dot',
        gridLineWidth: 0.5,
        opposite: true,
        title: {
          text: 'knot',
          rotation: 90,
          style: {
            color: 'rgba(255, 255, 255, 0.70)',
            opacity: 0.70,
            fontSize: "13px",
            fontWeight: '400',
            lineHeight: "24px",
            wordWrap: 'break-word'
          },
        },
        labels: {
          enabled: true,
          style: {
            color: 'rgba(255, 255, 255, 0.70)',
            opacity: 0.70,
            fontSize: "13px",
            fontFamily: 'Roboto Condensed',
            fontWeight: '400',
            lineHeight: "24px",
            wordWrap: 'break-word'
          }
        }
      },
      {
        min: 0,
        max: 100,
        gridLineWidth: 0,
        title: {
          text: '%・rpm・MT/day',
          rotation: -90,
          style: {
            color: 'rgba(255, 255, 255, 0.70)',
            opacity: 0.70,
            fontSize: "13px",
            fontWeight: '400',
            lineHeight: "24px",
            wordWrap: 'break-word'
          },
        },
        labels: {
          enabled: true,
          style: {
            color: 'rgba(255, 255, 255, 0.70)',
            opacity: 0.70,
            fontSize: "13px",
            fontFamily: 'Roboto Condensed',
            fontWeight: '400',
            lineHeight: "24px",
            wordWrap: 'break-word'
          },
        }
      },
      {
        min: 0,
        max: 100,
        gridLineWidth: 0,
        title: { enabled: false, },
        labels: { enabled: false, },
      },
      {
        min: 0,
        max: 100,
        gridLineWidth: 0,
        title: { enabled: false, },
        labels: { enabled: false, },
      },
    ],
    plotOptions: {
      height: "200px",
      series: {
        cursor: 'pointer',
        point: {
          events: {
            click: (event) => {
              onClickGraph(event);
            }
          }
        }
      }
    },
    series: [
      {
        type: 'line',
        name: "M/E Load",
        color: me_load_color,
        visible: true,
        yAxis: 3,
        data: me_loadTimeSereis,
        lineWidth: 0.7,
        marker: {
          enabled: false
        }
      }, {
        type: 'line',
        name: "M/E RPM",
        color: me_rpm_color,
        visible: true,
        yAxis: 1,
        data: me_rpmTimeSereis,
        lineWidth: 0.7,
        marker: {
          enabled: false
        }
      }, {
        type: 'line',
        name: "FOC",
        color: foc_color,
        visible: true,
        yAxis: 2,
        data: focTimeSereis,
        lineWidth: 0.7,
        marker: {
          enabled: false
        }
      }, {
        type: 'line',
        name: "LOG Speed",
        color: log_speed_color,
        visible: true,
        yAxis: 0,
        data: log_speedTimeSereis,
        lineWidth: 0.7,
        marker: {
          enabled: false
        }
      },
    ]
  };
  const chartComponentRef = React.useRef(null);
  const [hcOptions, setHcOptions] = React.useState<any>(firstOptions);



  // Loading--------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  // 時系列データ取得--------------------------------------------------------------------
  const [authCheck, setAuthCheck] = React.useState(false);
  const [authMessage, setAuthMessage] = React.useState("");

  // 初期処理完了を検知
  React.useEffect(() => {

    if (initCompleted === true) {
      // Loading表示
      setSuccess(false);
      setLoading(true);
      // API呼び出し
      getNoonReport(`${base_url}${SeachInfo.imoNo}?Timestamp_from=${SeachInfo.from}&Timestamp_to=${SeachInfo.to}`, jwt);
    }

  }, [initCompleted]);
    
  // VesselNameの変更検知
  React.useEffect(() => {

    let tmpImo = SeachInfo.imoNo;

    if (String(tmpImo) !== String(SeachInfo_imo)) {
      setSeachInfo_imo(tmpImo);

      // Loading表示
      setSuccess(false);
      setLoading(true);

      // API呼び出し
      getNoonReport(`${base_url}${SeachInfo.imoNo}?Timestamp_from=${SeachInfo.from}&Timestamp_to=${SeachInfo.to}`, jwt);
    }
  }, [SeachInfo.imoNo]);

  // 検索期間の変更検知
  React.useEffect(() => {

    let tmpFrom = SeachInfo.from;
    let tmpTo = SeachInfo.to;

    if (tmpFrom !== SeachInfo_from || tmpTo !== SeachInfo_to) {
      setSeachInfo_from(tmpFrom);
      setSeachInfo_to(tmpTo);

      // Loading表示
      setSuccess(false);
      setLoading(true);

      // API呼び出し
      getNoonReport(`${base_url}${SeachInfo.imoNo}?Timestamp_from=${SeachInfo.from}&Timestamp_to=${SeachInfo.to}`, jwt);
    }
  }, [SeachInfo.from, SeachInfo.to]);

  const getNoonReport = (url, jwt) => {
    console.log("url:", url);

    console.log("VesselOverview imoList:", imoList);
    if (SeachInfo.imoNo !== "" && SeachInfo.from !== null && SeachInfo.to !== null && jwt !== undefined) {

      const options = {
        headers: {
          'Authorization': jwt,
        }
      }
      axios.get(url, options).then((res) => {

        // 認可チェック
        if (res.data === 401) {
          setAuthCheck(true)
          setAuthMessage("不正な操作が行われた可能性があります。")
        } else if (res.data === 500) {
          setAuthCheck(true)
          setAuthMessage("JWTが改ざんされている可能性があります。")
        } else {
          setAuthCheck(false)
        }

        // 入力値保持
        setSeachInfo({ imoNo: `${SeachInfo.imoNo}`, from: SeachInfo.from, to: SeachInfo.to, gid: gid, gidList: gidList, imoList: imoList })
        console.log("VesselOverview imoList:", imoList);

        console.log("noonreport:", res.data.datas);
        const datas = res.data.datas.TIMESERIES;
        let state_on: boolean = false
        let inPortatSeaDate: any = []
        const inPortatSeaList: any = [];
        const inPortList: any = [];
        const atSeaList: any = [];
        const focGraphDataList: any = [];
        const log_speedGraphDataList: any = [];
        const me_rpmGraphDataList: any = [];
        const me_loadGraphDataList: any = [];
        const latlngGraphDataList: any = [];
        const DataList: any = [];
        const polylineList: any = [];
        const polylineList_360Plus: any = [];
        const polylineList_360Minus: any = [];

        for (let i = 0; i < datas.length; i++) {

          let lat_def = datas[i].lat
          if (lat_def === "") {
            lat_def = null
          } else {
            parseFloat(lat_def)
          }

          let lng_def = datas[i].lng
          if (lng_def === "") {
            lng_def = null
          } else {
            parseFloat(lng_def)
          }

          console.log("datas[i].displacement", datas[i].displacement)

          const utc_date = datas[i].utc_date;
          const state = datas[i].state;
          const lat = lat_def
          const lng = lng_def
          const foc = datas[i].foc
          const co2_factor = datas[i].co2_factor
          const co2 = datas[i].co2
          const me_foc = datas[i].me_foc
          const me_load = datas[i].me_load
          const log_speed = datas[i].log_speed
          const me_rpm = datas[i].me_rpm
          const distance = datas[i].distance
          const displacement = datas[i].displacement
          const wind_direction = datas[i].wind_direction
          const wind_speed = datas[i].wind_speed
          const wave_direction = datas[i].wave_direction
          const wave_height = datas[i].wave_height
          const wave_period = datas[i].wave_period
          const swell_direction = datas[i].swell_direction
          const swell_height = datas[i].swell_height
          const swell_period = datas[i].swell_period
          const course = datas[i].course
          const beaufort = datas[i].beaufort

          const foc_float = parseFloat(datas[i].foc)
          const co2_factor_float = parseFloat(datas[i].co2_factor)
          const co2_float = parseFloat(datas[i].co2)
          const me_foc_float = parseFloat(datas[i].me_foc)
          const me_load_float = parseFloat(datas[i].me_load)
          const log_speed_float = parseFloat(datas[i].log_speed)
          const me_rpm_float = parseFloat(datas[i].me_rpm)
          const distance_float = parseFloat(datas[i].distance)
          const displacement_float = parseFloat(datas[i].displacement)
          const wind_direction_float = parseFloat(datas[i].wind_direction)
          const wind_speed_float = parseFloat(datas[i].wind_speed)
          const wave_direction_float = parseFloat(datas[i].wave_direction)
          const wave_height_float = parseFloat(datas[i].wave_height)
          const wave_period_float = parseFloat(datas[i].wave_period)
          const swell_direction_float = parseFloat(datas[i].swell_direction)
          const swell_height_float = parseFloat(datas[i].swell_height)
          const swell_period_float = parseFloat(datas[i].swell_period)
          const course_float = parseFloat(datas[i].course)
          const beaufort_float = parseFloat(datas[i].beaufort)

          // 船(五角形)の方向
          const courseCalc = course;

          // 風の矢羽根の方向・矢羽根の本数（beaufort）
          const wind_directionCalc = wind_direction;
          const beaufortCalc = beaufort;

          // 風の方向
          const wind_directionCalcDeg = wind_direction_float
          // let wind_directionCalcDeg = 0;
          let wind_directionCalcLeft = 0;
          let wind_directionCalcTop = 0;
          let wind_directionColor = "#00FFFF";
          if (wind_direction_float < 22.5) {
            // wind_directionCalcDeg = 0;
            wind_directionCalcLeft = 8
            wind_directionCalcTop = 60
          } else if (wind_direction_float < 67.5) {
            // wind_directionCalcDeg = 45;
            wind_directionCalcLeft = -2
            wind_directionCalcTop = 50
          } else if (wind_direction_float < 112.5) {
            // wind_directionCalcDeg = 90;
            wind_directionCalcLeft = -12
            wind_directionCalcTop = 40
          } else if (wind_direction_float < 157.5) {
            // wind_directionCalcDeg = 135;
            wind_directionCalcLeft = -2
            wind_directionCalcTop = 30
          } else if (wind_direction_float < 202.5) {
            // wind_directionCalcDeg = 180;
            wind_directionCalcLeft = 8
            wind_directionCalcTop = 20
          } else if (wind_direction_float < 247.5) {
            // wind_directionCalcDeg = 225;
            wind_directionCalcLeft = 18
            wind_directionCalcTop = 30
          } else if (wind_direction_float < 292.5) {
            // wind_directionCalcDeg = 270;
            wind_directionCalcLeft = 26
            wind_directionCalcTop = 40
          } else if (wind_direction_float < 337.5) {
            // wind_directionCalcDeg = 315;
            wind_directionCalcLeft = 18
            wind_directionCalcTop = 50
          } else if (wind_direction_float <= 360) {
            // wind_directionCalcDeg = 0;
            wind_directionCalcLeft = 8
            wind_directionCalcTop = 60
          } else {
            // wind_directionCalcDeg = 0;
            wind_directionCalcLeft = 8
            wind_directionCalcTop = 10
            wind_directionColor = "transparent";
          }

          // 波の方向
          const wave_directionCalcDeg = wave_direction_float
          // let wave_directionCalcDeg = 0;
          let wave_directionCalcLeft = 0;
          let wave_directionCalcTop = 0;
          let wave_directionColor = "#00FFFF";
          if (wave_direction_float < 22.5) {
            // wave_directionCalcDeg = 0;
            wave_directionCalcLeft = 8
            wave_directionCalcTop = 60
          } else if (wave_direction_float < 67.5) {
            // wave_directionCalcDeg = 45;
            wave_directionCalcLeft = -2
            wave_directionCalcTop = 50
          } else if (wave_direction_float < 112.5) {
            // wave_directionCalcDeg = 90;
            wave_directionCalcLeft = -12
            wave_directionCalcTop = 40
          } else if (wave_direction_float < 157.5) {
            // wave_directionCalcDeg = 135;
            wave_directionCalcLeft = -2
            wave_directionCalcTop = 30
          } else if (wave_direction_float < 202.5) {
            // wave_directionCalcDeg = 180;
            wave_directionCalcLeft = 8
            wave_directionCalcTop = 20
          } else if (wave_direction_float < 247.5) {
            // wave_directionCalcDeg = 225;
            wave_directionCalcLeft = 18
            wave_directionCalcTop = 30
          } else if (wave_direction_float < 292.5) {
            // wave_directionCalcDeg = 270;
            wave_directionCalcLeft = 26
            wave_directionCalcTop = 40
          } else if (wave_direction_float < 337.5) {
            // wave_directionCalcDeg = 315;
            wave_directionCalcLeft = 18
            wave_directionCalcTop = 50
          } else if (wave_direction_float <= 360) {
            // wave_directionCalcDeg = 0;
            wave_directionCalcLeft = 8
            wave_directionCalcTop = 60
          } else {
            // wave_directionCalcDeg = 0;
            wave_directionCalcLeft = 8
            wave_directionCalcTop = 10
            wave_directionColor = "transparent";
          }

          // うねりの方向
          const swell_directionCalcDeg = swell_direction_float
          // let swell_directionCalcDeg = 0;
          let swell_directionCalcLeft = 0;
          let swell_directionCalcTop = 0;
          let swell_directionColor = "#00FFFF";
          if (swell_direction_float < 22.5) {
            // swell_directionCalcDeg = 0;
            swell_directionCalcLeft = 8
            swell_directionCalcTop = 60
          } else if (swell_direction_float < 67.5) {
            // swell_directionCalcDeg = 45;
            swell_directionCalcLeft = -2
            swell_directionCalcTop = 50
          } else if (swell_direction_float < 112.5) {
            // swell_directionCalcDeg = 90;
            swell_directionCalcLeft = -12
            swell_directionCalcTop = 40
          } else if (swell_direction_float < 157.5) {
            // swell_directionCalcDeg = 135;
            swell_directionCalcLeft = -2
            swell_directionCalcTop = 30
          } else if (swell_direction_float < 202.5) {
            // swell_directionCalcDeg = 180;
            swell_directionCalcLeft = 8
            swell_directionCalcTop = 20
          } else if (swell_direction_float < 247.5) {
            // swell_directionCalcDeg = 225;
            swell_directionCalcLeft = 18
            swell_directionCalcTop = 30
          } else if (swell_direction_float < 292.5) {
            // swell_directionCalcDeg = 270;
            swell_directionCalcLeft = 26
            swell_directionCalcTop = 40
          } else if (swell_direction_float < 337.5) {
            // swell_directionCalcDeg = 315;
            swell_directionCalcLeft = 18
            swell_directionCalcTop = 50
          } else if (swell_direction_float <= 360) {
            // swell_directionCalcDeg = 0;
            swell_directionCalcLeft = 8
            swell_directionCalcTop = 60
          } else {
            // swell_directionCalcDeg = 0;
            swell_directionCalcLeft = 8
            swell_directionCalcTop = 10
            swell_directionColor = "transparent";
          }

          // Time Series Graph描画用---------------------------------------------
          const me_loadGraphData: any = [utc_date, me_load_float];
          me_loadGraphDataList.push(me_loadGraphData)

          const me_rpmGraphData: any = [utc_date, me_rpm_float];
          me_rpmGraphDataList.push(me_rpmGraphData)

          const focGraphData: any = [utc_date, foc_float];
          focGraphDataList.push(focGraphData)

          const log_speedGraphData: any = [utc_date, log_speed_float];
          log_speedGraphDataList.push(log_speedGraphData)
          // -----------------------------------------------------

          // Map描画用---------------------------------------------

          // 時系列の全データ
          latlngGraphDataList.push({
            timestamp: utc_date,
            latlng: [lat, lng],
            course: courseCalc,
            wind_direction: wind_directionCalc,
            beaufort: beaufortCalc
          })

          // 緯度経度がnullでないかチェック
          let isSpasDataExist = false;
          if (lat && lng) {
            isSpasDataExist = true;
          }

          // 緯度経度がnull
          if (isSpasDataExist === true) {
            polylineList.push([lat, lng]) // ポリラインを引く
            polylineList_360Plus.push([lat, lng + 360])
            polylineList_360Minus.push([lat, lng - 360])
          }

          // IN PORTプロットライン描画用
          if (state_on === false && i > 0 && (state === "IN PORT" || state === "IN HARBOR")) {
            state_on = true
            // const inPortDate = utc_date  //「IN PORT」の日時をセット
            const inPortDate = datas[i - 1].utc_date  //「IN PORT」直前の「AT SEA」の日時をセット
            inPortList.push(inPortDate)
            inPortatSeaDate.push(inPortDate)
          }
          else if (state_on === true && state === "AT SEA") {
            state_on = false
            const atSeaDate = datas[i].utc_date  //「IN PORT」直前の「AT SEA」の日時をセット
            atSeaList.push(atSeaDate)
            inPortatSeaDate.push(atSeaDate)
            inPortatSeaList.push(inPortatSeaDate)
            inPortatSeaDate = [] // 初期化
          }

          // -----------------------------------------------------


          DataList.push({
            utc_date: utc_date,
            lat: lat,
            lng: lng,
            foc: foc,
            co2_factor: co2_factor,
            co2: co2,
            me_foc: me_foc,
            me_load: me_load,
            log_speed: log_speed,
            me_rpm: me_rpm,
            distance: distance,
            displacement: displacement,
            wind_direction: wind_direction,
            wind_directionColor: wind_directionColor,
            wind_directionCalcDeg: wind_directionCalcDeg,
            wind_directionCalcLeft: wind_directionCalcLeft,
            wind_directionCalcTop: wind_directionCalcTop,
            wind_speed: wind_speed,
            wave_direction: wave_direction,
            wave_directionColor: wave_directionColor,
            wave_directionCalcDeg: wave_directionCalcDeg,
            wave_directionCalcLeft: wave_directionCalcLeft,
            wave_directionCalcTop: wave_directionCalcTop,
            wave_height: wave_height,
            wave_period: wave_period,
            swell_direction: swell_direction,
            swell_directionColor: swell_directionColor,
            swell_directionCalcDeg: swell_directionCalcDeg,
            swell_directionCalcLeft: swell_directionCalcLeft,
            swell_directionCalcTop: swell_directionCalcTop,
            swell_height: swell_height,
            swell_period: swell_period,
            course: course,
            beaufort: beaufort,
          })
        }

        setInPortAtSeaList(inPortatSeaList)
        // setInPortList(inPortList)
        // setAtSeaList(atSeaList)
        setLog_speedTimeSereis(log_speedGraphDataList)
        setMe_rpmTimeSereis(me_rpmGraphDataList)
        setMe_loadTimeSereis(me_loadGraphDataList)
        setFocTimeSereis(focGraphDataList)
        setLATLNGTimeSereis(latlngGraphDataList)
        setPolyline(polylineList)
        setPolyline_360Plus(polylineList_360Plus)
        setPolyline_360Minus(polylineList_360Minus)
        setAllTimeSereis(DataList)

        // VesselMaster
        setVesselMaster(res.data.datas.VESSELMASTER)

        // Loading解除
        setSuccess(true);
        setLoading(false);
      })
        .catch((e: AxiosError<ErrorData>) => {
          // Loading解除
          setSuccess(true);
          setLoading(false);
          reset_all()
          if (e.response === undefined) {
            console.log("undefinedError: " + e);
            return;
          }
          console.log(e + ", message: " + e.response.data.message);
        });
      }
  }

  const reset_all = () => {
    setLog_speedTimeSereis([])
    setMe_rpmTimeSereis([])
    setMe_loadTimeSereis([])
    setFocTimeSereis([])
    setLATLNGTimeSereis([])
    setPolyline([])
    setPolyline_360Plus([])
    setPolyline_360Minus([])
    setAllTimeSereis([])

    // VesselMaster
    setVesselMaster("")
  }

  React.useEffect(() => {
    setPlotInformation(plot)
    if (AllTimeSereis[0] !== undefined) {
      const last_yoso = AllTimeSereis.slice(-1)[0]
      onClickMap(AllTimeSereis.length - 1, last_yoso.utc_date)
    }

    const newHcOptions = { ...hcOptions };
    newHcOptions.series[0].data = me_loadTimeSereis
    newHcOptions.series[1].data = me_rpmTimeSereis
    newHcOptions.series[2].data = focTimeSereis
    newHcOptions.series[3].data = log_speedTimeSereis
    setHcOptions(newHcOptions)

  }, [AllTimeSereis, me_loadTimeSereis, me_rpmTimeSereis, focTimeSereis, log_speedTimeSereis])


  // React.useEffect(() => {
  //   console.log("AllTimeSereis", AllTimeSereis)
  //   console.log("hcOptions", hcOptions)
  // }, [AllTimeSereis, hcOptions])


  // グラフ上の線をクリックされたら
  const [key, setKey] = React.useState(0);
  const [changeClickGraph, setChangeClickGraph] = React.useState<number>(0);
  const [clickItemGraph, setClickItemGraph] = React.useState({ utc: null, y: 0, index: 0 }); // クリックした点の値

  const onClickGraph = (event: any) => { //グラフ上の点をクリック
    setChangeClickGraph((prev) => (prev + 1));
    setClickItemGraph({ utc: event.point.x, y: event.point.y, index: event.point.index });
    setClickItemMap({ index: event.point.index, utc: event.point.x });
  }
  React.useEffect(() => {
    if (changeClickGraph > 0) {
      changeclickSeries(clickItemGraph)
    }
  }, [changeClickGraph])


  // 船アイコンがクリックされたら
  const [changeClickMap, setChangeClickMap] = React.useState<number>(0);
  const [clickItemMap, setClickItemMap] = React.useState({ index: 0, utc: null }); // クリックした点の値

  const onClickMap = (index, utc) => {
    setChangeClickMap((prev) => (prev + 1));
    setClickItemMap({ index: index, utc: utc });
  }
  React.useEffect(() => {
    if (changeClickMap > 0) {
      changeclickSeries(clickItemMap)
    }
  }, [changeClickMap, clickItemMap])

  // クリックされた点の各チャンネルの値をセット
  const changeclickSeries = (item) => {
    if (AllTimeSereis !== undefined) {
      // console.log("item", item)
      const SelectDate = new Date(AllTimeSereis[item.index].utc_date)
      const SelectDate_dayjs = dayjs(SelectDate).utc()
      const Year = SelectDate_dayjs.year().toString()


      let Month = (SelectDate_dayjs.month() + 1).toString()
      if (Month.length > 1) { } else { Month = "0" + Month }

      let Day = SelectDate_dayjs.date().toString()
      if (Day.length > 1) { } else { Day = "0" + Day }

      let Hour = SelectDate_dayjs.hour().toString()
      if (Hour.length > 1) { } else { Hour = "0" + Hour }

      let Minute = SelectDate_dayjs.minute().toString()
      if (Minute.length > 1) { } else { Minute = "0" + Minute }

      const plotDate = Year + "/" + Month + "/" + Day + " " + Hour + ":" + Minute

      let lat_plot = AllTimeSereis[item.index].lat
      let lng_plot = AllTimeSereis[item.index].lng
      let foc_plot = AllTimeSereis[item.index].foc
      let co2_factor_plot = AllTimeSereis[item.index].co2_factor
      let co2_plot = AllTimeSereis[item.index].co2
      let me_foc_plot = AllTimeSereis[item.index].me_foc
      let me_load_plot = AllTimeSereis[item.index].me_load
      let log_speed_plot = AllTimeSereis[item.index].log_speed
      let me_rpm_plot = AllTimeSereis[item.index].me_rpm
      let distance_plot = AllTimeSereis[item.index].distance
      let displacement_plot = AllTimeSereis[item.index].displacement
      // let wind_direction_plot =  AllTimeSereis[item.index].wind_direction
      // let wind_directionColor_plot = AllTimeSereis[item.index].wind_directionColor
      // let wind_directionCalcDeg_plot = AllTimeSereis[item.index].wind_directionCalcDeg
      // let wind_directionCalcLeft_plot = AllTimeSereis[item.index].wind_directionCalcLeft
      // let wind_directionCalcTop_plot = AllTimeSereis[item.index].wind_directionCalcTop
      let wind_speed_plot = AllTimeSereis[item.index].wind_speed
      // let wave_direction_plot = AllTimeSereis[item.index].wave_direction
      // let wave_directionColor_plot = AllTimeSereis[item.index].wave_directionColor
      // let wave_directionCalcDeg_plot = AllTimeSereis[item.index].wave_directionCalcDeg
      // let wave_directionCalcLeft_plot = AllTimeSereis[item.index].wave_directionCalcLeft
      // let wave_directionCalcTop_plot = AllTimeSereis[item.index].wave_directionCalcTop
      let wave_height_plot = AllTimeSereis[item.index].wave_height
      let wave_period_plot = AllTimeSereis[item.index].wave_period
      // let swell_direction_plot = AllTimeSereis[item.index].swell_direction
      // let swell_directionColor_plot = AllTimeSereis[item.index].swell_directionColor
      // let swell_directionCalcDeg_plot = AllTimeSereis[item.index].swell_directionCalcDeg
      // let swell_directionCalcLeft_plot = AllTimeSereis[item.index].swell_directionCalcLeft
      // let swell_directionCalcTop_plot = AllTimeSereis[item.index].swell_directionCalcTop
      let swell_height_plot = AllTimeSereis[item.index].swell_height
      let swell_period_plot = AllTimeSereis[item.index].swell_period
      let course_plot = AllTimeSereis[item.index].course
      let beaufort_plot = AllTimeSereis[item.index].beaufort

      if (isNaN(lat_plot)) { lat_plot = "" }
      if (isNaN(lng_plot)) { lat_plot = "" }
      if (isNaN(foc_plot)) { foc_plot = "" }
      if (isNaN(co2_factor_plot)) { co2_factor_plot = "" }
      if (isNaN(co2_plot)) { co2_plot = "" }
      if (isNaN(me_foc_plot)) { me_foc_plot = "" }
      if (isNaN(me_load_plot)) { me_load_plot = "" }
      if (isNaN(log_speed_plot)) { log_speed_plot = "" }
      if (isNaN(me_rpm_plot)) { me_rpm_plot = "" }
      if (isNaN(distance_plot)) { distance_plot = "" }
      if (isNaN(displacement_plot)) { displacement_plot = "" }
      // if (isNaN(wind_direction_plot)) { wind_direction_plot =""}
      // if (isNaN(wind_directionColor_plot)) { wind_directionColor_plot =""}
      // if (isNaN(wind_directionCalcDeg_plot)) { wind_directionCalcDeg_plot =""}
      // if (isNaN(wind_directionCalcLeft_plot)) { wind_directionCalcLeft_plot =""}
      // if (isNaN(wind_directionCalcTop_plot)) { wind_directionCalcTop_plot =""}
      if (isNaN(wind_speed_plot)) { wind_speed_plot = "" }
      // if (isNaN(wave_direction_plot)) { wave_direction_plot =""}
      // if (isNaN(wave_directionColor_plot)) { wave_directionColor_plot =""}
      // if (isNaN(wave_directionCalcDeg_plot)) { wave_directionCalcDeg_plot =""}
      // if (isNaN(wave_directionCalcLeft_plot)) { wave_directionCalcLeft_plot =""}
      // if (isNaN(wave_directionCalcTop_plot)) { wave_directionCalcTop_plot =""}
      if (isNaN(wave_height_plot)) { wave_height_plot = "" }
      if (isNaN(wave_period_plot)) { wave_period_plot = "" }
      // if (isNaN(swell_direction_plot)) { swell_direction_plot =""}
      // if (isNaN(swell_directionColor_plot)) { swell_directionColor_plot =""}
      // if (isNaN(swell_directionCalcDeg_plot)) { swell_directionCalcDeg_plot =""}
      // if (isNaN(swell_directionCalcLeft_plot)) { swell_directionCalcLeft_plot =""}
      // if (isNaN(swell_directionCalcTop_plot)) { swell_directionCalcTop_plot =""}
      if (isNaN(swell_height_plot)) { swell_height_plot = "" }
      if (isNaN(swell_period_plot)) { swell_period_plot = "" }
      if (isNaN(course_plot)) { course_plot = "" }
      if (isNaN(beaufort_plot)) { beaufort_plot = "" }

      const plotInformation = {
        utc_date: plotDate,
        lat: lat_plot,
        lng: lng_plot,
        foc: foc_plot,
        co2_factor: co2_factor_plot,
        co2: co2_plot,
        me_foc: me_foc_plot,
        me_load: me_load_plot,
        log_speed: log_speed_plot,
        me_rpm: me_rpm_plot,
        distance: distance_plot,
        displacement: displacement_plot,
        wind_direction: AllTimeSereis[item.index].wind_direction,
        wind_directionColor: AllTimeSereis[item.index].wind_directionColor,
        wind_directionCalcDeg: AllTimeSereis[item.index].wind_directionCalcDeg,
        wind_directionCalcLeft: AllTimeSereis[item.index].wind_directionCalcLeft,
        wind_directionCalcTop: AllTimeSereis[item.index].wind_directionCalcTop,
        wind_speed: wind_speed_plot,
        wave_direction: AllTimeSereis[item.index].wave_direction,
        wave_directionColor: AllTimeSereis[item.index].wave_directionColor,
        wave_directionCalcDeg: AllTimeSereis[item.index].wave_directionCalcDeg,
        wave_directionCalcLeft: AllTimeSereis[item.index].wave_directionCalcLeft,
        wave_directionCalcTop: AllTimeSereis[item.index].wave_directionCalcTop,
        wave_height: wave_height_plot,
        wave_period: wave_period_plot,
        swell_direction: AllTimeSereis[item.index].swell_direction,
        swell_directionColor: AllTimeSereis[item.index].swell_directionColor,
        swell_directionCalcDeg: AllTimeSereis[item.index].swell_directionCalcDeg,
        swell_directionCalcLeft: AllTimeSereis[item.index].swell_directionCalcLeft,
        swell_directionCalcTop: AllTimeSereis[item.index].swell_directionCalcTop,
        swell_height: swell_height_plot,
        swell_period: swell_period_plot,
        course: course_plot,
        beaufort: beaufort_plot,
        index: item.index,
      }
      setPlotInformation(plotInformation);

      setCenter(new LatLng(AllTimeSereis[item.index].lat, AllTimeSereis[item.index].lng))

      const newHcOptions = { ...hcOptions };
      newHcOptions.xAxis.plotLines = []
      newHcOptions.xAxis.plotLines.push({
        color: 'red',
        value: item.utc,
        zIndex: 6,
        width: 1,
      })
      // for (let i = 0; i < AtSeaList.length; i++) {
      //   const plotline = {
      //     color: 'rgb(255,0,255,1)',
      //     value: AtSeaList[i],
      //     zIndex: 5,
      //     width: 0.5,
      //     dashStyle: '5 5 5 5'
      //   }
      //   newHcOptions.xAxis.plotLines.push(plotline)
      // }
      // for (let i = 0; i < InPortList.length; i++) {
      //   const plotline = {
      //     color: 'rgb(0,0,255,1)',
      //     value: InPortList[i],
      //     zIndex: 5,
      //     width: 0.5,
      //     dashStyle: '5 5 5 5'
      //   }
      //   newHcOptions.xAxis.plotLines.push(plotline)
      // }
      newHcOptions.xAxis.plotBands = []
      for (let i = 0; i < InPortAtSeaList.length; i++) {
        if (InPortAtSeaList.length !== 0) {
          const plotline = {
            from: InPortAtSeaList[i][0],
            to: InPortAtSeaList[i][1],
            color: 'rgba(128, 128, 128, 0.4)'
          }
          newHcOptions.xAxis.plotBands.push(plotline)
        }
      }
      setHcOptions(newHcOptions)

    }
  }

  // サイドバー展開--------------------------------------------------------------------
  // 現在のアクティブなタブを管理するための状態
  const [SeachTab, setSeachTab] = React.useState<boolean>(true);
  const [VesselInformationTab, setVesselInformationTab] = React.useState<boolean>(true);

  // タブがクリックされた時に呼び出されるハンドラー関数
  const handleTabClick = (index) => {
    console.log("handleTabClick", index)
    if (index === 1) {
      setSeachTab(!SeachTab)
    }
    else if (index === 2) {
      setVesselInformationTab(!VesselInformationTab)
    }
  };

  return (
    <>
      <Box height="100vh" sx={{ display: 'flex' }}>
        <CssBaseline />
        {/* useId有効期限切れダイアログ */}
        <Dialog
          open={userCheck}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "600px",  // Set your width here
                height: "100%",
                maxHeight: "210px",  // Set your width here
              },
            },
          }}
        >
          <DialogTitle
            sx={{
              bgcolor: "#474C53",
              height: "50px",
              color: "white",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}>
            UserId Update
          </DialogTitle>
          <DialogContent
            sx={{
              background: "linear-gradient(180deg, #0E1B25 0%, #17232D 100%)",
              height: "100px",
            }}>
            <Typography align="left" sx={{ marginTop: "32px", color: 'white', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', wordWrap: 'break-word' }}>
              {message === "" ? "" : message}
            </Typography>
          </DialogContent>
          <DialogActions
            sx={{
              height: "50px",
              bgcolor: "#474C53",
            }}>
            <Button
              sx={{
                marginLeft: "0px",
                marginRight: "-8px",
                backgroundColor: "#e66300",
                width: "150px",
                height: "50px",
                padding: "0",
                fontSize: "18px",
                fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
                fontWeight: "bold",
                color: "white",
                borderRadius: "0px",
                borderBottomRightRadius: "2px",
                ":hover": {
                  bgcolor: "#FFFFFF",
                  color: "#40475D",
                },
                textTransform: 'none'
              }}
              onClick={handleClickWADATSUMI}>
              OK
            </Button>
          </DialogActions>
        </Dialog>

        {/* 認可チェックダイアログ */}
        <Dialog
          open={authCheck}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "600px",  // Set your width here
                height: "100%",
                maxHeight: "210px",  // Set your width here
              },
            },
          }}
        >
          <DialogTitle
            sx={{
              bgcolor: "#474C53",
              height: "50px",
              color: "white",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}>
            Auth check
          </DialogTitle>
          <DialogContent
            sx={{
              background: "linear-gradient(180deg, #0E1B25 0%, #17232D 100%)",
              height: "100px",
            }}>
            <Typography align="left" sx={{ marginTop: "32px", color: 'white', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', wordWrap: 'break-word' }}>
              {authMessage === "" ? "" : authMessage}
            </Typography>
          </DialogContent>
          <DialogActions
            sx={{
              height: "50px",
              bgcolor: "#474C53",
            }}>
            <Button
              sx={{
                marginLeft: "0px",
                marginRight: "-8px",
                backgroundColor: "#e66300",
                width: "150px",
                height: "50px",
                padding: "0",
                fontSize: "18px",
                fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
                fontWeight: "bold",
                color: "white",
                borderRadius: "0px",
                borderBottomRightRadius: "2px",
                ":hover": {
                  bgcolor: "#FFFFFF",
                  color: "#40475D",
                },
                textTransform: 'none'
              }}
              onClick={() => setAuthCheck(false)}>
              OK
            </Button>
          </DialogActions>
        </Dialog>


        {/* menu bar */}
        <MyAppBar company_id={company_id} group_id={group_id} user_id={user_id} role={role} />


        {/* side bar */}
        <Drawer
          variant="permanent"
          open={openDrwer}
          sx={{
            // background: 'linear-gradient(180deg, #3B3D43 0%, #383A40 100%)',
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              background: '#282F35',
              boxSizing: 'border-box',
              borderRight: "1px solid rgb(201,201,201,0.2)"
            },
          }}>
          <React.Fragment>
            {/* 検索フォーム開閉 */}
            {openDrwer ?
              <Box
                sx={{
                  marginTop: "64px",
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    width: "6px",
                    height: "6px",
                  },
                  '&::-webkit-scrollbar-track': {
                    borderRadius: "10px",
                    background: "rgba(255, 255, 255, 0.5)",
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: "#fff",
                    borderRadius: "10px",
                  },
                  '&::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: '#555',
                  },
                  "&::-webkit-scrollbar-corner": {
                    display: "none",
                  }
                }}>

                {/* ↓ Space ↓ */}
                <Stack alignItems="center" sx={{ position: "relative", height: "20px" }}>
                </Stack>
                {/* ↑ Space ↑ */}

                {/* ↓ Select Search Conditions ↓ */}
                <Stack alignItems="left" sx={{ position: "relative" }}>
                  {
                    SeachTab !== true ?
                      <>
                        {/* title */}
                        <Stack sx={{ position: "relative", height: "40px", cursor: "pointer", background: "rgba(59.32, 64.07, 71.19, 0.0)" }} onClick={() => handleTabClick(1)}>
                          <Box sx={{ position: "absolute", top: "14px", left: "25px", }}>
                            <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(45deg)" }}></Box>
                          </Box>
                          <Box sx={{ position: "absolute", top: "0px", left: "42px" }}>
                            <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              Select Search Conditions
                            </Typography>
                          </Box>
                        </Stack>
                      </>
                      :
                      <>
                        {/* title */}
                        <Stack sx={{ position: "relative", height: "40px", cursor: "pointer", background: "rgba(59.32, 64.07, 71.19, 0.0)" }} onClick={() => handleTabClick(1)}>
                          <Box sx={{ position: "absolute", top: "11px", left: "25px", }}>
                            <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(135deg)" }}></Box>
                          </Box>
                          <Box sx={{ position: "absolute", top: "0px", left: "42px" }}>
                            <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              Select Search Conditions
                            </Typography>
                          </Box>
                        </Stack>

                        {/* main */}
                        <Stack sx={{ position: "relative", height: "220px", width: `calc(100% - 22px)` }}>

                          {/* Vessel Name */}
                          <Stack alignItems="center" sx={{ position: "relative", top: "10px", left: "22px", height: "110px" }}>
                            <Typography
                              align="left"
                              sx={{
                                position: "absolute",
                                top: "0px",
                                left: "20px",
                                color: 'white',
                                fontSize: "14px",
                                fontFamily: "'Roboto'",
                                fontWeight: '700',
                                lineHeight: "24px",
                                wordWrap: 'break-word',
                              }}>
                              Vessel Name
                            </Typography>
                            <SelectVesseName
                              vesselName = {vesselName}
                              vesselOpen = {vesselOpen}
                              currentFocusVessel = {currentFocusVessel}
                              imoNo = {SeachInfo.imoNo}
                              imoList = {SeachInfo.imoList}
                              setVesseName = {(updateValue: string) => setVesselName(updateValue)}
                              setVesselOpen = {(updateValue: boolean) => setVesselOpen(updateValue)}
                              setCurrentFocusVessel = {(updateValue: string) => setCurrentFocusVessel(updateValue)}
                              selectImo = {(updateValue: string) => selectImo(updateValue)}
                            />


                          </Stack>

                          {/* Data Range */}
                          <Stack alignItems="center" sx={{ position: "relative", top: "0px", left: "22px", height: "110px" }}>
                            <Typography
                              align="left"
                              sx={{
                                position: "absolute",
                                top: "0px",
                                left: "20px",
                                color: 'white',
                                fontSize: "14px",
                                fontFamily: "'Roboto'",
                                fontWeight: '700',
                                lineHeight: "24px",
                                wordWrap: 'break-word',
                              }}>
                              Data Range
                            </Typography>
                            <Button
                              variant="outlined"
                              onClick={handleClickOpenDialog}
                              sx={{
                                position: "absolute",
                                top: "30px",
                                left: "20px",
                                width: "265px",
                                height: 45,
                                background: 'rgba(255, 255, 255, 0.15)',
                                borderRadius: 30,
                                border: '1px #CBCBCB solid',
                                color: 'white',
                                fontSize: 15,
                                fontFamily: 'Roboto',
                                fontWeight: '700',
                                wordWrap: 'break-word',
                                ":hover": { backgroundColor: "#7a86a0", border: '1px #CBCBCB solid' },
                              }}>
                              <Stack direction='row' sx={{ height: 20 }} alignItems="center" justifyContent="center">
                                <CalendarMonthIcon />
                              </Stack>
                              <Typography align="left" sx={{ color: 'white', width: "100%", fontSize: "15px", marginLeft: 2 }}>
                                {dayjs(SeachInfo.from).utc().year() + "/" + (dayjs(SeachInfo.from).utc().month() + 1) + "/" + dayjs(SeachInfo.from).utc().date()}
                                ~
                                {dayjs(SeachInfo.to).utc().year() + "/" + (dayjs(SeachInfo.to).utc().month() + 1) + "/" + dayjs(SeachInfo.to).utc().date()}
                              </Typography>
                            </Button>
                          </Stack>
                          <Box >{<SelectDate setSeachInfo={setSeachInfo} SeachInfo={SeachInfo} openDialog={openDialog} setOpenDialog={setOpenDialog} />}</Box>
                        </Stack>
                      </>
                  }
                </Stack>
                {/* ↑ Select Search Conditions ↑ */}

                {/* ↓ Border ↓ */}
                <Stack alignItems="center" sx={{ position: "relative", height: "60px" }}>
                  <Box sx={{ position: "absolute", top: "30px", width: `80%`, border: '1px rgba(255, 255, 255, 0.30) dotted' }}></Box>
                </Stack>
                {/* ↑ Border ↑ */}

                {/* ↓ VesselInformation ↓ */}
                <Stack alignItems="left" sx={{ position: "relative" }}>
                  {
                    VesselInformationTab !== true ?
                      <>
                        {/* title */}
                        <Stack sx={{ position: "relative", height: "40px", cursor: "pointer", background: "rgba(59.32, 64.07, 71.19, 0.0)" }} onClick={() => handleTabClick(2)}>
                          <Box sx={{ position: "absolute", top: "14px", left: "25px", }}>
                            <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(45deg)" }}></Box>
                          </Box>
                          <Box sx={{ position: "absolute", top: "0px", left: "42px" }}>
                            <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              Vessel Information
                            </Typography>
                          </Box>
                        </Stack>
                      </>
                      :
                      <>
                        {/* title */}
                        <Stack sx={{ position: "relative", height: "40px", cursor: "pointer", background: "rgba(59.32, 64.07, 71.19, 0.0)" }} onClick={() => handleTabClick(2)}>
                          <Box sx={{ position: "absolute", top: "12px", left: "25px", }}>
                            <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(135deg)" }}></Box>
                          </Box>
                          <Box sx={{ position: "absolute", top: "0px", left: "42px" }}>
                            <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                              Vessel Information
                            </Typography>
                          </Box>
                        </Stack>

                        {/* main */}
                        {success ?

                          <Stack sx={{ position: "relative", height: "330px", width: `calc(100% - 22px)` }}>
                            <Grid container direction="row" sx={{ position: "absolute", left: "22px", top: "0px" }}>

                              {/* Vessel Type */}
                              <Grid item xs={12}>
                                <Grid container direction="row" >
                                  <Grid item xs={1}>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                      Vessel Type
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1}>
                                    <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                      :
                                    </Box>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                                      {VesselMaster ? VesselMaster.VesselType !== "" ? VesselMaster.VesselType : "-" : "-"}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>

                              {/* Fuel Type */}
                              <Grid item xs={12}>
                                <Grid container direction="row" >
                                  <Grid item xs={1}>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                      Fuel Type
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1}>
                                    <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                      :
                                    </Box>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                                      {VesselMaster ? VesselMaster.OilType !== "" ? VesselMaster.OilType : "-" : "-"}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>

                              {/* Segmental */}
                              <Grid item xs={12}>
                                <Grid container direction="row" >
                                  <Grid item xs={1}>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                      Segmental
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1}>
                                    <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                      :
                                    </Box>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                                      {VesselMaster ? VesselMaster.Size !== "" ? VesselMaster.Size : "-" : "-"}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>

                              {/* DeadWeight */}
                              <Grid item xs={12}>
                                <Grid container direction="row" alignItems={"center"}>
                                  <Grid item xs={1}>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                      DeadWeight
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1}>
                                    <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                      :
                                    </Box>
                                  </Grid>
                                  <Grid item xs={2.5}>
                                    <Typography align="left" sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                                      {VesselMaster ? VesselMaster.Deadweight !== "" ? formatNumber(parseFloat(VesselMaster.Deadweight)) : "-" : "-"}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={2.5}>
                                    <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.5)', fontSize: "14px", fontFamily: "'Roboto Condensed Regular'", wordWrap: 'break-word' }}>
                                      {VesselMaster ? VesselMaster.Deadweight !== "" ? "ton" : "" : ""}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>

                              {/* Gross */}
                              <Grid item xs={12}>
                                <Grid container direction="row" alignItems={"center"} >
                                  <Grid item xs={1}>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                      Gross
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1}>
                                    <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                      :
                                    </Box>
                                  </Grid>
                                  <Grid item xs={2.5}>
                                    <Typography align="left" sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                                      {VesselMaster ? VesselMaster.Grosstongue !== "" ? formatNumber(parseFloat(VesselMaster.Grosstongue)) : "-" : "-"}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={2.5}>
                                    <Typography align="left" sx={{ color: 'rgba(255, 255, 255, 0.5)', fontSize: "14px", fontFamily: "'Roboto Condensed Regular'", wordWrap: 'break-word' }}>
                                      {VesselMaster ? VesselMaster.Grosstongue !== "" ? "ton" : "" : ""}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>

                              {/* Built Date */}
                              <Grid item xs={12}>
                                <Grid container direction="row" >
                                  <Grid item xs={1}>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                      Built Date
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1}>
                                    <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                      :
                                    </Box>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                                      {VesselMaster ? VesselMaster.BuildDate !== "" ? VesselMaster.BuildDate : "-" : "-"}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>

                              {/* Hull Cleaning Date */}
                              <Grid item xs={12}>
                                <Grid container direction="row" >
                                  <Grid item xs={1}>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                      Hull Cleaning Date
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1}>
                                    <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                      :
                                    </Box>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                                      {VesselMaster ? VesselMaster.HullCleaningDate !== "" ? VesselMaster.HullCleaningDate : "-" : "-"}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>

                              {/* Last Dock Date */}
                              <Grid item xs={12}>
                                <Grid container direction="row" >
                                  <Grid item xs={1}>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Typography sx={{ color: "rgba(255, 255, 255, 0.50)", fontSize: "13px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                      Last Dock Date
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1}>
                                    <Box sx={{ color: "rgba(255, 255, 255, 0.30)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 700, lineHeight: "38px", wordWrap: "break-word" }}>
                                      :
                                    </Box>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Typography sx={{ color: "rgba(255, 255, 255, 1)", fontSize: "14px", fontFamily: "Roboto", fontWeight: 400, lineHeight: "38px", wordWrap: "break-word" }}>
                                      {VesselMaster ? VesselMaster.LastDockDate !== "" ? VesselMaster.LastDockDate : "-" : "-"}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Stack>
                          :

                          <Stack sx={{ position: "relative", height: "330px", width: `${300 - 20}px` }}>
                            {loading && (
                              <CircularProgress
                                size={68}
                                sx={{
                                  color: "grey",
                                  position: 'absolute',
                                  top: "100px",
                                  left: "100px",
                                  zIndex: 1,
                                }}
                              />
                            )}
                          </Stack>
                        }
                      </>
                  }
                </Stack>
                {/* ↑ VesselInformation ↑ */}


              </Box>
              :
              <Box>
              </Box>
            }

          </React.Fragment >

        </Drawer >

        {/* メイン部分 */}
        <Main                 sx={{
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    width: "6px",
                    height: "6px",
                  },
                  '&::-webkit-scrollbar-track': {
                    borderRadius: "10px",
                    background: "rgba(255, 255, 255, 0.5)",
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: "#fff",
                    borderRadius: "10px",
                  },
                  '&::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: '#555',
                  },
                  "&::-webkit-scrollbar-corner": {
                    display: "none",
                  }
                }}>

          {/* ↓ OpenClose ↓ */}
          <Stack
            sx={{
              position: "absolute", top: "64px", left: `${openDrwer ? "345px" : "0px"}`, width: '10px', height: '20px',
              background: 'rgba(255, 255, 255, 0.25)',
              cursor: "pointer",
              transition: "0.5s",
            }}
            onClick={openDrwer ? handleDrawerClose : handleDrawerOpen}>
            <Box sx={{ position: "absolute", top: "7px", left: "2px", width: "6px", height: "6px", borderTop: "solid 2px #01103B", borderRight: "solid 2px #01103B", transform: `${openDrwer ? "rotate(225deg)}" : "rotate(45deg)}"}` }}></Box>
          </Stack>
          {/* ↑ OpenClose ↑ */}

          <Grid container spacing={2}>

            {/* ↓ 地図エリア ↓ */}
            <Grid item xs={12}>
              <Stack
                sx={{
                  width: "flex",
                  background: 'linear-gradient(180deg, rgba(45.82, 46.41, 47.81, 0.20) 0%, rgba(65.37, 66.21, 68.21, 0.20) 20%)',
                }}>

                {/* ↓ タイトル ↓ */}
                <Box sx={{ position: "relative", height: "40px" }}>
                  <Typography sx={{ position: "absolute", top: "10px", left: "10px", color: 'white', fontSize: "14px", fontFamily: "'Roboto Bold'", fontWeight: 'bold', wordWrap: 'break-word' }}>
                    Route & Weather Conditions
                  </Typography>
                  {/* ↑ タイトル ↑ */}
                </Box>

                {/* メイン */}
                <Box className='routeWetherCond'>
                  <Grid container direction={"row"}>
                    {/* ↓ 地図本体 ↓ */}
                    <Box className='map'>
                      {success ?
                        <Box sx={{ marginTop: "0px", marginBottom: "0px", marginLeft: "16px", marginRight: "32px", width: "97%", height: "484px", bgcolor: "orange" }}>
                          <MapContainer
                            center={CENTER}
                            zoom={4}
                            style={{
                              height: "100%",
                              width: "100%",
                              margin: 0,
                            }}
                            maxZoom={19}
                            minZoom={1.0}
                            scrollWheelZoom={true}
                            attributionControl={false}
                            maxBounds={[[90, -Infinity], [-90, Infinity]]}
                            worldCopyJump={true}
                            preferCanvas={true}
                          >
                            <TileLayer
                              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            {polyline.length !== 0 &&
                              <Polyline positions={polyline} color="white" weight={1} />
                            }
                            {polyline_360Plus.length !== 0 &&
                              <Polyline positions={polyline_360Plus} color="white" weight={1} />
                            }
                            {polyline_360Minus.length !== 0 &&
                              <Polyline positions={polyline_360Minus} color="white" weight={1} />
                            }
                            <ChangeMapCenter position={CENTER} />
                            {LATLNGTimeSereis.map((x, i) => (
                              <React.Fragment key={i}>
                                {(x.latlng[0] != null && x.latlng[0] != null) &&
                                  <>
                                    <CustomMarker plot={i} index={clickItemMap.index} position={[x.latlng[0], x.latlng[1]]} rotationAngle={x.course} onClickMap={onClickMap} timestamp={x.timestamp} />
                                    <CustomMarker plot={i} index={clickItemMap.index} position={[x.latlng[0], x.latlng[1] + 360]} rotationAngle={x.course} onClickMap={onClickMap} timestamp={x.timestamp} />
                                    <CustomMarker plot={i} index={clickItemMap.index} position={[x.latlng[0], x.latlng[1] - 360]} rotationAngle={x.course} onClickMap={onClickMap} timestamp={x.timestamp} />
                                    {x.beaufort &&
                                      <CustomBeaufortMarker plot={i} index={clickItemMap.index} position={[x.latlng[0], x.latlng[1]]} rotationAngle={parseFloat(x.wind_direction) + 180} onClickMap={onClickMap} timestamp={x.timestamp}
                                      beaufortNumber ={formatNumber(x.beaufort)}
                                      />
                                    }
                                    {
                                      i === clickItemMap.index ?
                                        <LayerGroup>
                                          {/* <Box sx={{height:"50px",width:"50px",bgcolor:"white"}}></Box> */}
                                          <CircleMarker
                                            center={[x.latlng[0], x.latlng[1]]}
                                            pathOptions={{
                                              color: "rgba(37.01, 70.85, 80.75, 0.5)",
                                              // fillColor: 'radial-gradient(rgba(255, 255, 255, 0), rgba(7.21, 29.76, 78.63, 0.28))',
                                              fillColor: "rgba(37.01, 70.85, 80.75, 0.7)",
                                              weight: 0.5,
                                              // fillOpacity: 0.7,
                                              lineCap: "butt",
                                              lineJoin: "bevel",
                                              // dashArray: "5 1",
                                              // dashOffset: "1",
                                            }}
                                            radius={48}
                                          />
                                        </LayerGroup>
                                        :
                                        <></>
                                    }
                                  </>
                                }
                              </React.Fragment>
                            ))}
                          </MapContainer>
                        </Box>
                        :
                        <Box>
                          {loading && (
                            <CircularProgress
                              size={68}
                              sx={{
                                color: "grey",
                                position: 'absolute',
                                top: 200,
                                left: 400,
                                zIndex: 1,
                              }}
                            />
                          )}
                        </Box>
                      }
                    </Box>
                    {/* ↑ 地図本体 ↑ */}


                    {/* ↓ Point Information ↓ */}
                    <Box className='pointInformation'>
                      <Box>
                        <Stack sx={{ height: "480px", width: "97%", background: 'rgba(37.01, 70.85, 80.75, 0.13)', boxShadow: '3px 3px 1px rgba(3.21, 11.28, 23.38, 0.30)', borderRadius: "10px", border: '0.25px #254751 solid' }}>

                          {/* Title */}
                          <Stack
                            sx={{
                              height: "30px",
                              marginBottom: "0px",
                              borderRadius: "5px"
                            }} >
                            <Grid container direction="row" >
                              <Grid item xs={12}>
                                <Stack direction='column' sx={{ marginLeft: "8px", height: "30px" }} alignItems="flex-start" justifyContent="center">
                                  <Typography sx={{ color: 'rgba(255, 255, 255, 1)', fontSize: "13px", fontFamily: 'Roboto', fontWeight: 700, wordWrap: 'break-word' }} >
                                    ＜ Point Information ＞
                                  </Typography>
                                </Stack>
                              </Grid>
                            </Grid>
                          </Stack>
                          {
                            success ?
                              <>
                                < Grid container direction={"row"}>
                                  {/* ↓ Voyage ↓ */}
                                  < Grid item xs={6.5}>

                                    {/* utc date */}
                                    <Stack
                                      sx={{
                                        height: "30px",
                                        marginLeft: "16px",
                                        marginRight: "8px",
                                        marginBottom: "8px",
                                        // background:"linear-gradient(352deg, rgba(255, 255, 255, 0.01) 1.98%, rgba(255, 255, 255, 0.03) 89.82%)", 
                                        borderRadius: "5px"
                                      }} >
                                      <Grid container direction="row" >
                                        <Grid item xs={1}>
                                          <Stack direction='column' sx={{ marginLeft: "8px", height: "30px" }} alignItems="center" justifyContent="center">
                                            <Box sx={{ color: 'white' }}>
                                              <img src={DateIcon_svg} alt="iconLike" style={{ height: "20px", width: "20px" }} />
                                            </Box>
                                          </Stack>
                                        </Grid>
                                        <Grid item xs={11}>
                                          <Stack direction='column' sx={{ marginLeft: "16px", height: "30px" }} alignItems="flex-start" justifyContent="center">
                                            <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)', fontSize: "14px", fontFamily: "'Roboto Regular'", wordWrap: 'break-word' }} >
                                              {plotInformation.utc_date === "" ? "" : plotInformation.utc_date}
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                    </Stack>

                                    {/* Emission */}
                                    <Stack
                                      sx={{
                                        height: "120px",
                                        width: "312px",
                                        marginTop: "8px",
                                        marginLeft: "16px",
                                        marginRight: "8px",
                                        marginBottom: "8px",
                                        background: "linear-gradient(352deg, rgba(255, 255, 255, 0.01) 1.98%, rgba(255, 255, 255, 0.03) 89.82%)",
                                        borderRadius: "5px"
                                      }} >
                                      <Grid container direction="row" >
                                        <Grid item xs={1}>
                                          <Stack direction='column' sx={{ marginLeft: "8px", height: "120px" }} alignItems="center" justifyContent="center">
                                            <Box sx={{ color: 'white' }}>
                                              <img src={OilIcon_svg} alt="iconLike" style={{ height: "20px", width: "20px" }} />
                                            </Box>
                                          </Stack>
                                        </Grid>
                                        <Grid item xs={5}>
                                          <Stack direction='column' sx={{ marginLeft: "16px", height: "120px" }} alignItems="flex-start" justifyContent="center">
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 13, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                                              FOC
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 13, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                                              M/E FOC
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 13, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                                              CO2
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Stack direction='column' sx={{ marginLeft: "8px", height: "120px" }} alignItems="flex-end" justifyContent="center">
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 1)', fontSize: "15px", fontFamily: "'Roboto Regular'", wordWrap: 'break-word' }}>
                                              {/* {plotInformation.foc} */}
                                              {plotInformation.foc === "" ? "-" : formatNumber(plotInformation.foc)}
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 1)', fontSize: "15px", fontFamily: "'Roboto Regular'", wordWrap: 'break-word' }}>
                                              {/* {plotInformation.me_foc} */}
                                              {plotInformation.me_foc === "" ? "-" : formatNumber(plotInformation.me_foc)}
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 1)', fontSize: "15px", fontFamily: "'Roboto Regular'", wordWrap: 'break-word' }}>
                                              {/* {plotInformation.co2} */}
                                              {plotInformation.co2 === "" ? "-" : formatNumber(plotInformation.co2)}
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Stack direction='column' sx={{ marginLeft: "8px", height: "120px" }} alignItems="flex-start" justifyContent="center">
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.5)', fontSize: "14px", fontFamily: "'Roboto Condensed Regular'", wordWrap: 'break-word' }}>
                                              MT/day
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.5)', fontSize: "14px", fontFamily: "'Roboto Condensed Regular'", wordWrap: 'break-word' }}>
                                              MT/day
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.5)', fontSize: "14px", fontFamily: "'Roboto Condensed Regular'", wordWrap: 'break-word' }}>
                                              MT/day
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                    </Stack>

                                    {/* M/E */}
                                    <Stack
                                      sx={{
                                        height: "120px",
                                        width: "312px",
                                        marginTop: "8px",
                                        marginLeft: "16px",
                                        marginRight: "8px",
                                        marginBottom: "8px",
                                        background: "linear-gradient(352deg, rgba(255, 255, 255, 0.01) 1.98%, rgba(255, 255, 255, 0.03) 89.82%)",
                                        borderRadius: "5px"
                                      }} >
                                      <Grid container direction="row" >
                                        <Grid item xs={1}>
                                          <Stack direction='column' sx={{ marginLeft: "8px", height: "120px" }} alignItems="center" justifyContent="center">
                                            <Box sx={{ color: 'white' }}>
                                              <img src={EngineIcon_svg} alt="iconLike" style={{ height: "20px", width: "20px" }} />
                                            </Box>
                                          </Stack>
                                        </Grid>
                                        <Grid item xs={5}>
                                          <Stack direction='column' sx={{ marginLeft: "16px", height: "120px" }} alignItems="flex-start" justifyContent="center">
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 13, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                                              Avg. LOG Speed
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 13, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                                              Avg. M/E Load
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 13, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                                              Avg. M/E RPM
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Stack direction='column' sx={{ marginLeft: "8px", height: "120px" }} alignItems="flex-end" justifyContent="center">
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 1)', fontSize: "15px", fontFamily: "'Roboto Regular'", wordWrap: 'break-word' }}>
                                              {/* {plotInformation.log_speed} */}
                                              {plotInformation.log_speed === "" ? "-" : formatNumber(plotInformation.log_speed)}
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 1)', fontSize: "15px", fontFamily: "'Roboto Regular'", wordWrap: 'break-word' }}>
                                              {/* {plotInformation.me_load} */}
                                              {plotInformation.me_load === "" ? "-" : formatNumber(plotInformation.me_load)}
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 1)', fontSize: "15px", fontFamily: "'Roboto Regular'", wordWrap: 'break-word' }}>
                                              {/* {plotInformation.me_rpm} */}
                                              {plotInformation.me_rpm === "" ? "-" : formatNumber(plotInformation.me_rpm)}
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Stack direction='column' sx={{ marginLeft: "8px", height: "120px" }} alignItems="flex-start" justifyContent="center">
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.5)', fontSize: "14px", fontFamily: "'Roboto Condensed Regular'", wordWrap: 'break-word' }}>
                                              knot
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.5)', fontSize: "14px", fontFamily: "'Roboto Condensed Regular'", wordWrap: 'break-word' }}>
                                              %
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.5)', fontSize: "14px", fontFamily: "'Roboto Condensed Regular'", wordWrap: 'break-word' }}>
                                              rpm
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                    </Stack>

                                    {/* Distance, Displacement */}
                                    <Stack
                                      sx={{
                                        height: "100px",
                                        width: "312px",
                                        marginTop: "8px",
                                        marginLeft: "16px",
                                        marginRight: "8px",
                                        marginBottom: "8px",
                                        background: "linear-gradient(352deg, rgba(255, 255, 255, 0.01) 1.98%, rgba(255, 255, 255, 0.03) 89.82%)",
                                        borderRadius: "5px"
                                      }} >
                                      <Grid container direction="row" >
                                        <Grid item xs={1}>
                                          <Stack direction='column' sx={{ marginLeft: "8px", height: "100px" }} alignItems="center" justifyContent="center">
                                            <Box sx={{ color: 'white' }}>
                                              <img src={ShipIcon_svg} alt="iconLike" style={{ height: "25px", width: "25px" }} />
                                            </Box>
                                          </Stack>
                                        </Grid>
                                        <Grid item xs={5}>
                                          <Stack direction='column' sx={{ marginLeft: "16px", height: "100px" }} alignItems="flex-start" justifyContent="center">
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 13, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                                              Distance
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 13, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                                              Displacement
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Stack direction='column' sx={{ marginLeft: 1, height: "100px" }} alignItems="flex-end" justifyContent="center">
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 1)', fontSize: "15px", fontFamily: "'Roboto Regular'", wordWrap: 'break-word' }}>
                                              {/* {plotInformation.distance} */}
                                              {plotInformation.distance === "" ? "-" : formatNumber(plotInformation.distance)}
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 1)', fontSize: "15px", fontFamily: "'Roboto Regular'", wordWrap: 'break-word' }}>
                                              {/* {plotInformation.displacement} */}
                                              {plotInformation.displacement === "" ? "-" : formatNumber(plotInformation.displacement)}
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Stack direction='column' sx={{ marginLeft: 1, height: "100px" }} alignItems="flex-start" justifyContent="center">
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.5)', fontSize: "14px", fontFamily: "'Roboto Condensed Regular'", wordWrap: 'break-word' }}>
                                              NM/day
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.5)', fontSize: "14px", fontFamily: "'Roboto Condensed Regular'", wordWrap: 'break-word' }}>
                                              ton
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                    </Stack>
                                  </Grid>
                                  {/* ↑ Voyage ↑ */}

                                  {/* ↓ Weather ↓ */}
                                  <Grid item xs={5.5}>
                                    {/* utc_dateの間隔を空ける */}
                                    <Stack direction='row' alignItems="center" justifyContent="flex-start" sx={{ height: "30px", marginBottom: "0px" }} ></Stack>

                                    {/* Wind */}
                                    <Stack
                                      sx={{
                                        height: "100px",
                                        width: "252px",
                                        marginLeft: "16px",
                                        marginRight: "16px",
                                        marginTop: "8px",
                                        marginBottom: "8px",
                                        background: "linear-gradient(352deg, rgba(255, 255, 255, 0.01) 1.98%, rgba(255, 255, 255, 0.03) 89.82%)",
                                        borderRadius: "5px"
                                      }} >
                                      <Grid container direction="row" >
                                        <Grid item xs={3}>
                                          <Stack direction='column' sx={{ marginLeft: "8px", height: "100px", position: 'relative', opacity: 0.60 }} >
                                            <AirIcon
                                              sx={{
                                                position: 'absolute',
                                                color: 'white',
                                                top: "30px",
                                                opacity: 0.7,
                                              }} />
                                            <Typography sx={{
                                              position: 'absolute',
                                              color: 'rgba(255, 255, 255, 0.50)',
                                              fontSize: 13,
                                              fontFamily: 'Roboto',
                                              fontWeight: '700',
                                              wordWrap: 'break-word',
                                              top: "55px",
                                            }}>
                                              Wind
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Stack direction='column' sx={{ marginLeft: "16px", height: "100px", position: 'relative', opacity: 0.60 }} alignItems="flex-start" justifyContent="center">
                                            {/* 船アイコン */}
                                            <Box
                                              sx={{
                                                position: 'absolute',
                                                color: 'white',
                                                top: "30px",
                                                transform: "rotate(" + plotInformation.course + "deg)",
                                              }}>
                                              <img src={CourseIcon} alt="logo" className='course-icon' />
                                            </Box>
                                            {/* 風向アイコン */}
                                            <Box
                                              sx={{
                                                position: 'absolute',
                                                width: "0 !important",
                                                height: "0 !important",
                                                borderWidth: "0 3.5px 3px 3.5px",
                                                borderStyle: "solid",
                                                borderColor: "transparent transparent " + plotInformation.wind_directionColor + " transparent",
                                                left: plotInformation.wind_directionCalcLeft + "px",
                                                top: plotInformation.wind_directionCalcTop + "px",
                                                transform: "rotate(" + plotInformation.wind_directionCalcDeg + "deg)",
                                              }}
                                            >
                                            </Box>

                                          </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Stack direction='column' sx={{ marginLeft: "8px", height: "100px" }} alignItems="flex-end" justifyContent="center">
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 1)', fontSize: "15px", fontFamily: "'Roboto Regular'", wordWrap: 'break-word' }}>
                                              {/* {plotInformation.wind_speed} */}
                                              {plotInformation.wind_speed === "" ? "-" : formatNumber(plotInformation.wind_speed)}
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 1)', fontSize: "15px", fontFamily: "'Roboto Regular'", wordWrap: 'break-word' }}>
                                              {plotInformation.beaufort === "" ? "-" : formatNumber(plotInformation.beaufort)}
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Stack direction='column' sx={{ marginLeft: "8px", height: "100px" }} alignItems="flex-start" justifyContent="center">
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.5)', fontSize: "14px", fontFamily: "'Roboto Condensed Regular'", wordWrap: 'break-word' }}>
                                              m/s
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.5)', fontSize: "14px", fontFamily: "'Roboto Condensed Regular'", wordWrap: 'break-word' }}>
                                              BF
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                    </Stack>

                                    {/* Wave */}
                                    <Stack
                                      sx={{
                                        height: "100px",
                                        width: "252px",
                                        marginLeft: "16px",
                                        marginRight: "16px",
                                        marginTop: "8px",
                                        marginBottom: "8px",
                                        background: "linear-gradient(352deg, rgba(255, 255, 255, 0.01) 1.98%, rgba(255, 255, 255, 0.03) 89.82%)",
                                        borderRadius: "5px"
                                      }} >
                                      <Grid container direction="row" >
                                        <Grid item xs={3}>
                                          <Stack direction='column' sx={{ marginLeft: "8px", height: "100px", position: 'relative', opacity: 0.60 }} >
                                            <TsunamiIcon
                                              sx={{
                                                position: 'absolute',
                                                color: 'white',
                                                top: "30px",
                                                opacity: 0.7,
                                              }} />
                                            <Typography sx={{
                                              position: 'absolute',
                                              color: 'rgba(255, 255, 255, 0.50)',
                                              fontSize: 13,
                                              fontFamily: 'Roboto',
                                              fontWeight: '700',
                                              wordWrap: 'break-word',
                                              top: "55px",
                                            }}>
                                              Wave
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Stack direction='column' sx={{ marginLeft: "16px", height: "100px", position: 'relative', opacity: 0.60 }} alignItems="flex-start" justifyContent="center">
                                            <Box
                                              sx={{
                                                position: 'absolute',
                                                color: 'white',
                                                top: "30px",
                                                transform: "rotate(" + plotInformation.course + "deg)",
                                              }}>
                                              <img src={CourseIcon} alt="logo" className='course-icon' />
                                            </Box>
                                            <Box
                                              sx={{
                                                position: 'absolute',
                                                width: "0 !important",
                                                height: "0 !important",
                                                borderWidth: "0 3.5px 3px 3.5px",
                                                borderStyle: "solid",
                                                borderColor: "transparent transparent " + plotInformation.wave_directionColor + " transparent",
                                                left: plotInformation.wave_directionCalcLeft + "px",
                                                top: plotInformation.wave_directionCalcTop + "px",
                                                transform: "rotate(" + plotInformation.wave_directionCalcDeg + "deg)",
                                              }}
                                            >
                                            </Box>
                                          </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Stack direction='column' sx={{ marginLeft: "8px", height: "100px" }} alignItems="flex-end" justifyContent="center">
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 1)', fontSize: "15px", fontFamily: "'Roboto Regular'", wordWrap: 'break-word' }}>
                                              {/* {plotInformation.wave_height} */}
                                              {plotInformation.wave_height === "" ? "-" : formatNumber(plotInformation.wave_height)}
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 1)', fontSize: "15px", fontFamily: "'Roboto Regular'", wordWrap: 'break-word' }}>
                                              {/* {plotInformation.wave_period} */}
                                              {plotInformation.wave_period === "" ? "-" : formatNumber(plotInformation.wave_period)}
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Stack direction='column' sx={{ marginLeft: "8px", height: "100px" }} alignItems="flex-start" justifyContent="center">
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.5)', fontSize: "14px", fontFamily: "'Roboto Condensed Regular'", wordWrap: 'break-word' }}>
                                              m
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.5)', fontSize: "14px", fontFamily: "'Roboto Condensed Regular'", wordWrap: 'break-word' }}>
                                              s
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                    </Stack>

                                    {/* Swell */}
                                    <Stack
                                      sx={{
                                        height: "100px",
                                        width: "252px",
                                        marginLeft: "16px",
                                        marginRight: "16px",
                                        marginTop: "8px",
                                        marginBottom: "8px",
                                        background: "linear-gradient(352deg, rgba(255, 255, 255, 0.01) 1.98%, rgba(255, 255, 255, 0.03) 89.82%)",
                                        borderRadius: "5px"
                                      }} >
                                      <Grid container direction="row" >
                                        <Grid item xs={3}>
                                          <Stack direction='column' sx={{ marginLeft: "8px", height: "100px", position: 'relative', opacity: 0.60 }} >
                                            <WavesIcon
                                              sx={{
                                                position: 'absolute',
                                                color: 'white',
                                                top: "30px",
                                                opacity: 0.7,
                                              }} />
                                            <Typography sx={{
                                              position: 'absolute',
                                              color: 'rgba(255, 255, 255, 0.50)',
                                              fontSize: 13,
                                              fontFamily: 'Roboto',
                                              fontWeight: '700',
                                              wordWrap: 'break-word',
                                              top: "55px",
                                            }}>
                                              Swell
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Stack direction='column' sx={{ marginLeft: "16px", height: "100px", position: 'relative', opacity: 0.60 }} alignItems="flex-start" justifyContent="center">
                                            <Box
                                              sx={{
                                                position: 'absolute',
                                                color: 'white',
                                                top: "30px",
                                                transform: "rotate(" + plotInformation.course + "deg)",
                                              }}>
                                              <img src={CourseIcon} alt="logo" className='course-icon' />
                                            </Box>
                                            <Box
                                              sx={{
                                                position: 'absolute',
                                                width: "0 !important",
                                                height: "0 !important",
                                                borderWidth: "0 3.5px 3px 3.5px",
                                                borderStyle: "solid",
                                                borderColor: "transparent transparent " + plotInformation.swell_directionColor + " transparent",
                                                left: plotInformation.swell_directionCalcLeft + "px",
                                                top: plotInformation.swell_directionCalcTop + "px",
                                                transform: "rotate(" + plotInformation.swell_directionCalcDeg + "deg)",
                                              }}
                                            >
                                            </Box>
                                          </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Stack direction='column' sx={{ marginLeft: "8px", height: "100px" }} alignItems="flex-end" justifyContent="center">
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 1)', fontSize: "15px", fontFamily: "'Roboto Regular'", wordWrap: 'break-word' }}>
                                              {/* {plotInformation.swell_height} */}
                                              {plotInformation.swell_height === "" ? "-" : formatNumber(plotInformation.swell_height)}
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 1)', fontSize: "15px", fontFamily: "'Roboto Regular'", wordWrap: 'break-word' }}>
                                              {/* {plotInformation.swell_period} */}
                                              {plotInformation.swell_period === "" ? "-" : formatNumber(plotInformation.swell_period)}
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Stack direction='column' sx={{ marginLeft: "8px", height: "100px" }} alignItems="flex-start" justifyContent="center">
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.5)', fontSize: "14px", fontFamily: "'Roboto Condensed Regular'", wordWrap: 'break-word' }}>
                                              m
                                            </Typography>
                                            <Typography sx={{ marginTop: "4px", marginBottom: "4px", color: 'rgba(255, 255, 255, 0.5)', fontSize: "14px", fontFamily: "'Roboto Condensed Regular'", wordWrap: 'break-word' }}>
                                              s
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                    </Stack>
                                  </Grid>
                                  {/* ↑ Weather ↑ */}
                                </Grid>
                              </>
                              :
                              <Box>
                                {loading && (
                                  <CircularProgress
                                    size={68}
                                    sx={{
                                      color: "grey",
                                      position: 'absolute',
                                      top: 200,
                                      left: 1200,
                                      zIndex: 1,
                                    }}
                                  />
                                )}
                              </Box>
                          }
                        </Stack>
                        {/* ↓ Point Information ↓ */}
                      </Box>
                    </Box>


                  </Grid>
                </Box>
                {/* ↑ メイン ↑ */}

              </Stack>
            </Grid>
            {/* ↑ 地図エリア ↑ */}


            {/* ↓ TimeSeriesエリア ↓ */}
            <Grid item xs={12}>
              <Stack
                sx={{
                  width: "flex",
                  background: 'linear-gradient(180deg, rgba(45.82, 46.41, 47.81, 0.20) 0%, rgba(65.37, 66.21, 68.21, 0.20) 20%)',
                }}>

                {/* ↓ タイトル ↓ */}
                <Box sx={{ position: "relative", height: "40px" }}>
                  <Typography sx={{ position: "absolute", top: "10px", left: "10px", color: 'white', fontSize: "14px", fontFamily: "'Roboto Bold'", fontWeight: 'bold', wordWrap: 'break-word' }}>
                    Time Series
                  </Typography>
                  {/* ↑ タイトル ↑ */}
                </Box>

                {/* ↓ メイン部分 ↓ */}
                <Box className='timeSeries'>

                  {success ?
                    <>
                      <Grid container direction={"row"} sx={{ height: "270px" }}>
                        {/* ↓ Highchartsグラフ ↓ */}
                        <Box className='graph'>
                          <Box sx={{ position: "relative" }}>
                            <Box sx={{ marginLeft: "24px", marginTop: "16px", width: "95%" }}>
                              <HighchartsReact
                                key={key}
                                highcharts={Highcharts}
                                options={hcOptions}
                                ref={chartComponentRef}
                                allowChartUpdate={true}
                              />
                            </Box>

                            {/* Y軸設定ダイアログ */}
                            <Box
                              sx={{
                                position: "absolute",
                                top: "-16px",
                                left: "8px",
                                width: "20px",
                                height: "20px",
                                background: "rgb(255,255,255,0.3)",
                                cursor: "pointer",
                                borderRadius: "50%",
                                border: "2px solid rgb(255,255,255,0.7)",
                                zIndex: 10,

                              }}
                              onClick={handleClickOpenDialogAxis}
                            >
                              <SettingsIcon sx={{ position: "absolute", top: "1px", left: "1px", width: "14px", height: "14px", color: "rgb(255,255,255,0.7)" }} />
                            </Box>
                          </Box>
                        </Box>
                        {/* ↑ Highchartsグラフ ↑ */}

                        {/* ↓ 凡例 ↓ */}
                        <Box className='legend'>
                          <Box sx={{ position: "relative", top: "10px", left: "0px" }}>
                            <Stack direction='row' spacing={0} sx={{ marginRight: "16px", marginLeft: "0px", marginTop: "18px", opacity: 0.80, background: 'linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%)' }}>
                              <Grid container alignItems={"center"} justifyContent={"flex-end"}>
                                <Grid item xs={2}>
                                  <Box sx={{ position: "relative" }}>
                                    <AntSwitch onChange={handleChange} checked={Toggle_me_load.checked} name={Toggle_me_load.name} color={Toggle_me_load.color}></AntSwitch>
                                  </Box>
                                </Grid>
                                <Grid item xs={4}>
                                  <Typography align="left" sx={{ color: 'white', width: "100%", opacity: 0.70, fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                    M/E Load
                                  </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography align="right" sx={{ color: 'white', width: "100%", opacity: 0.70, fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                    {plotInformation.me_load === "" ? "-" : plotInformation.me_load}
                                  </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography align="left" sx={{ marginLeft: "5px", color: 'white', width: "100%", opacity: 0.70, fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                    %
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Stack>

                            <Stack direction='row' spacing={0} sx={{ marginRight: "16px", marginLeft: "0px", marginTop: "8px", opacity: 0.80 }}>
                              <Grid container alignItems={"center"} justifyContent={"flex-end"}>
                                <Grid item xs={2}>
                                  <Box sx={{ position: "relative" }}>
                                    <AntSwitch onChange={handleChange} checked={Toggle_me_rpm.checked} name={Toggle_me_rpm.name} color={Toggle_me_rpm.color}></AntSwitch>
                                  </Box>
                                </Grid>
                                <Grid item xs={4}>
                                  <Typography align="left" sx={{ color: 'white', width: "100%", opacity: 0.70, fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                    M/E RPM
                                  </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography align="right" sx={{ color: 'white', width: "100%", opacity: 0.70, fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                    {plotInformation.me_rpm === "" ? "-" : plotInformation.me_rpm}
                                  </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography align="left" sx={{ marginLeft: "5px", color: 'white', width: "100%", opacity: 0.70, fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                    rpm
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Stack>

                            <Stack direction='row' spacing={0} sx={{ marginRight: "16px", marginLeft: "0px", marginTop: "8px", opacity: 0.80, background: 'linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%)' }}>
                              <Grid container alignItems={"center"} justifyContent={"flex-end"}>
                                <Grid item xs={2}>
                                  <Box sx={{ position: "relative" }}>
                                    <AntSwitch onChange={handleChange} checked={Toggle_foc.checked} name={Toggle_foc.name} color={Toggle_foc.color}></AntSwitch>
                                  </Box>
                                </Grid>
                                <Grid item xs={4}>
                                  <Typography align="left" sx={{ color: 'white', width: "100%", opacity: 0.70, fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                    FOC
                                  </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography align="right" sx={{ color: 'white', width: "100%", opacity: 0.70, fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                    {plotInformation.foc === "" ? "-" : plotInformation.foc}
                                  </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography align="left" sx={{ marginLeft: "5px", color: 'white', width: "100%", opacity: 0.70, fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                    MT/day
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Stack>

                            <Stack direction='row' spacing={0} sx={{ marginRight: "16px", marginLeft: "0px", marginTop: "8px", opacity: 0.80 }}>
                              <Grid container alignItems={"center"} justifyContent={"flex-end"}>
                                <Grid item xs={2}>
                                  <Box sx={{ position: "relative" }}>
                                    <AntSwitch onChange={handleChange} checked={Toggle_log_speed.checked} name={Toggle_log_speed.name} color={Toggle_log_speed.color}></AntSwitch>
                                  </Box>
                                </Grid>
                                <Grid item xs={4}>
                                  <Typography align="left" sx={{ color: 'white', width: "100%", opacity: 0.70, fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                    LOG Speed
                                  </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography align="right" sx={{ color: 'white', width: "100%", opacity: 0.70, fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                    {plotInformation.log_speed === "" ? "-" : plotInformation.log_speed}
                                  </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography align="left" sx={{ marginLeft: "5px", color: 'white', width: "100%", opacity: 0.70, fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                    knot
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Stack>

                            <Stack direction='row' sx={{ marginRight: "16px", marginLeft: "0px", marginTop: "80px" }}>
                              <Grid container alignItems={"center"} justifyContent={"flex-end"}>
                                <Grid item xs={12}>
                                  <Typography align="right" sx={{ color: 'white', width: "100%", opacity: 0.70, fontSize: "12px", fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word' }}>
                                    Grey areas are IN HARBOR or IN PORT.
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Stack>
                          </Box>
                        </Box>
                        {/* ↑ 凡例 ↑ */}

                      </Grid>


                      <Dialog
                        open={openDialogyAxis}
                        sx={{
                          "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                              width: "100%",
                              maxWidth: "500px",
                              height: "100%",
                              maxHeight: "310px",
                            },
                          },
                        }}
                      >
                        <DialogTitle
                          sx={{
                            bgcolor: "#474C53",
                            height: "50px",
                            color: "white",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}>
                          Axis Setting
                          <IconButton
                            aria-label="close"
                            onClick={handleNoClickOpenDialogAxis}
                            sx={{
                              position: 'absolute',
                              right: 8,
                              top: 8,
                              color: (theme) => theme.palette.grey[1000],
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </DialogTitle>
                        <DialogContent
                          sx={{
                            background: "linear-gradient(180deg, #0E1B25 0%, #17232D 100%)",
                          }}>

                          <Box sx={{ position: "relative" }}>
                            <Box sx={{ position: "absolute", top: "60px", left: "20px", height: "1px", width: "400px", borderRadius: '0px ', border: "1px dotted #A4A7B1" }}></Box>
                            <Typography align="center"
                              sx={{
                                position: "absolute", top: "30px", left: "40px",
                                color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word'
                              }}>
                              Channel Name
                            </Typography>
                            <Typography align="center"
                              sx={{
                                position: "absolute", top: "30px", left: "240px",
                                color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word'
                              }}>
                              Min
                            </Typography>
                            <Typography align="center"
                              sx={{
                                position: "absolute", top: "30px", left: "350px",
                                color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word'
                              }}>
                              Max
                            </Typography>
                          </Box>
                          <Box sx={{ position: "relative" }}>
                            <Stack direction={"row"}>
                              <Typography align="left"
                                sx={{
                                  position: "absolute", top: "90px", left: "70px",
                                  color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word'
                                }}>
                                (knot)
                              </Typography>
                              <FormControl>
                                <Input
                                  value={yAxisMinShow_log_speed} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleClickyAxisMinShow_log_speed(event.target.value) }}
                                  type='tel'
                                  sx={{
                                    position: "absolute", top: "80px", left: "200px", width: "100px", fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                    "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                    ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                    ":hover:after": { borderBottom: "0px solid green", },
                                    ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                    ":after": { borderBottom: "2px solid white", },
                                    ":before": { borderBottom: "2px solid #A4A7B1", },
                                  }} />
                              </FormControl>
                              <Box sx={{ position: "absolute", top: "100px", left: "300px", height: "1px", width: "20px", borderRadius: '0px ', border: "1px solid #A4A7B1" }}></Box>
                              <FormControl>
                                <Input
                                  value={yAxisMaxShow_log_speed} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleClickyAxisMaxShow_log_speed(event.target.value) }}
                                  type='tel'
                                  sx={{
                                    position: "absolute", top: "80px", left: "320px", width: "100px", fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                    "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                    ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                    ":hover:after": { borderBottom: "0px solid green", },
                                    ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                    ":after": { borderBottom: "2px solid white", },
                                    ":before": { borderBottom: "2px solid #A4A7B1", },
                                  }} />
                              </FormControl>
                            </Stack>
                          </Box>
                          <Box sx={{ position: "relative" }}>
                            <Stack direction={"row"}>
                              <Typography align="left"
                                sx={{
                                  position: "absolute", top: "150px", left: "70px",
                                  color: 'rgba(255, 255, 255, 0.70)', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', lineHeight: "24px", wordWrap: 'break-word'
                                }}>
                                others
                              </Typography>
                              <FormControl>
                                <Input
                                  value={yAxisMinShow} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleClickyAxisMinShow(event.target.value) }}
                                  type='tel'
                                  sx={{
                                    position: "absolute", top: "140px", left: "200px", width: "100px", fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                    "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                    ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                    ":hover:after": { borderBottom: "0px solid green", },
                                    ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                    ":after": { borderBottom: "2px solid white", },
                                    ":before": { borderBottom: "2px solid #A4A7B1", },
                                  }} />
                              </FormControl>
                              <Box sx={{ position: "absolute", top: "160px", left: "300px", height: "1px", width: "20px", borderRadius: '0px ', border: "1px solid #A4A7B1" }}></Box>
                              <FormControl>
                                <Input
                                  value={yAxisMaxShow} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleClickyAxisMaxShow(event.target.value) }}
                                  type='tel'
                                  sx={{
                                    position: "absolute", top: "140px", left: "320px", width: "100px", fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word', border: '0px grey solid', color: "white", fontSize: "15px",
                                    "& .MuiInput-input": { height: "30px", borderRadius: '0px', border: '0px white solid', background: "rgba(255, 255, 255, 0.15)", textAlign: "center", },
                                    ":hover": { backgroundColor: "#7a86a0", border: '0px #CBCBCB solid', borderBottom: "0px solid white", },
                                    ":hover:after": { borderBottom: "0px solid green", },
                                    ":hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "2px solid white", },
                                    ":after": { borderBottom: "2px solid white", },
                                    ":before": { borderBottom: "2px solid #A4A7B1", },
                                  }} />
                              </FormControl>
                            </Stack>
                          </Box>
                        </DialogContent>
                        <DialogActions
                          sx={{
                            height: "50px",
                            bgcolor: "#474C53",
                          }}>
                          <Button
                            sx={{
                              marginLeft: "0px",
                              marginRight: "-8px",
                              backgroundColor: "rgba(255, 255, 255, 0.5)",
                              width: "127px",
                              height: "50px",
                              padding: "0",
                              fontSize: "18px",
                              fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
                              fontWeight: "bold",
                              color: "white",
                              borderRadius: "0px",
                              ":hover": {
                                bgcolor: "#FFFFFF",
                                color: "#40475D",
                              },
                              textTransform: 'none'
                            }}
                            onClick={handleNoClickOpenDialogAxis}
                          >
                            Cancel
                          </Button>
                          <Button
                            sx={{
                              marginLeft: "0px",
                              marginRight: "-8px",
                              backgroundColor: "#e66300",
                              width: "127px",
                              height: "50px",
                              padding: "0",
                              fontSize: "18px",
                              fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
                              fontWeight: "bold",
                              color: "white",
                              borderRadius: "0px",
                              ":hover": {
                                bgcolor: "#FFFFFF",
                                color: "#40475D",
                              },
                              textTransform: 'none'
                            }}
                            onClick={handleClickSetAxis}
                          >
                            Save
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </>
                    :
                    <Box sx={{ position: "relative"}}>
                      {loading && (
                        <CircularProgress
                          size={68}
                          sx={{
                            color: "grey",
                            position: 'absolute',
                            top: 100,
                            left: 400,
                            zIndex: 1,
                          }}
                        />
                      )}
                    </Box>
                  }
                </Box>
                {/* ↑ メイン部分 ↑ */}

              </Stack >
            </Grid >
            {/* ↑ TimeSeriesエリア ↑ */}

          </Grid >
        </Main >
      </Box >
    </>
  );
}