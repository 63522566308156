
import * as React from 'react';

import './SpeedConsumption.css'; // スタイルのために追加


// MUI @mui/material/
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';

// 親コンポーネントから持ち回る値
export type Props = {
  fromLogSpeedAF: string;
  toLogSpeedAF: string;
  fromFOCAF: string;
  toFOCAF: string;
  onBlurFlg: boolean;
  setFromLogSpeedAF: (value: string) => void;
  setToLogSpeedAF: (value: string) => void;
  setFromFOCAF: (value: string) => void;
  setToFOCAF: (value: string) => void;
  setOnBlurFlg: (value: boolean) => void;
};

// 除外データ範囲選択コンポーネント
export const FilterComponent: React.FC<Props> = props => {

  // Text Advanced Filter----------------------------------------------------------------------------------------------------------------------------
  const [fromLogSpeedAF, setFromLogSpeedAF] = React.useState<string>(props.fromLogSpeedAF);
  const [toLogSpeedAF, setToLogSpeedAF] = React.useState<string>(props.toLogSpeedAF);
  const [fromFOCAF, setFromFOCAF] = React.useState<string>(props.fromFOCAF);
  const [toFOCAF, setToFOCAF] = React.useState<string>(props.toFOCAF);
  
  const UpperLimitLogSpeedAF = (event) => {
    if (/^\d*$/.test(event)) {
      setToLogSpeedAF(event);
      props.setToLogSpeedAF(event);
    }
    else {
      alert("Please enter numbers in half-width characters.")
    }
    };
  const LowerLimitLogSpeedAF = (event) => {
    if (/^\d*$/.test(event)) {
      setFromLogSpeedAF(event);
      props.setFromLogSpeedAF(event);
    }
    else {
      alert("Please enter numbers in half-width characters.")
    }
  };
  const UpperLimitFOCAF = (event) => {
    if (/^\d*$/.test(event)) {
      setToFOCAF(event);
      props.setToFOCAF(event);
    }
    else {
      alert("Please enter numbers in half-width characters.")
    }
  };
  const LowerLimitFOCAF = (event) => {
    if (/^\d*$/.test(event)) {
      setFromFOCAF(event);
      props.setFromFOCAF(event);
    }
    else {
      alert("Please enter numbers in half-width characters.")
    }
  };

  // テキストボックスBlurイベント
  const onBlurLimitTextBox = () => {
    // テキストボックスBlurフラグオン
    props.setOnBlurFlg(true);
  };

  return(
    <>
      {/* Filter */}
      <Stack sx={{ position: "relative", margin: "5px 0px", left: "29px", width: "296px", height: "225px", backgroundColor: "rgba(81, 84, 90, 0.2)" }}>
        {/* Log Speed */}
        <Stack alignItems="center" sx={{ position: "relative", top: "12px", left: "0px", height: "100px" }}>
          <Typography align="left"
            sx={{
            position: "absolute",
            top: "0px",
            left: "13px",
            color: 'white',
            fontSize: "14px",
            fontFamily: "'Roboto Bold'",
            fontWeight: '700',
            lineHeight: "24px",
            wordWrap: 'break-word',
            }}>
            Log Speed
          </Typography>
          <FormControl
            sx={{
            position: "absolute",
            top: "30px",
            left: "13px",
            width: "100px"
            }}>
            <Input
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              LowerLimitLogSpeedAF(event.target.value);
            }}
            onBlur={onBlurLimitTextBox}
            placeholder="lower"
            value={fromLogSpeedAF}
            type='tel'
            sx={{
              fontFamily: 'Roboto',
              fontWeight: '400',
              wordWrap: 'break-word',
              border: '0px grey solid',
              color: "white",
              fontSize: "15px",
              "& .MuiInput-input": {
              height: "30px",
              borderRadius: '0px',
              border: '0px white solid',
              background: "rgba(255, 255, 255, 0.15)",
              textAlign: "center",
              },
              ":hover": {
              backgroundColor: "#7a86a0",
              border: '0px #CBCBCB solid',
              borderBottom: "0px solid white",
              },
              ":hover:after": {
              borderBottom: "0px solid green",
              },
              ":hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid white",
              },
              ":after": {
              borderBottom: "2px solid white",
              },
              ":before": {
              borderBottom: "2px solid #A4A7B1",
              },
            }}
            />
          </FormControl>
          <Typography align="left"
            sx={{
            position: "absolute",
            top: "55px",
            left: "115px",
            color: 'rgba(255, 255, 255, 0.30)',
            fontSize: 13,
            fontFamily: 'Roboto Condensed',
            fontWeight: '400',
            lineHeight: "24px",
            wordWrap: 'break-word'
            }}>
            knot
          </Typography>
          <Box sx={{
            position: "absolute",
            top: "50px",
            left: "113px",
            width: "40px",
            borderRadius: '0px ',
            border: "1.5px solid #A4A7B1",
            fontFamily: 'Roboto',
            wordWrap: 'break-word'
          }}>
          </Box>
          <FormControl
            sx={{
            position: "absolute",
            top: "30px",
            left: "153px",
            width: "100px"
            }}>
            <Input
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              UpperLimitLogSpeedAF(event.target.value)
            }}
            onBlur={onBlurLimitTextBox}
            placeholder="upper"
            value={toLogSpeedAF}
            type='tel'
            sx={{
              fontFamily: 'Roboto',
              fontWeight: '400',
              wordWrap: 'break-word',
              border: '0px grey solid',
              color: "white",
              fontSize: "15px",
              "& .MuiInput-input": {
              height: "30px",
              borderRadius: '0px',
              border: '0px white solid',
              background: "rgba(255, 255, 255, 0.15)",
              textAlign: "center",
              },
              ":hover": {
              backgroundColor: "#7a86a0",
              border: '0px #CBCBCB solid',
              borderBottom: "0px solid white",
              },
              ":hover:after": {
              borderBottom: "0px solid green",
              },
              ":hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid white",
              },
              ":after": {
              borderBottom: "2px solid white",
              },
              ":before": {
              borderBottom: "2px solid #A4A7B1",
              },
            }}
            />
          </FormControl>
          <Typography align="left"
            sx={{
            position: "absolute",
            top: "55px",
            left: "255px",
            color: 'rgba(255, 255, 255, 0.30)',
            fontSize: 13,
            fontFamily: 'Roboto Condensed',
            fontWeight: '400',
            lineHeight: "24px",
            wordWrap: 'break-word'
            }}>
            knot
          </Typography>
          </Stack>
          <Stack alignItems="center" sx={{ position: "relative", top: "0px", left: "0px", height: "21px" }}>
          <Typography align="left"
            sx={{
            position: "absolute",
            top: "0px",
            left: "13px",
            color: 'rgba(255, 255, 255, 0.5)',
            fontSize: "18px",
            fontFamily: "Roboto",
            fontWeight: '700',
            lineHeight: "24px",
            wordWrap: 'break-word',
            }}>
            and
          </Typography>
          </Stack>
          <Stack alignItems="center" sx={{ position: "relative", top: "12px", left: "0px", height: "100px" }}>
          <Typography align="left"
            sx={{
            position: "absolute",
            top: "0px",
            left: "13px",
            color: 'white',
            fontSize: "14px",
            fontFamily: "'Roboto Bold'",
            fontWeight: '700',
            lineHeight: "24px",
            wordWrap: 'break-word',
            }}>
            FOC
          </Typography>
          <FormControl
            sx={{
            position: "absolute",
            top: "30px",
            left: "13px",
            width: "100px"
            }}>
            <Input
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              LowerLimitFOCAF(event.target.value);
            }}
            onBlur={onBlurLimitTextBox}
            placeholder="lower"
            value={fromFOCAF}
            type='tel'
            sx={{
              fontFamily: 'Roboto',
              fontWeight: '400',
              wordWrap: 'break-word',
              border: '0px grey solid',
              color: "white",
              fontSize: "15px",
              "& .MuiInput-input": {
              height: "30px",
              borderRadius: '0px',
              border: '0px white solid',
              background: "rgba(255, 255, 255, 0.15)",
              textAlign: "center",
              },
              ":hover": {
              backgroundColor: "#7a86a0",
              border: '0px #CBCBCB solid',
              borderBottom: "0px solid white",
              },
              ":hover:after": {
              borderBottom: "0px solid green",
              },
              ":hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid white",
              },
              ":after": {
              borderBottom: "2px solid white",
              },
              ":before": {
              borderBottom: "2px solid #A4A7B1",
              },
            }}
            />
          </FormControl>
          <Typography align="left"
            sx={{
            position: "absolute",
            top: "55px",
            left: "115px",
            color: 'rgba(255, 255, 255, 0.30)',
            fontSize: 13,
            fontFamily: 'Roboto Condensed',
            fontWeight: '400',
            lineHeight: "24px",
            wordWrap: 'break-word'
            }}>
            MT
          </Typography>
          <Box sx={{
            position: "absolute",
            top: "50px",
            left: "113px",
            width: "40px",
            borderRadius: '0px ',
            border: "1.5px solid #A4A7B1",
            fontFamily: 'Roboto',
            wordWrap: 'break-word'
          }}>
          </Box>
          <FormControl
            sx={{
            position: "absolute",
            top: "30px",
            left: "153px",
            width: "100px"
            }}>
            <Input
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              UpperLimitFOCAF(event.target.value)
            }}
            onBlur={onBlurLimitTextBox}
            placeholder="upper"
            value={toFOCAF}
            type='tel'
            sx={{
              fontFamily: 'Roboto',
              fontWeight: '400',
              wordWrap: 'break-word',
              border: '0px grey solid',
              color: "white",
              fontSize: "15px",
              "& .MuiInput-input": {
              height: "30px",
              borderRadius: '0px',
              border: '0px white solid',
              background: "rgba(255, 255, 255, 0.15)",
              textAlign: "center",
              },
              ":hover": {
              backgroundColor: "#7a86a0",
              border: '0px #CBCBCB solid',
              borderBottom: "0px solid white",
              },
              ":hover:after": {
              borderBottom: "0px solid green",
              },
              ":hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid white",
              },
              ":after": {
              borderBottom: "2px solid white",
              },
              ":before": {
              borderBottom: "2px solid #A4A7B1",
              },
            }}
            />
          </FormControl>
          <Typography align="left"
            sx={{
            position: "absolute",
            top: "55px",
            left: "255px",
            color: 'rgba(255, 255, 255, 0.30)',
            fontSize: 13,
            fontFamily: 'Roboto Condensed',
            fontWeight: '400',
            lineHeight: "24px",
            wordWrap: 'break-word'
            }}>
            MT
          </Typography>
        </Stack>
      </Stack>
    </>
  )
}

export default FilterComponent;
